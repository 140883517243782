import FileContainer from "components/Utils/SubComs/FileContainer/FileContainer";
import LoadingSpinner from "components/Utils/SubComs/LoadingSpinner/LoadingSpinner";
import { useMemo } from "react";
import LoadMoreButton from "../Utils/Button/LoadMoreButton";
import NoResults from "../Utils/SubComs/NoResults/NoResults";
import styles from "./UserVault.module.css";
import VaultItem from "./VaultItem";
// import VaultLoader from "../Utils/SubComs/CustomLoader/VaultLoader";
// import emptyFolderIcon from "images/empty-folder-icon.svg";

const VaultItems = ({
	items = [],
	isLoading,
	perPageCount,
	layout,
	isActive,
	selectedItems,
	onDelete,
	onDetails,
	onShare,
	onMove,
	onRename,
	onCopy,
	onSelect,
	onFavoriteItem,
	onLoadMoreItems,
	// hideShowMoreButton = false,
	onClick,
	hasNextPage,
}) => {
	const isList = layout === "list";

	/**
	 * JSX
	 */

	const userItems = useMemo(
		() =>
			items?.map((item) => {
				return (
					<VaultItem
						key={item.uuid}
						item={item}
						isActive={isActive}
						layout={layout}
						selectedItems={selectedItems}
						onSelect={onSelect}
						onDelete={onDelete}
						onDetails={onDetails}
						onShare={onShare}
						onMove={onMove}
						onRename={onRename}
						onCopy={onCopy}
						onFavorite={onFavoriteItem}
						// onPopover={(itemId) => setOpenedPopoverId(itemId)}
						// openedPopoverId={openedPopoverId}
						onClick={() => onClick(item)}
					/>
				);
			}),
		[
			isActive,
			items,
			layout,
			onClick,
			onCopy,
			onDelete,
			onDetails,
			onFavoriteItem,
			onMove,
			onRename,
			onSelect,
			onShare,
			selectedItems,
		]
	);

	// const loadingItems = [...Array(perPageCount / 2)].map((_, index) => (
	// 	<VaultLoader key={index} isList={isList} />
	// ));

	return (
		<>
			{userItems.length > 0 && <FileContainer isList={isList}>{userItems}</FileContainer>}

			{/*	{isLoading && (
				<FileContainer isList={isList} marginTop={userItems.length > 0}>
					{loadingItems}
				</FileContainer>
			)} */}

			<LoadingSpinner visible={isLoading} />

			{/* //TODO: SHOULD HAVE A FILECOUNT TO BE ACCURATE */}
			{/* //TODO: will still show "see more" in case remainder of the modulo is 0 even though there's no more to load */}

			<LoadMoreButton
				hasMore={hasNextPage}
				loading={isLoading}
				onLoad={onLoadMoreItems}
				customStyle={styles.centered_div}
				showSpinner={false}
			/>

			<NoResults
				visible={!isLoading && items.length === 0}
				// customStyle={styles.centered_div}
				text={"No items to display"}
			/>
		</>
	);
};

export default VaultItems;
