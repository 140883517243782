import profileApi from "api/profile";
import PrimaryButton from "components/Utils/Button/PrimaryButton";
import SecondaryButton from "components/Utils/Button/SecondaryButton";
import { maxPostLength, successfulChangeToast } from "components/Utils/General";
import PromptModal from "components/Utils/Prompt/PromptModal";
import TextArea from "components/Utils/SubComs/Inputs/TextArea/TextArea";
import useCurrentUser from "hooks/useCurrentUser";
import useMutate from "hooks/useMutate";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { bioUpdate as companyBioUpdate } from "../../../../store/slices/company";
import { bioUpdated } from "../../../../store/slices/user";
import WarningModal from "../../../Utils/GaawkModal/WarningModal";
import styles from "./BioEdit.module.css";

const Index = () => {
	const dispatch = useDispatch();

	const { bio: savedBio, uuid, type } = useCurrentUser();
	const editCompany = type === "COMPANY";

	const [bio, setBio] = useState("");

	const [warningDeleteModal, setWarningDeleteModal] = useState(false);

	const validCount = bio.length > 0 && bio.length <= maxPostLength;

	const updateStoreState = (data) => {
		dispatch(editCompany ? companyBioUpdate(data) : bioUpdated(data));
		successfulChangeToast();
	};

	const {
		action: { mutate: updateBio, isLoading },
	} = useMutate(editCompany ? profileApi.updateCompanyBio : profileApi.updateBio, (response) => {
		updateStoreState(response.data);
	});

	const saveBio = () => {
		const body = editCompany
			? { data: { bio, share: false }, uuid }
			: { data: { bio, share: false } };

		updateBio(body);
	};

	const {
		action: { mutate: deleteBio },
	} = useMutate(editCompany ? profileApi.deleteCompanyBio : profileApi.deleteBio, (response) => {
		updateStoreState(response.data);
		setWarningDeleteModal(false);
	});

	const handleDelete = () => {
		deleteBio(editCompany ? uuid : "");
	};

	useEffect(() => {
		setBio(savedBio);
	}, [savedBio]);

	return (
		<>
			<div className={styles.container}>
				<TextArea
					label={"Bio"}
					className={styles.mt_0}
					onChange={(e) => setBio(e.target.value)}
					value={bio}
					infoText={`Maximum ${maxPostLength} characters`}
					placeholder={"Enter your bio"}
				/>

				<div className={styles.button_wrapper}>
					{bio.length > 0 && savedBio !== "" && (
						<SecondaryButton
							className={styles.delete_button}
							onClick={() => setWarningDeleteModal(true)}
							text="clear"
						/>
					)}

					<PrimaryButton
						type="button"
						text={"Save"}
						isLoading={isLoading}
						disabled={!validCount}
						onClick={saveBio}
					/>
				</div>
			</div>

			<PromptModal when={savedBio !== bio} />

			<WarningModal
				show={warningDeleteModal}
				headerText="Are you sure you want to delete this information?"
				warningText="This action cannot be undone."
				cancelButtonText={"Cancel"}
				onCancelButtonClicked={() => setWarningDeleteModal(false)}
				submitButtonText={"DELETE"}
				onSubmitButtonClicked={handleDelete}
			/>
		</>
	);
};

export default Index;
