import { joiResolver } from "@hookform/resolvers/joi";
import profileApi from "api/profile";
import routes from "components/Routing/routing-keys";
import PrimaryButton from "components/Utils/Button/PrimaryButton";
import InputWrapper from "components/Utils/SubComs/Inputs/InputWrapper/InputWrapper";
import TextInput from "components/Utils/SubComs/Inputs/TextInput/TextInput";
import useMutate from "hooks/useMutate";
import Joi from "joi";
import { useState } from "react";
import { useForm } from "react-hook-form";
import styles from "./ForgotPasswordForm.module.scss";

const ForgotPasswordForm = ({ onBack }) => {
	const schema = Joi.object({
		email: Joi.string()
			.email({ tlds: { allow: false } })
			.required()
			.label("Email")
			.messages({
				"string.empty": "Email should not be empty",
				"string.email": "Email should be valid",
			}),
	});

	const {
		register,
		formState: { errors },
		handleSubmit,
		watch,
		setValue,
	} = useForm({ resolver: joiResolver(schema), mode: "onBlur" });

	const watchEmail = watch("email");

	const [isSuccess, setIsSuccess] = useState(false);
	const [errorMsg, setErrorMsg] = useState(null);

	const url = `${window.location.origin}${routes.passwordReset}`;

	const {
		action: { mutate: sendLink, isLoading },
	} = useMutate(
		profileApi.passwordLink,
		() => {
			setIsSuccess(true);
			setErrorMsg(null);
		},
		() => {
			setIsSuccess(false);
			setErrorMsg("Profile not found");
		},
		{
			meta: { enableError: false },
		}
	);

	const handleSendLink = ({ email }) => {
		sendLink({ email, url });
	};

	return (
		<form
			className={styles.form_wrapper}
			onSubmit={handleSubmit((data) => handleSendLink(data))}
		>
			<button className={styles.arrow_btn} type="button" onClick={onBack}>
				<img src={require("images/back-arrow-black.svg").default} alt="back" />
			</button>

			<h3>Forgot Password</h3>

			<p>
				Enter your registered email address below, and we’ll send you a link to reset your
				password. Please follow the instructions in the email to proceed.
			</p>

			<InputWrapper
				label="Email"
				required={true}
				error={errors?.email || errorMsg}
				customError={errorMsg}
				component={
					<TextInput
						{...register("email")}
						placeholder="Enter email"
						autoComplete="on"
						onBlur={() => setValue("email", watchEmail.trim())}
					/>
				}
			/>

			<PrimaryButton text={"send link"} className={styles.submit_btn} isLoading={isLoading} />

			{isSuccess && (
				<p className={styles.success_info}>
					An email was sent to your address. Please check your inbox and follow
					instructions to reset your password.
				</p>
			)}
		</form>
	);
};

export default ForgotPasswordForm;
