import { joiResolver } from "@hookform/resolvers/joi";
import profileApi from "api/profile";
import routes from "components/Routing/routing-keys";
import { capitalize } from "components/Utils/General";
import PromptModal from "components/Utils/Prompt/PromptModal";
import InfiniteSearchInput from "components/Utils/SubComs/Inputs/InfiniteSearchInput/InfiniteSearchInput";
import { dialCodeFormatter } from "components/Utils/SubComs/Inputs/SearchableInput/response-formatter";
import useCurrentUser from "hooks/useCurrentUser";
import useFetchLocation from "hooks/useFetchLocation";
import useMutate from "hooks/useMutate";
import { locationKeys } from "queryKeys/location-key-factory";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { phoneUpdated as companyPhoneUpdated } from "../../../../../store/slices/company";
import { phoneUpdated } from "../../../../../store/slices/user";
import PrimaryButton from "../../../../Utils/Button/PrimaryButton";
import CustomSelect from "../../../../Utils/SubComs/CustomSelect/CustomSelect";
import TextInput from "../../../../Utils/SubComs/Inputs/TextInput/TextInput";
import VisibilitySelector from "../../../../Utils/Visibility/VisibilitySelector";
import phoneSchema from "./phone-schema";
import styles from "./Phone.module.css";

const itemsPerPage = 20;

const PhoneEntry = () => {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const { state, pathname } = useLocation();

	const editMode = pathname === routes.editPhone;

	const { uuid: companyId, type: userType } = useCurrentUser();
	const editCompany = userType === "COMPANY";

	const {
		uuid,
		description,
		mobile: phone,
		type,
		code,
		visibility: currentVisibility,
	} = state || {};

	const {
		register,
		formState: { errors, isDirty, isSubmitSuccessful },
		handleSubmit,
		control,
	} = useForm({
		resolver: joiResolver(phoneSchema),
		mode: "onSubmit",
		defaultValues: editMode
			? {
					description,
					type: {
						label: capitalize(type), //type?.charAt(0) + type?.slice(1).toLowerCase(),
						value: type,
					},
					dialCode: { label: code, value: code },
					phone,
			  }
			: {
					description: "",
					type: null,
					dialCode: null,
					phone: "",
			  },
	});

	const [visibility, setVisibility] = useState(currentVisibility ?? "WORK_CIRCLE_FRIENDS");

	const handleVisibilityChange = (value) => () => {
		setVisibility(value);
	};

	const handleSuccess = (response, variables) => {
		const { companyId } = variables;
		dispatch(companyId ? companyPhoneUpdated(response.data) : phoneUpdated(response.data));
		navigate(routes.phone, { replace: true });
	};

	const {
		action: { mutate: addContact, isLoading: isAdding },
	} = useMutate(
		editCompany ? profileApi.addCompanyContact : profileApi.addContact,
		handleSuccess
	);

	const {
		action: { mutate: updateContact, isLoading: isUpdating },
	} = useMutate(
		editCompany ? profileApi.updateCompanyContact : profileApi.updateContact,
		handleSuccess
	);

	const handleSave = (data) => {
		const {
			description,
			dialCode: { value: code },
			phone: mobile,
			type: { value: type },
		} = data;

		const body = {
			...(editMode && { uuid }),
			description,
			type,
			code,
			mobile,
			visibility,
		};

		if (editMode) {
			updateContact({ ...(editCompany && { companyId }), body });
		} else {
			addContact({ ...(editCompany && { companyId }), body });
		}
	};

	const fetchCountries = useFetchLocation(false, itemsPerPage);

	if (editMode && !state) {
		return <Navigate to={routes.phone} />;
	}

	return (
		<>
			<div className={styles.container}>
				<div className={styles.contact_section}>
					<form
						className={styles.add_form}
						onSubmit={handleSubmit((data) => handleSave(data))}
						noValidate
					>
						<div className={styles.form_input_wrapper}>
							<label>
								Description / Label
								<span className="required">*</span>
							</label>
							<TextInput
								{...register("description")}
								error={!!errors.description}
								placeholder="Description"
							/>
							{errors?.description?.message && (
								<p className={styles.error_message}>
									{errors?.description?.message}
								</p>
							)}
						</div>

						<div className={styles.react_select_wrapper}>
							<label>
								Contact type
								<span className="required">*</span>
							</label>

							<CustomSelect
								fontSize="14px"
								placeholder="Contact Type"
								height="35px"
								options={[
									{
										value: "MOBILE",
										label: "Mobile",
									},
									{
										value: "LANDLINE",
										label: "Landline",
									},
								]}
								isSearchable={false}
								error={!!errors.type}
								control={control}
								name="type"
							/>
							{errors?.type?.message && (
								<p className={styles.error_message}>{errors?.type?.message}</p>
							)}
						</div>

						<div className={styles.react_select_wrapper}>
							<label>
								Phone number
								<span className="required">*</span>
							</label>

							<div className={styles.phone_wrapper}>
								<div className={styles.phone_dial}>
									<InfiniteSearchInput
										queryName={locationKeys.countries}
										queryFn={fetchCountries}
										itemsPerPage={itemsPerPage}
										formatter={dialCodeFormatter}
										error={errors?.dialCode}
										control={control}
										name="dialCode"
										customStyle={styles.dialCode_input}
										openMenuOnClick={true}
									/>
								</div>

								<div className={styles.form_input_wrapper}>
									<TextInput
										{...register("phone")}
										error={!!errors.phone}
										placeholder="Phone number"
									/>
									{errors?.phone?.message && (
										<p className={styles.error_message}>
											{errors?.phone?.message}
										</p>
									)}
								</div>
							</div>
						</div>

						<div className={styles.visibility_wrapper}>
							<label className={styles.visibility_label}>
								Visibility
								<span className="required">*</span>
							</label>

							<VisibilitySelector
								value={visibility}
								onValueChange={handleVisibilityChange}
							/>
						</div>

						<div className={styles.button_container}>
							<PrimaryButton
								className={styles.save_btn}
								isLoading={isAdding || isUpdating}
								text={"save"}
							/>
						</div>
					</form>
				</div>
			</div>

			<PromptModal when={isDirty && !isSubmitSuccessful} />
		</>
	);
};

export default PhoneEntry;
