import profileApi from "api/profile";
import routes from "components/Routing/routing-keys";
import CtaButton from "components/Utils/Button/CtaButton";
import useCurrentUser from "hooks/useCurrentUser";
import useMutate from "hooks/useMutate";
import { useMemo } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { addressUpdated as companyAddressUpdated } from "../../../../../store/slices/company";
import { addressUpdated } from "../../../../../store/slices/user";
import WarningModal from "../../../../Utils/GaawkModal/WarningModal";
import styles from "./Address.module.css";
import AddressItem from "./AddressItem";

const Address = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const { addresses, uuid: companyId, type } = useCurrentUser();
	const editCompany = type === "COMPANY";

	const { handleRequestMutate, handleConfirmMutate, setWarningModal, warningModal, activeItem } =
		useMutate(
			editCompany ? profileApi.deleteCompanyAddress : profileApi.deleteAddress,
			(response, variables) => {
				const { companyId } = variables;
				dispatch(
					companyId ? companyAddressUpdated(response.data) : addressUpdated(response.data)
				);
			}
		);

	const addressesList = useMemo(
		() =>
			addresses.map((address) => (
				<AddressItem
					key={address.uuid}
					item={address}
					onEditClicked={() =>
						navigate(routes.editAddress, {
							state: address,
						})
					}
					onDelete={() => handleRequestMutate(address)}
				/>
			)),
		[addresses]
	);

	return (
		<>
			<div className={styles.button_wrapper}>
				<CtaButton text="+ ADD A NEW ADDRESS" onClick={() => navigate(routes.addAddress)} />
			</div>

			{addresses.length > 0 && (
				<div className={styles.container}>
					<h4 className={styles.title}>Your Addresses</h4>
					<div className={styles.contact_section}>{addressesList}</div>
				</div>
			)}

			<WarningModal
				show={warningModal}
				headerText="Are you sure you want to delete this information?"
				warningText="This action cannot be undone."
				cancelButtonText={"Cancel"}
				onCancelButtonClicked={() => setWarningModal(false)}
				submitButtonText={"DELETE"}
				onSubmitButtonClicked={() =>
					handleConfirmMutate({
						...(editCompany && { companyId }),
						addressId: activeItem.uuid,
					})
				}
			/>
		</>
	);
};

export default Address;
