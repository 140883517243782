import { useInView } from "react-intersection-observer";
import { useEffect, useState } from "react";
import { useInfiniteQuery } from "@tanstack/react-query";
import { selectErrorComponent } from "components/Errors/ErrorComponents";

export default function useInfiniteScroll({
	queryKey,
	queryFn,
	pageSize = 20,
	enabled = true,
	queryOptions = {},
}) {
	const { errorComponent, ...restOptions } = queryOptions;

	const [items, setItems] = useState([]);

	const { ref: viewRef, inView } = useInView({
		// threshold: 0.1, // Trigger when 10% of the element is visible
		triggerOnce: true,
	});

	const query = useInfiniteQuery({
		queryKey: queryKey,
		queryFn: queryFn,
		getNextPageParam: (lastPage, allPages) => {
			return lastPage.length === pageSize ? allPages.length : undefined;
		},
		enabled,
		...restOptions,
	});

	const { data, fetchNextPage, hasNextPage, isFetchingNextPage, error } = query;

	if (error) {
		const ErrorComponent = errorComponent || selectErrorComponent(error);
		query.errorComponent = <ErrorComponent error={error} />;
	}

	useEffect(() => {
		if (data) {
			setItems(data.pages.flat());
		}
	}, [data]);

	useEffect(() => {
		if (inView && hasNextPage && !isFetchingNextPage) {
			fetchNextPage();
		}
	}, [inView, hasNextPage, isFetchingNextPage, fetchNextPage]);

	const loadMore = () => {
		if (hasNextPage && !isFetchingNextPage) {
			fetchNextPage();
		}
	};

	return {
		viewRef,
		items,
		loadMore,
		...query,
	};
}
