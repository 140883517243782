import useMutate from "hooks/useMutate";
import { useEffect } from "react";
import servicesApi from "../../../../../api/services";
import useDebounce from "../../../../../hooks/useDebounce";

export const useTag = (nameWatcher, setValue, step2, service) => {
	const debouncedInput = useDebounce(nameWatcher);

	const {
		action: { mutate: fetchTag },
	} = useMutate(servicesApi.getTag, (response) => {
		setValue("tag", response.data.tag);
	});

	useEffect(() => {
		if (!service) {
			if (debouncedInput === step2?.title) setValue("tag", step2?.tag);
			else if (debouncedInput) fetchTag(debouncedInput);
			else setValue("tag", "");
		}
	}, [debouncedInput]);

	return debouncedInput;
};
