import { useQuery } from "@tanstack/react-query";
import boardsApi from "api/boards";
import tipsApi from "api/tips";
import BoardSection from "components/Boards/BoardsComponents/BoardSection";
import useGetBoards from "components/More/BoardsPage/useGetBoards";
import routes from "components/Routing/routing-keys";
import StorageCard from "components/Storage/StorageComponents/StorageCard";
import SecondaryButton from "components/Utils/Button/SecondaryButton";
import GaawkSection from "components/Utils/GaawkSection/GaawkSection";
import {
	getStorageWarningText,
	packageTypes,
	storageWarningThreshold,
} from "components/Utils/General";
import RangesCalendar from "components/Utils/RangesCalendar/RangesCalendar";
import GaawkLink from "components/Utils/SubComs/GaawkLink/GaawkLink";
import ProfileTabContainer from "components/Utils/SubComs/ProfileTab/ProfileTabContainer";
import useApi from "hooks/useApi";
import useCurrentUser from "hooks/useCurrentUser";
import useCalendarEntries from "hooks/useCalendarEntries";
import useVaultStorage from "hooks/useVaultStorage";
import { ReactComponent as ProductIcon } from "images/product.svg";
import { ReactComponent as ServiceIcon } from "images/services.svg";
import { boardsKeys } from "queryKeys/boards-key-factory";
import { tipsKeys } from "queryKeys/tips-key-factory";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { updateProfileTab } from "store/slices/activeTab";
import { setModalProduct } from "store/slices/products";
import { setModal } from "store/slices/service";
import styles from "./HomeLeftSidebar.module.scss";
import TipsSlider from "./TipsSlider";

const HomeLeftSidebar = () => {
	const { uuid, type } = useCurrentUser();
	const { data: vaultData, usedSpaceInPercentage, isFetching } = useVaultStorage();

	const {
		usedProductsCount,
		usedServicesCount,
		currentPlan: { products: productsAdCount, services: servicesAdCount } = {},
	} = vaultData || {};

	const isServicesDisabled = usedServicesCount >= servicesAdCount;
	const isProductsDisabled = usedProductsCount >= productsAdCount;

	const navigate = useNavigate();
	const dispatch = useDispatch();

	const { data: calendarEntries, handleMonthChange } = useCalendarEntries(uuid);

	const handleRedirect = (tab) => {
		dispatch(updateProfileTab(tab));
		navigate(routes.profile());
		dispatch(tab === "services" ? setModal(true) : setModalProduct(true));
	};

	const getMyBoardsApi = useApi(boardsApi.getMyBoards, true, true);

	const fetchMyBoards = async ({ pageParam = 0 }) => {
		const response = await getMyBoardsApi.request(pageParam, 3);
		return response.data;
	};

	const myBoards = useGetBoards(
		fetchMyBoards,
		boardsKeys.list("myBoards-sidebar"), //TODO >> move to boards-key-factory
		false,
		20,
		true,
		300000
	);

	// !===== TIPS ====

	const getTipsApi = useApi(tipsApi.getTips, true, true);

	const fetchTips = async () => {
		const response = await getTipsApi.request();
		return response.data;
	};

	const { data, isLoading } = useQuery({
		queryKey: tipsKeys.all(),
		queryFn: fetchTips,
		staleTime: Infinity,
		//* below to avoid using boundary from react-query-config which would trigger a toast.notification
		// useErrorBoundary: (error, query) => {
		// 	return;
		// },
	});

	return (
		<div className={styles.container}>
			<ProfileTabContainer customStyle={styles.section_container}>
				<div className={styles.grid}>
					<GaawkSection tileStyle={styles.small} labelStyle={styles.black} />
				</div>
			</ProfileTabContainer>

			<ProfileTabContainer customStyle={styles.section_container}>
				<h3>Services &amp; Products</h3>
				<p>
					Explore the Services section in Gaawk – where convenience meets your needs with
					a smile. Let’s get started!
				</p>

				{isServicesDisabled && (
					<p className={styles.warning}>
						You've reached the maximum services allowed in your current plan.
					</p>
				)}
				{isProductsDisabled && (
					<p className={styles.warning}>
						You've reached the maximum products allowed in your current plan.
					</p>
				)}

				<div className={styles.button_container}>
					<SecondaryButton
						text={
							<>
								<ServiceIcon
									width="20"
									fill={isServicesDisabled ? "#FFF" : "#000"}
								/>
								<span
									className={
										isServicesDisabled
											? styles.button_label_disabled
											: undefined
									}
								>
									+Add a Service
								</span>
							</>
						}
						disabled={isServicesDisabled}
						onClick={() => handleRedirect("services")}
						className={isServicesDisabled ? styles.button_disabled : undefined}
					/>
					<SecondaryButton
						text={
							<>
								<ProductIcon
									width="20"
									fill={isProductsDisabled ? "#FFF" : "#000"}
								/>
								<span
									className={
										isProductsDisabled
											? styles.button_label_disabled
											: undefined
									}
								>
									+Add a Product
								</span>
							</>
						}
						disabled={isProductsDisabled}
						onClick={() => handleRedirect("products")}
						className={isProductsDisabled ? styles.button_disabled : undefined}
					/>
				</div>
			</ProfileTabContainer>

			{type !== "COMPANY" && (
				<ProfileTabContainer minHeight={false} customStyle={styles.section_container}>
					<h3>Your boards</h3>
					<p>You can create posts and interact with like-minded people through boards.</p>

					<BoardSection infiniteData={myBoards} isSidebar={true} />

					<GaawkLink to={routes.manageBoards} customStyle={styles.link}>
						Manage boards
					</GaawkLink>
				</ProfileTabContainer>
			)}

			{!isFetching && vaultData.currentPlan.paymentPackageType === packageTypes.free && (
				<ProfileTabContainer customStyle={styles.section_container}>
					<StorageCard
						text={getStorageWarningText(
							"homeSidebar",
							usedSpaceInPercentage,
							vaultData
						)}
						showCleanBtn={usedSpaceInPercentage >= storageWarningThreshold}
					/>
				</ProfileTabContainer>
			)}

			{type !== "COMPANY" && (
				<ProfileTabContainer minHeight={false} customStyle={styles.section_container}>
					<h3>Calendar</h3>
					<RangesCalendar
						entries={calendarEntries}
						onMonthChange={handleMonthChange}
						isSidebar={true}
					/>
					<GaawkLink
						onClick={() => dispatch(updateProfileTab("work status"))}
						to={"/profile"}
						customStyle={styles.link}
					>
						Update Calendar or Work Status
					</GaawkLink>
				</ProfileTabContainer>
			)}

			{data?.length > 0 && (
				<ProfileTabContainer
					minHeight={false}
					customStyle={`${styles.section_container} ${styles.no_padding}`}
				>
					<div className={styles.inner_wrapper}>
						<h3>Tips on Gaawk</h3>
						<p>
							To all Gaawk explorer! Check out our Tips and Tutorials for a smoother
							app adventure!
						</p>
					</div>

					<TipsSlider data={data} isLoading={isLoading} />
				</ProfileTabContainer>
			)}

			<ProfileTabContainer minHeight={false} customStyle={styles.section_container}>
				<div className={styles.other}>
					<GaawkLink to={routes.help} target={"_blank"}>
						Help &amp; Support
					</GaawkLink>
					<GaawkLink to={routes.about} target={"_blank"}>
						About Us
					</GaawkLink>
					<GaawkLink to={routes.privacy} target={"_blank"}>
						Privacy Policy
					</GaawkLink>
					<GaawkLink to={routes.faq} target={"_blank"}>
						FAQ
					</GaawkLink>
				</div>

				{/* <div className={styles.copyright}>
					Copyright all right reserved gaawk 2023
				</div> */}
			</ProfileTabContainer>
		</div>
	);
};

export default HomeLeftSidebar;
