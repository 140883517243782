import { useMutation } from "@tanstack/react-query";
import useApi from "./useApi";
// import { toast } from "react-toastify";
import { isPlainObject } from "@reduxjs/toolkit";
import { useState } from "react";

const useMutate = (endpoint, successFn, errorFn, options = {}) => {
	const api = useApi(endpoint, true, true);

	const action = useMutation({
		mutationFn: (args) => {
			if (isPlainObject(args)) {
				return api.request(...Object.values(args));
			} else {
				return api.request(args);
			}
		},
		onSuccess: (data, variables) => {
			successFn && successFn(data, variables);
		},

		onError: (error) => {
			// errorMessage !== false &&
			// 	toast.error(typeof errorMessage === "string" ? errorMessage : error.message);
			// if (options.useErrorBoundary) {
			// 	throw new Response("", {
			// 		statusText: error.message,
			// 		status: error.code,
			// 	});
			// }
			errorFn && errorFn(error);
		},

		...options,
	});

	const [warningModal, setWarningModal] = useState(false);
	const [activeItem, setActiveItem] = useState(undefined);

	const handleRequestMutate = (item) => {
		setWarningModal(true);
		setActiveItem(item);
	};

	const handleConfirmMutate = (args) => {
		action.mutate(args);
		setWarningModal(false);
	};

	return {
		action,
		warningModal,
		setWarningModal,
		handleRequestMutate,
		handleConfirmMutate,
		activeItem,
	};
};

export default useMutate;
