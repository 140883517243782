import profileApi from "api/profile";
import routes from "components/Routing/routing-keys";
import CtaButton from "components/Utils/Button/CtaButton";
import useCurrentUser from "hooks/useCurrentUser";
import useMutate from "hooks/useMutate";
import { useMemo } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { updateKeyPeople } from "../../../../store/slices/company";
import WarningModal from "../../../Utils/GaawkModal/WarningModal";
import styles from "./KeyPeople.module.css";
import KeyPeopleItem from "./KeyPeopleItem";

const KeyPeople = () => {
	const navigate = useNavigate();
	const dispatch = useDispatch();

	const { keyPeople, uuid } = useCurrentUser();

	const { handleRequestMutate, handleConfirmMutate, setWarningModal, warningModal, activeItem } =
		useMutate(profileApi.deleteKeyPeople, (response) => {
			dispatch(updateKeyPeople(response.data));
		});

	const formattedKeyPeople = useMemo(
		() =>
			keyPeople.map((item) => (
				<div key={item.profile.uuid} className={styles.key_profile}>
					<KeyPeopleItem
						title={item.profile.name}
						subtitle={item.customRole ?? item.roleDTO.name}
						onDelete={() => handleRequestMutate(item.profile)}
						image={item.profile?.profileImage?.image}
						showControls={true}
					/>
				</div>
			)),
		[keyPeople]
	);

	return (
		<>
			<div className={styles.button_wrapper}>
				<CtaButton text="+ ADD KEY PEOPLE" onClick={() => navigate(routes.addKeyPeople)} />
			</div>

			{keyPeople.length > 0 && (
				<div className={styles.container}>
					<h4 className={styles.title}>Key People you have added</h4>

					<div
						className={`${styles.key_special_profiles} ${
							keyPeople.length > 2 ? styles.align_left : undefined
						}`}
					>
						{formattedKeyPeople}
					</div>
				</div>
			)}

			<WarningModal
				show={warningModal}
				headerText="Are you sure you want to delete this information?"
				warningText="This action cannot be undone."
				cancelButtonText={"Cancel"}
				onCancelButtonClicked={() => setWarningModal(false)}
				submitButtonText={"DELETE"}
				onSubmitButtonClicked={() =>
					handleConfirmMutate({
						companyId: uuid,
						profileId: activeItem.uuid,
					})
				}
			/>
		</>
	);
};

export default KeyPeople;
