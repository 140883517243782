import { useQueryClient } from "@tanstack/react-query";
import { router } from "AppRoutes";
import routes from "components/Routing/routing-keys";
import LoadingPage from "components/Utils/SubComs/CustomLoader/LoadingPage";
import FileSaver from "file-saver";
import useCurrentUser from "hooks/useCurrentUser";
import useGetPostById from "hooks/useGetPostById";
import useMutate from "hooks/useMutate";
import fullscreenIcon from "images/fullscreen-icon-white.svg";
import { postsKeys } from "queryKeys/posts-key-factory";
import { useEffect, useMemo, useRef, useState } from "react";
import { NavigationType, useBlocker, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import postApi from "../../api/post";
import useEditSubPost from "../../hooks/useEditSubPost";
import useWindowSize from "../../hooks/useWindowSize";
import BackButton from "../Utils/Button/BackButton";
import FileViewer from "../Utils/FileViewer/FileViewer";
import WarningModal from "../Utils/GaawkModal/WarningModal";
import { copyToClipboard } from "../Utils/General";
import DefaultPage from "../Utils/PageArchs/DefaultPage/DefaultPage";
import ReportModal from "../Utils/ReportModal/ReportModal";
import CreatePost from "./CreatePost";
import EditMediaModal from "./EditMediaModal";
import PostActions from "./PostActions";
import styles from "./PostFullscreen.module.css";
import PostItem from "./PostItem";

const PostFullScreen = ({
	post: propPost,
	initialSlideIndex,
	postId,
	subPostId: subId,
	isModal,
	onClose,
	onUpdateUrl,
	onRefresh,
}) => {
	const navigate = useNavigate();
	const reportRef = useRef();
	const postRef = useRef();
	const fileViewerRef = useRef();

	const profile = useCurrentUser();

	const profileName =
		profile.type === "COMPANY" ? profile.name : `${profile.firstName} ${profile.lastName}`;

	const [activeSlideIndex, setActiveSlideIndex] = useState(initialSlideIndex);

	const queryClient = useQueryClient();

	const invalidatePost = () => {
		queryClient.invalidateQueries(postsKeys.detail(postId));
	};

	// ! GET POST =================================
	//* disabling the post fetching if we already got it from <PostPreview />
	const { data: post, isLoading } = useGetPostById(postId, !propPost, propPost);
	console.log("🚀 ~ post >>", post);

	const { subPosts, file } = post || {};

	useEffect(() => {
		if (subPosts?.length > 0) {
			const initialIndex = subPosts.findIndex((subPost) => subPost.uuid === subId);
			if (initialIndex !== -1) {
				setActiveSlideIndex(initialIndex);
			}
		}
	}, [subPosts?.length, subId, setActiveSlideIndex]);

	// !=============================================

	// const filesArray = useMemo(() => subPosts?.map((subPost) => subPost.file), [subPosts]);
	const filesArray = useMemo(() => {
		const files = subPosts?.map((subPost) => subPost.file).filter(Boolean);
		return files?.length > 0 ? files : file ? [file] : [];
	}, [subPosts, file]);

	const { width } = useWindowSize();

	// ! ============= POST ACTIONS ================

	const { handleRequestMutate, handleConfirmMutate, warningModal, setWarningModal } = useMutate(
		postApi.deleteMedia,
		() => {
			if (subPosts.length === 1) {
				//* if only 1 subpost, it will close the modal or redirect to homepage
				if (isModal) {
					onRefresh();
					onClose();
					onUpdateUrl();
				} else {
					//* check with backed >> will delete the whole post if only 1 subPost,
					//* despite the presence of text on the parent post
					navigate(routes.root);
				}
			} else {
				if (subPosts.length === activeSlideIndex + 1) {
					//* Decrement activeSlideIndex by 1 when you're deleting the last subPost
					//* Otherwise it will throw an error
					setActiveSlideIndex((prevState) => prevState - 1);
				}
				invalidatePost();
				onRefresh();
			}
		}
	);

	const handleShare = () => {
		if (post.sharedPost) {
			postRef.current.sharePost(post.sharedPost);
		} else {
			postRef.current.sharePost(post);
		}
	};

	//TODO >> la logique de l'url qui change en fonction de la slide devrait être gérée par usePostViewer
	const postItem = useMemo(() => {
		if (post) {
			if (subPosts?.length === 0) {
				return (
					<PostItem
						sm={true}
						post={post}
						visibleByDefault={true}
						onPostUpdate={(reaction) => {
							queryClient.setQueryData(postsKeys.detail(postId), (oldData) => {
								return {
									...oldData,
									reactionStats: reaction.reactionStats,
									profileReactionType: reaction.profileReactionType,
								};
							});
						}}
						onShare={handleShare}
						isModal={isModal}
					/>
				);
			} else if (subPosts?.length > 0 && activeSlideIndex > -1) {
				window.history.replaceState(
					null,
					"",
					routes.post(postId, subPosts[activeSlideIndex].uuid)
				);

				return (
					<PostItem
						sm={true}
						post={subPosts[activeSlideIndex]}
						visibleByDefault={true}
						onPostUpdate={(reaction) => {
							const { postId: subPostId } = reaction;

							queryClient.setQueryData(postsKeys.detail(postId), (oldData) => {
								return {
									...oldData,
									mediaList: oldData.mediaList.map((media) =>
										media.uuid === subPostId
											? {
													...media,
													reactionStats: reaction.reactionStats,
													profileReactionType:
														reaction.profileReactionType,
											  }
											: media
									),
									pdfList: oldData.pdfList.map((pdf) =>
										pdf.uuid === subPostId
											? {
													...pdf,
													reactionStats: reaction.reactionStats,
													profileReactionType:
														reaction.profileReactionType,
											  }
											: pdf
									),
								};
							});
						}}
						onShare={handleShare}
						activeSlideIndex={activeSlideIndex}
						isModal={isModal}
					/>
				);
			} else if (activeSlideIndex === -1) {
				throw new Response("", {
					statusText: "This post doesn't exist",
					status: 404,
				});
			}
		}
	}, [post, subPosts, activeSlideIndex]);

	useEffect(() => {
		let unlisten;
		if (isModal) {
			unlisten = router.subscribe((state) => {
				if (state.historyAction === NavigationType.Pop) {
					onClose();
				}
			});
		}

		return () => unlisten && unlisten();
	}, [isModal]);

	//Triggered when clicking on the home button (bottomnavbar) while a post is opened in a modal
	//if commented, won't close the modal when clicking on the home button, but only change the url
	useBlocker(
		({
			currentLocation: { pathname: currentPathname },
			nextLocation: { pathname: nextPathname },
		}) => {
			if (nextPathname !== window.location.pathname) {
				if (isModal) {
					onClose();
				}
			}
		}
	);

	//! ========== EDIT MEDIA MODAL =============

	// const updateMediaApi = useApi(postApi.updateMedia, true);

	const [showEditMediaModal, setShowEditMediaModal] = useState(false);

	const editItem = useMemo(() => {
		if (post) {
			if (subPosts?.length === 0) {
				return post;
			} else {
				return subPosts[activeSlideIndex];
			}
		}
	}, [post, subPosts, activeSlideIndex]);

	const { handleEdit: handleConfirmEditMedia } = useEditSubPost(
		postApi.updateMedia,
		invalidatePost,
		true
	);

	const handleBackBtn = () => {
		onClose();
		onUpdateUrl();
	};

	const handleCopyLink = () => {
		copyToClipboard(`${window.location.origin}/post/${post.uuid}`);
		toast.success("Link copied !");
	};

	const handleDownload = () => {
		const downloadable = filesArray[activeSlideIndex];
		FileSaver.saveAs(`${downloadable.url}`, `${downloadable.originalName}`);
	};

	return (
		<>
			{isLoading ? (
				<LoadingPage />
			) : (
				<DefaultPage
					leftSideChildren={postItem}
					defaultHeader={false}
					disableScrollbar={true}
					showFooter={false}
					headerRightContent={
						//TODO REPLACE BELOW BY <ViewerHeader /> component ?

						<div className={styles.header_container}>
							<BackButton onBack={() => (isModal ? handleBackBtn() : navigate(-1))} />

							<div className={styles.post_actions_wrapper}>
								<img
									src={fullscreenIcon}
									className={styles.fullscreen_icon}
									alt="Fullscreen"
									onClick={() => fileViewerRef.current.enterFullscreen()}
								/>

								<PostActions
									whiteButton={true}
									isOwner={post?.owner?.uuid === profile.uuid}
									isPublic={post?.postVisibility === "PUBLIC"}
									// onEdit={handleEdit}
									onEdit={() => setShowEditMediaModal(true)}
									// onDelete={handleDeletePost}
									onDelete={handleRequestMutate}
									onCopy={handleCopyLink}
									onShare={handleShare}
									onDownload={handleDownload}
									onReport={() =>
										reportRef.current.showReportModal(
											subId ? subPosts[activeSlideIndex]?.uuid : postId
										)
									}
									postType={post?.postType}
									isFullScreen={true}
								/>
							</div>
						</div>
					}
					rightSideChildren={
						filesArray?.length > 0 ? (
							<div className={styles.fileviewer_container}>
								<FileViewer
									ref={fileViewerRef}
									inModal={false}
									files={filesArray}
									// files={subPosts.length > 0 ? filesArray : [file]}
									initialSlide={activeSlideIndex}
									onActiveSlide={(index) => setActiveSlideIndex(index)}
									deletableItems={true}
								/>
								{/* //* Below DIV for mobile version */}
								<div className={styles.mobile_post_container}>{postItem}</div>
							</div>
						) : (
							<div className={styles.fileviewer_container}>
								<div className={styles.mobile_post_container}>{postItem}</div>
							</div>
						)
					}
					rightSideFullWidth={true}
					rightSideBgColor={width < 748 ? "#f2f3f5" : "#FFF"}
				/>
			)}

			<CreatePost
				ref={postRef}
				name={profileName}
				profileImg={profile.profileImage}
				type={profile.type}
				onCreate={() => toast.success("Post successfully shared!")}
			/>

			<EditMediaModal
				show={showEditMediaModal}
				onClose={() => setShowEditMediaModal(false)}
				// onReset={() => setEditItem("")}
				item={editItem}
				onConfirm={handleConfirmEditMedia}
				onToggle={(bool) => setShowEditMediaModal(bool)}
				// isEditing={true}
			/>

			<WarningModal
				show={warningModal}
				modalOnTop={false}
				headerText="Are you sure you want to delete your post?"
				warningText="This action cannot be undone."
				cancelButtonText={"Cancel"}
				onCancelButtonClicked={() => setWarningModal(false)}
				submitButtonText={"DELETE"}
				onSubmitButtonClicked={() =>
					handleConfirmMutate({
						parentPostId: post.uuid,
						subPostId: subPosts[activeSlideIndex].uuid,
					})
				}
			/>

			<ReportModal ref={reportRef} category="post" />
		</>
	);
};

export default PostFullScreen;
