import { joiResolver } from "@hookform/resolvers/joi";
import profileApi from "api/profile";
import passwordSchema from "components/Registeration/Schema/password-schema";
import routes from "components/Routing/routing-keys";
import PrimaryButton from "components/Utils/Button/PrimaryButton";
import GaawkLink from "components/Utils/SubComs/GaawkLink/GaawkLink";
import InputWrapper from "components/Utils/SubComs/Inputs/InputWrapper/InputWrapper";
import TextInput from "components/Utils/SubComs/Inputs/TextInput/TextInput";
import useMutate from "hooks/useMutate";
import md5 from "md5";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { resetRegistration } from "store/slices/registration";
import { userLoginUpdated } from "store/slices/user";
import styles from "./PasswordForm.module.scss";
import PasswordValidationItem from "./PasswordValidationItem";

const PasswordForm = ({ onBack, formData, onStepChange }) => {
	const dispatch = useDispatch();

	const {
		register,
		formState: { errors, isDirty },
		handleSubmit,
	} = useForm({
		resolver: joiResolver(passwordSchema),
		mode: "onChange",
		criteriaMode: "all",
	});

	const {
		action: { mutate: registerUser, isLoading },
	} = useMutate(profileApi.registerUser, (response) => {
		dispatch(resetRegistration());
		dispatch(userLoginUpdated(response.data));
		localStorage.setItem("uuid", response.data.uuid);
		// window.dispatchEvent(new Event("storage"));
		onStepChange();
	});

	const handleNext = ({ password }) => {
		// fetchToken();
		// setMd5Password(md5(password));

		const data = {
			locationId: formData.locationId,
			genderId: formData.gender,
			genderPronoun: formData.genderPronoun,
			customGender: formData.customGender,
			dob: new Date(`${formData.year}.${formData.month}.${formData.day}`).getTime(),
			email: formData.email,
			// fcmToken: isFound ? token : "N_A",
			firstName: formData.firstName,
			lastName: formData.lastName,
			password: md5(password),
		};

		registerUser({ data });
	};

	// useEffect(() => {
	// 	if (md5Password) {
	// 		const data = {
	// 			locationId: formData.locationId,
	// 			genderId: formData.gender,
	// 			genderPronoun: formData.genderPronoun,
	// 			customGender: formData.customGender,
	// 			dob: new Date(
	// 				`${formData.year}.${formData.month}.${formData.day}`
	// 			).getTime(),
	// 			email: formData.email,
	// 			// fcmToken: isFound ? token : "N_A",
	// 			firstName: formData.firstName,
	// 			lastName: formData.lastName,
	// 			password: md5Password,
	// 		};

	// 		registerUser({ data });
	// 	}
	// }, [dispatch, md5Password]);

	return (
		<form className={styles.form} onSubmit={handleSubmit((data) => handleNext(data))}>
			<button className={styles.arrow_btn} type="button" onClick={onBack}>
				<img src={require("images/back-arrow-black.svg").default} alt="back" />
			</button>

			<h3>Choose a password</h3>

			<PasswordValidationItem
				validate="length"
				label="8 characters"
				errors={errors}
				isDirty={isDirty}
			/>

			<PasswordValidationItem
				validate="upper"
				label="1 uppercase letter"
				errors={errors}
				isDirty={isDirty}
			/>

			<PasswordValidationItem
				validate="lower"
				label="1 lowercase letter"
				errors={errors}
				isDirty={isDirty}
			/>

			<PasswordValidationItem
				validate="digit"
				label="1 number"
				errors={errors}
				isDirty={isDirty}
			/>

			<PasswordValidationItem
				validate="special"
				label="1 special character"
				errors={errors}
				isDirty={isDirty}
			/>

			<InputWrapper
				label="Password"
				required={true}
				error={errors?.password}
				customError={
					errors?.password?.type === "string.empty" ? "Password is required" : ""
				}
				className={styles.custom_padding}
				component={
					<TextInput
						{...register("password")}
						placeholder="Enter password"
						cursor="text"
						isPassword={true}
					/>
				}
			/>
			<InputWrapper
				label="Confirm Password"
				required={true}
				error={errors?.confirmPassword}
				className={styles.custom_padding}
				component={
					<TextInput
						{...register("confirmPassword")}
						placeholder="Enter password again"
						cursor="text"
						isPassword={true}
					/>
				}
			/>

			<span className={styles.info}>
				By tapping Sign Up you agree to our{" "}
				<GaawkLink to={routes.terms} style={{ fontWeight: "bold" }} target={"_blank"}>
					Terms
				</GaawkLink>
				, Data Policy and Cookie Policy. You may receive emails and SMS notifications from
				gaawk, but you can opt out at any time.
			</span>

			<PrimaryButton text="next" className={styles.next_btn} isLoading={isLoading} />
		</form>
	);
};

export default PasswordForm;
