import { createSlice } from "@reduxjs/toolkit";
// import * as apiActions from "../actionCreators/api";

const initialState = {
	startedAt: undefined,
	expireAt: undefined,
	code: "",
	sent: false,
	newEmail: "",
	newMobile: {
		countryCode: "",
		mobile: "",
	},
};

const slice = createSlice({
	name: "otp",
	initialState,
	reducers: {
		startTimer: (state, action) => {
			state.startedAt = action.payload;
			state.expireAt = action.payload + 40000;
		},
		resetTimer: (state) => {
			state.startedAt = undefined;
			state.expireAt = undefined;
			state.code = "";
			state.sent = false;
		},
		otpUpdated: (state, action) => {
			state.code = action.payload.otp;
			state.sent = true;
		},
		updateNewEmail: (state, action) => {
			state.newEmail = action.payload;
			state.newMobile = "";
		},
		updateNewMobile: (state, action) => {
			state.newMobile = action.payload;
			state.newEmail = "";
		},
		resetOtp: () => {
			return initialState;
		},
	},
});

export const { startTimer, resetTimer, otpUpdated, updateNewMobile, updateNewEmail, resetOtp } =
	slice.actions;
export default slice.reducer;

// export const getOtp = (data) =>
// 	apiActions.apiCallBegan(
// 		data.mobile === undefined
// 			? {
// 					headerId: data.uuid,
// 					url: `profile/user/sendOTP?email=${data.email}`,
// 					method: "GET",
// 					onSuccess: otpUpdated.type,
// 			  }
// 			: {
// 					headerId: data.uuid,
// 					url: `profile/user/sendOTP?mobile=${data.mobile}`,
// 					method: "GET",
// 					onSuccess: otpUpdated.type,
// 			  }
// 	);
