import { useQueryClient } from "@tanstack/react-query";
import useCurrentUser from "hooks/useCurrentUser";
import useInfiniteScroll from "hooks/useInfiniteScroll";
import useMutate from "hooks/useMutate";
import { postsKeys } from "queryKeys/posts-key-factory";
import { useState } from "react";
import postApi from "../../api/post";
import useApi from "../../hooks/useApi";
import CommentInput from "./CommentInput";
import CommentItem from "./CommentItem";
import styles from "./Comments.module.css";

const PAGE_SIZE = 5;

const Comments = ({
	visible,
	postId,
	commentsCount,
	onUpdateCommentCount,
	onUpdateTotalCommentCount,
	isFullscreenPost,
	// onIncrementComment,
	// commentUniqueCount,
	// onDecrementComment,
}) => {
	const { profileImage, type, url, name, firstName } = useCurrentUser();

	// !--------------- add comments ----------------
	const queryClient = useQueryClient();

	const {
		action: { mutate: addComment },
	} = useMutate(postApi.addComment, () => {
		queryClient.invalidateQueries(postsKeys.comments(postId));
		onUpdateCommentCount("INCREMENT");
	});

	// !--------------- get comments ----------------
	const getCommentsApi = useApi(postApi.getComments, true, true);

	const fetchComments = async ({ pageParam = 0, queryKey }) => {
		const [_, __, postId] = queryKey;
		const response = await getCommentsApi.request(postId, pageParam, PAGE_SIZE);
		return response.data;
	};

	const {
		items: comments,
		hasNextPage,
		loadMore,
	} = useInfiniteScroll({
		queryKey: postsKeys.comments(postId),
		queryFn: fetchComments,
		pageSize: PAGE_SIZE,
		enabled: visible,
	});

	// !---------------------------------------------

	const [commentText, setCommentText] = useState("");

	const handleTextChange = (comment) => {
		setCommentText(comment);
	};

	const handleConfirmComment = () => {
		addComment({ object: { comment: commentText, postId } });
		setCommentText("");
	};

	const handleEmojiClick = (_, emojiObject) => {
		setCommentText((prevState) => prevState + emojiObject.emoji);
	};

	const handleCommentUpdate = (updatedComment) => {
		queryClient.setQueryData(postsKeys.comments(postId), (oldData) => {
			return {
				...oldData,
				pages: oldData.pages.map((page) =>
					page.map((comment) =>
						comment.uuid === updatedComment.uuid ? updatedComment : comment
					)
				),
			};
		});
	};

	// !--- REPLIES ----

	const handleUpdateRepliesCount = (commentId, operation) => {
		queryClient.setQueryData(postsKeys.comments(postId), (oldData) => {
			return {
				...oldData,
				pages: oldData.pages.map((page) =>
					page.map((comment) =>
						comment.uuid === commentId
							? {
									...comment,
									repliesCount:
										operation === "INCREMENT"
											? comment.repliesCount + 1
											: comment.repliesCount - 1,
							  }
							: comment
					)
				),
			};
		});

		onUpdateTotalCommentCount(operation === "INCREMENT" ? "INCREMENT" : "DECREMENT");
	};

	// ! ========= COMMENT EDIT =========

	// const [newText, setNewText] = useState("");

	// const handleCommentEdit = (text) => {
	// 	setNewText(text);
	// };

	// ! ========= COMMENT DELETE =========

	const {
		action: { mutate: deleteComment },
	} = useMutate(postApi.deleteComment, () => {
		queryClient.invalidateQueries(postsKeys.comments(postId));
		onUpdateCommentCount("DECREMENT");
	});

	const handleDeleteComment = (commentId) => {
		deleteComment({ postId, commentId });
	};

	const commentList = comments.map((comment) => {
		return (
			<CommentItem
				key={comment.uuid}
				postId={postId}
				comment={comment}
				onCommentUpdate={handleCommentUpdate}
				onUpdateRepliesCount={handleUpdateRepliesCount}
				profileImg={profileImage?.originalImageURL}
				name={type === "COMPANY" ? name : firstName}
				type={type}
				url={url}
				// onTextChange={handleCommentEdit}
				// editedText={newText}
				onDelete={handleDeleteComment}
				isFullscreenPost={isFullscreenPost}
			/>
		);
	});

	return (
		<div
			className={`${styles.post_comments_section} ${
				visible && styles.post_comments_section_visible
			}`}
		>
			<CommentInput
				profileImg={profileImage?.originalImageURL}
				name={type === "COMPANY" ? name : firstName}
				type={type}
				url={url}
				onTextChange={handleTextChange}
				text={commentText}
				onEmojiClick={handleEmojiClick}
				isEditing={false}
				onConfirm={handleConfirmComment}
			/>

			<div className={styles.comments_wrapper}>{commentList}</div>

			{comments.length > 0 && hasNextPage && (
				<div className={styles.load_more_button}>
					<button onClick={loadMore}>
						View {commentsCount - comments.length} more comments
					</button>
				</div>
			)}
		</div>
	);
};

export default Comments;
