import React, { useState } from "react";
import styles from "./EditUserInfo.module.css";
import { useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { joiResolver } from "@hookform/resolvers/joi";
import TextInput from "../../../Utils/SubComs/Inputs/TextInput/TextInput";
import PrimaryButton from "../../../Utils/Button/PrimaryButton";
import CustomRadioButton from "../../../Utils/SubComs/CustomRadioButton/CustomRadioButton";
import DayPicker from "../../../Utils/SubComs/DayPicker/DayPicker";
import { customGenderValues } from "../../../Utils/General";
import { locationFormatter } from "../../../Utils/SubComs/Inputs/SearchableInput/response-formatter";
import CustomSelect from "../../../Utils/SubComs/CustomSelect/CustomSelect";
import profileApi from "../../../../api/profile";
import UrlCheckInput from "../../../Utils/SubComs/Inputs/UrlCheck/UrlCheckInput";
import editUserSchema from "./edit-user-schema";
import InfiniteSearchInput from "../../../Utils/SubComs/Inputs/InfiniteSearchInput/InfiniteSearchInput";
import useFetchLocation from "../../../../hooks/useFetchLocation";
import useMutate from "hooks/useMutate";
import { useNavigate } from "react-router";
import PromptModal from "components/Utils/Prompt/PromptModal";
import { locationKeys } from "queryKeys/location-key-factory";
import routes from "components/Routing/routing-keys";

const itemsPerPage = 20;

const EditUserInfo = () => {
	const [shouldBlockSave, setShouldBlockSave] = useState(true);

	const {
		firstName,
		lastName,
		nationality,
		nationality: { countryDTO: { name: nationalityName } = {}, id: nationalityId } = {},
		locationDTO: {
			name: cityName,
			countryDTO: { name: countryName } = {},
			id: locationId,
		} = {},
		tagLine,
		url,
		gender,
		genderPronoun,
		customGender = "",
		dob,
	} = useSelector((state) => state.user.profileInfo);

	const {
		register,
		formState: { errors, isDirty },
		handleSubmit,
		control,
		watch,
		setValue,
		setError,
		clearErrors,
	} = useForm({
		resolver: joiResolver(editUserSchema),
		mode: "all",
		defaultValues: {
			firstName,
			lastName,
			url,
			urlValidity: true,
			headline: tagLine,
			location: {
				value: locationId,
				label: `${cityName}, ${countryName}`,
				// countryCode: countryDTO.code,
			},
			nationality: nationality ? { label: nationalityName, value: nationalityId } : null,
			birthDate: new Date(dob),
			gender: gender[0].toLowerCase(),
			genderPronoun:
				genderPronoun === "THEM" || genderPronoun === "they"
					? {
							value: "they",
							label: 'They - "Wish them a happy birthday"',
					  }
					: genderPronoun === "HE"
					? { value: "he", label: 'He - "Wish him a happy birthday"' }
					: {
							value: "she",
							label: 'She - "Wish her a happy birthday"',
					  },
			optionalGender: customGender,
		},
	});

	// const urlField = register("url");
	const genderWatcher = watch("gender");
	const urlWatcher = watch("url");
	const urlValidityWatcher = watch("urlValidity");

	const navigate = useNavigate();

	const {
		action: { mutate: updateInfo, isLoading },
	} = useMutate(
		profileApi.updateUserInfo,
		() => navigate(routes.profile()),
		() => setShouldBlockSave(true)
	);

	const handleSave = (data) => {
		const {
			firstName,
			lastName,
			url,
			headline: headLine,
			location: { value: locationId },
			nationality,
			birthDate,
			gender: genderId,
			genderPronoun,
			optionalGender: customGender,
		} = data;

		const body = {
			firstName,
			lastName,
			url,
			headLine,
			locationId,
			...(nationality && { nationalityId: nationality.value }),
			dob: birthDate.getTime(),
			genderId,
			genderPronoun: genderId === "c" ? genderPronoun.value : "", //genderId === "m" ? "he" : "she"
			customGender: genderId === "c" && customGender ? customGender : "",
		};

		setShouldBlockSave(false);
		updateInfo({ body });
	};

	const fetchLocation = useFetchLocation(true, itemsPerPage);
	const fetchCountries = useFetchLocation(false, itemsPerPage);

	return (
		<div className={styles.container}>
			<form
				className={styles.form}
				onSubmit={handleSubmit((data) => handleSave(data))}
				noValidate
			>
				<div className={styles.form_input_wrapper}>
					<label>
						First Name
						<span className="required">*</span>
					</label>

					<TextInput
						error={!!errors.firstName}
						{...register("firstName")}
						placeholder="Enter your First Name"
					/>

					{errors?.firstName?.message && (
						<p className={styles.error_message}>{errors?.firstName?.message}</p>
					)}
				</div>

				<div className={styles.form_input_wrapper}>
					<label>
						Last Name
						<span className="required">*</span>
					</label>

					<TextInput
						error={!!errors.lastName}
						{...register("lastName")}
						placeholder="Enter your Last Name"
					/>

					{errors?.lastName?.message && (
						<p className={styles.error_message}>{errors?.lastName?.message}</p>
					)}
				</div>

				<UrlCheckInput
					label={"Public URL"}
					placeholder={"Enter your public URL"}
					control={control}
					name={"url"}
					errors={errors}
					urlValidityWatcher={urlValidityWatcher}
					urlWatcher={urlWatcher}
					url={url}
					onSetValue={setValue}
					onSetError={setError}
					onClearErrors={clearErrors}
					urlPrefix={"profile/"}
				/>

				<div className={styles.form_input_wrapper}>
					<label>Headline</label>

					<TextInput
						error={!!errors.headline}
						{...register("headline")}
						placeholder="Enter your Headline"
					/>

					{errors?.headline?.message && (
						<p className={styles.error_message}>{errors?.headline?.message}</p>
					)}
				</div>

				<InfiniteSearchInput
					queryName={locationKeys.cities}
					queryFn={fetchLocation}
					itemsPerPage={itemsPerPage}
					formatter={locationFormatter}
					label={"Location"}
					required={true}
					error={errors?.location}
					control={control}
					name="location"
					openMenuOnClick={true}
				/>

				<InfiniteSearchInput
					queryName={locationKeys.countries}
					queryFn={fetchCountries}
					itemsPerPage={itemsPerPage}
					formatter={locationFormatter}
					label={"Nationality"}
					error={errors?.nationality}
					control={control}
					name="nationality"
					isClearable={true}
					openMenuOnClick={true}
				/>

				<div className={styles.datepicker_wrapper}>
					<label>
						Date of Birth<span className="required">*</span>
					</label>
					<DayPicker
						className={`form-start-date ${!!errors.birthDate ? "error" : undefined}`}
						control={control}
						name="birthDate"
					/>
					{errors?.birthDate?.message && (
						<p className={styles.error_message}>{errors?.birthDate?.message}</p>
					)}
				</div>

				<div className={styles.gender_wrapper}>
					<label>
						Gender<span className="required">*</span>
					</label>

					<div className={styles.gender_item}>
						<CustomRadioButton
							{...register("gender")}
							id="male"
							value="m"
							name="gender"
						/>
						<label htmlFor="male">Male</label>
					</div>

					<div className={styles.gender_item}>
						<CustomRadioButton
							{...register("gender")}
							id="female"
							value="f"
							name="gender"
						/>
						<label htmlFor="female">Female</label>
					</div>

					<div className={styles.gender_item}>
						<CustomRadioButton
							{...register("gender")}
							id="custom"
							value="c"
							name="gender"
						/>
						<label htmlFor="custom">Custom</label>
					</div>

					<div
						className={genderWatcher === "c" ? styles.form_input_wrapper : styles.hide}
					>
						<label>
							Select your pronoun
							<span className="required">*</span>
						</label>

						<CustomSelect
							control={control}
							name="genderPronoun"
							error={!!errors.genderPronoun}
							options={customGenderValues}
							height={"35px"}
						/>
						{errors?.genderPronoun?.message && (
							<p className={styles.error_message}>Please select an option</p>
						)}
					</div>

					<div
						className={genderWatcher === "c" ? styles.form_input_wrapper : styles.hide}
					>
						<label>Enter your gender (optional)</label>
						<TextInput {...register("optionalGender")} placeholder="Type your gender" />
					</div>

					{errors?.gender?.message && (
						<p className={styles.error_message}>{errors?.gender?.message}</p>
					)}
				</div>

				<div className={styles.button_container}>
					<PrimaryButton
						className={styles.save_btn}
						text={"save"}
						isLoading={isLoading}
					/>
				</div>

				<PromptModal when={isDirty && shouldBlockSave} />
			</form>
		</div>
	);
};

export default EditUserInfo;
