import { maxPostLength } from "components/Utils/General";
import { nanoid } from "nanoid";
import { classNames } from "primereact/utils";
import { forwardRef, useState } from "react";
import styles from "./TextArea.module.scss";

const TextArea = forwardRef(
	(
		{
			label,
			error,
			maxLength = maxPostLength,
			onChange,
			value,
			required = false,
			infoText,
			className,
			placeholder = "Enter a description",
		},
		ref
	) => {
		const [isFocused, setIsFocused] = useState(false);
		const id = nanoid();

		return (
			<div className={classNames(styles.container, className)}>
				<div className={styles.label_wrapper}>
					<label htmlFor={id}>
						{label}
						{required && <span className="required">*</span>}
					</label>
					{infoText && <span className={styles.info}>{infoText}</span>}
				</div>

				<div
					className={classNames(styles.textarea_wrapper, {
						[styles.focused]: isFocused,
						[styles.error]: error,
					})}
				>
					<textarea
						ref={ref}
						maxLength={maxLength}
						onChange={onChange}
						id={id}
						value={value}
						onFocus={() => setIsFocused(true)}
						onBlur={() => setIsFocused(false)}
						placeholder={placeholder}
					/>

					<div className={styles.word_count_wrapper}>{`${
						value?.length || 0
					}/${maxLength}`}</div>
				</div>

				{error && <p className={styles.error_message}>{error.message}</p>}
			</div>
		);
	}
);

export default TextArea;
