import React, { useMemo } from "react";
import CustomRadioButton from "../../../Utils/SubComs/CustomRadioButton/CustomRadioButton";
import styles from "./ServiceSuggestionModal.module.css";
import { useDispatch } from "react-redux";
import { Controller, useForm } from "react-hook-form";
import { joiResolver } from "@hookform/resolvers/joi";
import { useEffect } from "react";
import gaawkServiceApi from "../../../../api/gaawk-service";
import CustomSelect from "../../../Utils/SubComs/CustomSelect/CustomSelect";
import TextInput from "../../../Utils/SubComs/Inputs/TextInput/TextInput";
import PrimaryButton from "../../../Utils/Button/PrimaryButton";
import { setModal, setSuggModal } from "../../../../store/slices/service";
import useGaawkServices from "../ServiceAdd/hooks/useGaawkServices";
import serviceSuggestionSchema from "./service-suggestion-schema";
import useMutate from "hooks/useMutate";
import TextArea from "components/Utils/SubComs/Inputs/TextArea/TextArea";

const ServiceSuggestionModal = () => {
	const dispatch = useDispatch();

	const {
		register,
		formState: {
			errors,
			// isDirty
		},
		handleSubmit,
		watch,
		control,
		reset,
		setValue,
		clearErrors,
	} = useForm({
		resolver: joiResolver(serviceSuggestionSchema),
		mode: "onChange",
		defaultValues: {
			category: "NEW",
		},
	});

	const category = watch("category");
	const gaawkLvl1Watcher = watch("gaawkLvl1");

	const resetAndClose = () => {
		reset();
		dispatch(setSuggModal(false));
		dispatch(setModal(true));
	};

	const {
		action: { mutate: addGaawkService },
	} = useMutate(gaawkServiceApi.addGaawkServiceSuggestion, resetAndClose, undefined, {
		meta: {
			successMessage: "Suggestion Sent!",
		},
	});

	const handleSave = async (data) => {
		const { name, description, gaawkLvl1, gaawkLvl2 } = data;

		let parentId = "";

		if (gaawkLvl2) parentId = gaawkLvl2.value;
		else if (gaawkLvl1 && !gaawkLvl2) {
			parentId = gaawkLvl1.value;
		}

		addGaawkService({
			name,
			description,
			parentId,
		});
	};

	useEffect(() => {
		setValue("gaawkLvl2", null);
	}, [gaawkLvl1Watcher]);

	//! services queries

	const { handleKeyUp, hasNextPage, fetchNextPage, isFetching, data } = useGaawkServices();

	const gaawkServices = useMemo(
		() =>
			data?.pages
				.map((page) =>
					page.map((service) => ({
						label: service.serviceL1.name,
						value: service.serviceL1.uuid,
					}))
				)
				.flat(),
		[data]
	);

	const {
		handleKeyUp: handleKeyUpLvl2,
		hasNextPage: hasNextPageLvl2,
		fetchNextPage: fetchNextPageLvl2,
		isFetching: isFetchingLvl2,
		data: dataLvl2,
	} = useGaawkServices(gaawkLvl1Watcher?.value);

	const gaawkServicesLvl2 = useMemo(
		() =>
			dataLvl2?.pages
				.map((page) =>
					page.map((service) => ({
						label: service.serviceL2?.name,
						value: service.serviceL2?.uuid,
					}))
				)
				.flat(),
		[dataLvl2]
	);

	return (
		<form
			className={styles.form_container}
			onSubmit={(e) => {
				e.stopPropagation();
				handleSubmit((data) => handleSave(data))(e);
			}}
			noValidate
		>
			<>
				If you think we've missed a category or a sub category from our master list and you
				would like us to add it, please share the details below...
			</>

			<div className={styles.option_item_wrapper}>
				<div className={styles.radio_button_wrapper}>
					<CustomRadioButton
						{...register("category")}
						value="NEW"
						id="NEW"
						name="category"
					/>

					<label htmlFor="NEW">New Category</label>
				</div>

				<div className={styles.info_text}>
					Your newly created service will be listed as a first level service.
				</div>
			</div>

			<div className={styles.option_item_wrapper}>
				<div className={styles.radio_button_wrapper}>
					<CustomRadioButton
						{...register("category")}
						value="SUB"
						id="SUB"
						name="category"
					/>

					<label htmlFor="SUB">Sub Category</label>
				</div>

				<div className={styles.info_text}>
					Your newly created service will be listed as a child to the category you select
					from the list below.
				</div>
			</div>

			{category === "SUB" && (
				<>
					<div className={styles.form_input_wrapper}>
						<label>Level One Services</label>

						<div onKeyUp={handleKeyUp}>
							<CustomSelect
								placeholder="Select Service"
								options={gaawkServices}
								height="35px"
								error={!!errors.gaawkLvl1}
								control={control}
								name="gaawkLvl1"
								isLoading={isFetching}
								onMenuScrollToBottom={() => {
									if (hasNextPage && !isFetching) fetchNextPage();
								}}
							/>
						</div>
						{errors?.gaawkLvl1?.message && (
							<p className={styles.error_message}>{errors?.gaawkLvl1?.message}</p>
						)}
					</div>

					{gaawkLvl1Watcher && (
						<div className={styles.form_input_wrapper}>
							<label>Level Two Services</label>

							<div onKeyUp={handleKeyUpLvl2}>
								<CustomSelect
									placeholder="Select Service"
									options={gaawkServicesLvl2}
									height="35px"
									error={!!errors.gaawkLvl2}
									control={control}
									name="gaawkLvl2"
									isLoading={isFetchingLvl2}
									onMenuScrollToBottom={() => {
										if (hasNextPageLvl2 && !isFetchingLvl2) fetchNextPageLvl2();
									}}
									isClearable={true}
								/>
							</div>
							{errors?.gaawkLvl2?.message && (
								<p className={styles.error_message}>{errors?.gaawkLvl2?.message}</p>
							)}
						</div>
					)}
				</>
			)}

			<div className={styles.form_input_wrapper}>
				<label>
					Service Name<span className="required">*</span>
				</label>

				<div className={styles.input_wrapper}>
					<span>+</span>
					<TextInput
						{...register("name")}
						error={!!errors.name}
						placeholder="Service Name"
					/>
				</div>

				{errors?.name?.message && (
					<p className={styles.error_message}>{errors?.name?.message}</p>
				)}
			</div>
			{/* <div className={styles.form_input_wrapper}>
				<label>
					Description<span className="required">*</span>
				</label>
				<div
					className={`${styles.textarea_wrapper} ${
						!!errors.description ? styles.error : undefined
					}`}
				>
					<textarea {...register("description")} placeholder="Enter description here" />
				</div>
				{errors?.description?.message && (
					<p className={styles.error_message}>{errors?.description?.message}</p>
				)}
			</div> */}

			<Controller
				name={"description"}
				control={control}
				render={({ field, fieldState }) => (
					<TextArea
						required={true}
						className={styles.textarea_wrapper}
						label="Description"
						error={fieldState.error}
						{...field}
					/>
				)}
			/>

			<PrimaryButton className={styles.send_btn} text={"send"} />
		</form>
	);
};

export default ServiceSuggestionModal;
