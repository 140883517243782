import { useQueryClient } from "@tanstack/react-query";
import useCurrentUser from "hooks/useCurrentUser";
import useInfiniteScroll from "hooks/useInfiniteScroll";
import useMutate from "hooks/useMutate";
import { postsKeys } from "queryKeys/posts-key-factory";
import { forwardRef, useImperativeHandle, useRef, useState } from "react";
import postApi from "../../api/post";
import useApi from "../../hooks/useApi";
import CommentInput from "./CommentInput";
import styles from "./Replies.module.css";
import ReplyItem from "./ReplyItem";

const PAGE_SIZE = 5;

const Replies = forwardRef(({ commentId, repliesCount, onUpdateRepliesCount }, ref) => {
	const getRepliesApi = useApi(postApi.getCommentReplies, true, true);

	const queryClient = useQueryClient();

	const {
		action: { mutate: addReply },
	} = useMutate(postApi.addReply, () => {
		queryClient.invalidateQueries(postsKeys.replies(commentId));
		onUpdateRepliesCount(commentId, "INCREMENT");
	});

	const {
		action: { mutate: deleteReply },
	} = useMutate(postApi.deleteReply, () => {
		queryClient.invalidateQueries(postsKeys.replies(commentId));
		onUpdateRepliesCount(commentId, "DECREMENT");
	});

	const { profileImage, type, url, name, firstName } = useCurrentUser();

	useImperativeHandle(ref, () => ({
		replyComment(commentUrl) {
			handleReplyClick(commentUrl);
		},
	}));

	const inputRef = useRef(null);
	const [reply, setReply] = useState("");

	const fetchReplies = async ({ pageParam = 0, queryKey }) => {
		const [_, __, commentId] = queryKey;
		const response = await getRepliesApi.request(commentId, pageParam, PAGE_SIZE);
		return response.data;
	};

	const {
		items: replies,
		isFetchingNextPage,
		hasNextPage,
		loadMore,
	} = useInfiniteScroll({
		queryKey: postsKeys.replies(commentId),
		queryFn: fetchReplies,
		pageSize: PAGE_SIZE,
	});

	const handleTextChange = (text) => {
		setReply(text);
	};

	const handleReplyClick = (url) => {
		setReply(`@${url}  `);
		inputRef.current.focus();
	};

	const handleEmojiClick = (_, emojiObject) => {
		setReply((prevState) => prevState + emojiObject.emoji);
	};

	const handleConfirmReply = () => {
		addReply({ object: { commentId, reply } });
		setReply("");
	};

	const handleReplyUpdate = (updatedReply) => {
		queryClient.setQueryData(postsKeys.replies(commentId), (oldData) => {
			return {
				...oldData,
				pages: oldData.pages.map((page) =>
					page.map((reply) => (reply.uuid === updatedReply.uuid ? updatedReply : reply))
				),
			};
		});
	};

	// ! ========= REPLY EDIT =========

	const [newText, setNewText] = useState("");

	const handleReplyEdit = (text) => {
		setNewText(text);
	};

	// ! ========= REPLY DELETE =========

	const handleDeleteReply = (replyId) => {
		deleteReply(replyId);
	};

	const replyList =
		replies.length > 0 &&
		replies.map((reply) => {
			return (
				<ReplyItem
					key={reply.uuid}
					profileImg={profileImage?.originalImageURL}
					name={type === "COMPANY" ? name : firstName}
					type={type}
					url={url}
					commentId={commentId}
					reply={reply}
					onReplyUpdate={handleReplyUpdate}
					onTextChange={handleReplyEdit}
					editedText={newText}
					onDelete={handleDeleteReply}
					onReply={handleReplyClick}
				/>
			);
		});

	return (
		<div className={styles.container}>
			<CommentInput
				ref={inputRef}
				profileImg={profileImage?.originalImageURL}
				name={type === "COMPANY" ? name : firstName}
				type={type}
				url={url}
				onTextChange={handleTextChange}
				text={reply}
				onEmojiClick={handleEmojiClick}
				isEditing={false}
				onConfirm={handleConfirmReply}
			/>

			<>{replyList}</>

			{replies.length > 0 && hasNextPage && (
				<div className={styles.load_more_button}>
					<button onClick={loadMore}>{repliesCount - replies.length} more replies</button>
				</div>
			)}
		</div>
	);
});

export default Replies;
