import { joiResolver } from "@hookform/resolvers/joi";
import loginSchema from "components/Registeration/Schema/login-schema";
import PrimaryButton from "components/Utils/Button/PrimaryButton";
import Checkbox from "components/Utils/SubComs/Inputs/Checkbox/Checkbox";
import InputWrapper from "components/Utils/SubComs/Inputs/InputWrapper/InputWrapper";
import TextInput from "components/Utils/SubComs/Inputs/TextInput/TextInput";
import md5 from "md5";
import { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { profileInfoUpdated, userLoginUpdated } from "store/slices/user";
import styles from "./LoginForm.module.scss";
import routes from "components/Routing/routing-keys";
import useMutate from "hooks/useMutate";
import profileApi from "api/profile";

const LoginForm = ({ onRedirect, onForgotPassword, from }) => {
	const navigate = useNavigate();
	const dispatch = useDispatch();

	const [keepLogged, setKeepLogged] = useState(true);
	// const [loginData, setLoginData] = useState(undefined);

	const {
		register,
		formState: { errors },
		handleSubmit,
	} = useForm({ resolver: joiResolver(loginSchema), mode: "onSubmit" });

	const {
		action: { mutate: login },
	} = useMutate(profileApi.login, (response) => {
		dispatch(userLoginUpdated(response.data));
	});

	const {
		action: { mutate: getUserProfile },
	} = useMutate(profileApi.getUserProfile, (response) =>
		dispatch(profileInfoUpdated(response.data))
	);

	const handleLogin = (data) => {
		const { email, password } = data;
		login({ email, password: md5(password) });
	};

	const userLoginInfo = useSelector((state) => state.user.userLogin);
	const profile = useSelector((state) => state.user.profileInfo);

	const didMountRef = useRef(false);

	useEffect(() => {
		if (didMountRef.current) {
			if (userLoginInfo && Object.keys(userLoginInfo).length > 0) {
				const { uuid, mobileVerified, emailVerified } = userLoginInfo;

				localStorage.setItem("keepLogged", keepLogged);
				localStorage.setItem("uuid", uuid);

				// keepLogged
				// 	? localStorage.setItem("uuid", uuid)
				// 	: sessionStorage.setItem("uuid", uuid);

				window.dispatchEvent(new Event("storage"));

				if (!mobileVerified && !emailVerified) {
					onRedirect();
				} else if (profile && Object.keys(profile).length > 0) {
					navigate(from ? from : routes.root, { replace: true });
				} else {
					getUserProfile(uuid);
				}
			}
		}
		didMountRef.current = true;
	}, [
		dispatch,
		userLoginInfo,
		profile,
		// keepLogged,
		navigate,
		onRedirect,
	]);

	return (
		<form
			noValidate
			className={styles.login_form}
			onSubmit={handleSubmit((data) => handleLogin(data))}
		>
			<div className={styles.logo_wrapper}>
				<img src={require("images/gaawk-logos/gaawk-default-black.svg").default} alt="" />
			</div>

			<InputWrapper
				label="Email"
				error={errors?.email}
				component={
					<TextInput
						{...register("email")}
						placeholder="Enter email"
						autoComplete="on"
						type="email"
					/>
				}
			/>

			<InputWrapper
				label="Password"
				error={errors?.password}
				component={
					<TextInput
						{...register("password")}
						placeholder="Enter password"
						isPassword={true}
						autoComplete="on"
					/>
				}
			/>

			<div className={styles.login_extra_field}>
				<Checkbox
					checked={keepLogged}
					customBackgroundColor="gaawk"
					w={"15px"}
					h={"15px"}
					onChange={() => setKeepLogged((prevState) => !prevState)}
					id="keepMeLogged"
				/>
				<label htmlFor="keepMeLogged">Keep me logged in</label>
			</div>

			<PrimaryButton text={"login"} className={styles.login_btn} />

			<div className={styles.link_container}>
				<button onClick={onForgotPassword}>Forgot password?</button>
			</div>
		</form>
	);
};

export default LoginForm;
