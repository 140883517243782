import client from "./client";

const endpoint = "/gallery";

const getAlbums = (requestedProfileId, page, size) =>
	client.get(
		endpoint + `/album?requestedProfileId=${requestedProfileId}&page=${page}&size=${size}`
	);

const getAlbumById = (albumId) => client.get(endpoint + `/album/byId?albumId=${albumId}`);

const addAlbum = (body) => client.post(endpoint + "/album", body);
const editAlbum = (body) => client.put(endpoint + "/album", body);
const deleteAlbum = (albumId) => client.delete(endpoint + `/album?albumId=${albumId}`);

const getAlbumItems = (parentAlbumId, page, size) =>
	client.get(endpoint + `/items?parentAlbumId=${parentAlbumId}&page=${page}&size=${size}`);

const updateAlbumItem = (albumId, body) => client.put(endpoint + `/items?albumId=${albumId}`, body);

const addAlbumFiles = (data, onUploadProgress) =>
	client.post(endpoint + "/items", data, {
		// onUploadProgress: (progress) => onUploadProgress(progress.loaded / progress.total),
		onUploadProgress: (progressEvent) => {
			const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
			onUploadProgress(percentCompleted);
		},
	});

const deleteFile = (albumId, postId) =>
	client.delete(endpoint + `/items?albumId=${albumId}&postId=${postId}`);

const updateAlbumCover = (albumId, albumItemId) =>
	client.put(endpoint + `/album/cover?albumId=${albumId}&albumItemId=${albumItemId}`);

// eslint-disable-next-line import/no-anonymous-default-export
export default {
	getAlbums,
	getAlbumById,
	addAlbum,
	editAlbum,
	deleteAlbum,
	getAlbumItems,
	updateAlbumItem,
	deleteFile,
	addAlbumFiles,
	updateAlbumCover,
};
