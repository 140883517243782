import { MutationCache, QueryCache, QueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";

export const queryClient = new QueryClient({
	queryCache: new QueryCache({
		onSuccess: (data, query) => {
			if (query.meta?.successMessage) {
				toast.success(query.meta.successMessage);
			}
		},
		onError: (error, query) => {
			// Only show toast for non-500 & non-404 errors that aren't already being handled by the error boundary
			//TODO >> also exclude 401 unauthorized / 403 forbidden if using error boundary ?
			//* adding `enableError: false` in meta object will disable the toast
			if (
				((error.status < 500 && error.status !== 404) ||
					(error.code < 500 && error.code !== 404)) &&
				query.meta?.enableError !== false
			) {
				if (query.meta?.errorMessage) {
					toast.error(`${query.meta.errorMessage}`);
				} else {
					toast.error(error.message);
				}
			}
		},
	}),
	mutationCache: new MutationCache({
		onSuccess: (data, variables, context, mutation) => {
			if (mutation.meta?.successMessage) {
				toast.success(mutation.meta?.successMessage);
			}
		},
		onError: (error, variables, context, mutation) => {
			//* adding `enableError: false` in meta object will disable the toast
			if ((error.status < 500 || error.code < 500) && mutation.meta?.enableError !== false) {
				if (mutation.meta?.errorMessage) {
					toast.error(`${mutation.meta.errorMessage}`);
				} else {
					toast.error(error.message);
				}
			}
		},
	}),
	defaultOptions: {
		queries: {
			retry: false,
			refetchOnWindowFocus: false,
			useErrorBoundary: (error) => {
				// Return true to throw to error boundary for 500s and 404s
				//TODO >> also add 401 unauthorized / 403 forbidden if using dedicated error boundary page?
				return (
					error.status >= 500 ||
					error.code >= 500 ||
					error.status === 404 ||
					error.code === 404
				);
			},
		},
		mutations: {
			useErrorBoundary: (error) => {
				return error.status >= 500 || error.code >= 500;
			},
		},
	},
});
