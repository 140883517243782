// components/Errors/QueryErrors.js
import { Message } from "primereact/message";

const errorStyles = {
	width: "100%",
	marginBottom: "1rem",
};

export function DefaultErrorComponent({ error }) {
	return (
		<Message
			severity="error"
			text={error.message || "An error occurred while fetching data"}
			style={errorStyles}
		/>
	);
}

// export const NetworkErrorComponent = ({ error }) => (
//   <Message
//     severity="error"
//     text="Please check your internet connection and try again"
//     style={errorStyles}
//   />
// );

// export const NotFoundErrorComponent = ({ error }) => (
//   <Message
//     severity="error"
//     text="The requested resource could not be found"
//     style={errorStyles}
//   />
// );

// export const ServerErrorComponent = ({ error }) => (
//   <Message
//     severity="error"
//     text="An unexpected error occurred. Please try again later"
//     style={errorStyles}
//   />
// );

export function selectErrorComponent(error) {
	//   if (error.code === 404) return NotFoundErrorComponent;
	//   if (error.code >= 500) return ServerErrorComponent;
	//   if (error.message?.includes('network')) return NetworkErrorComponent;
	return DefaultErrorComponent;
}
