import { useQuery } from "@tanstack/react-query";
import { selectErrorComponent } from "components/Errors/ErrorComponents";

export function useCustomQuery({ queryKey, queryFn, enabled = true, options = {} }) {
	const { errorComponent, ...restOptions } = options;

	const query = useQuery({
		queryKey,
		queryFn,
		enabled,
		...restOptions,
	});

	if (query.error) {
		const ErrorComponent = errorComponent || selectErrorComponent(query.error);
		query.errorComponent = <ErrorComponent error={query.error} />;
	}

	return query;
}
