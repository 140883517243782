import { joiResolver } from "@hookform/resolvers/joi";
import { useQueryClient } from "@tanstack/react-query";
import routes from "components/Routing/routing-keys";
import PromptModal from "components/Utils/Prompt/PromptModal";
import { useCustomQuery } from "hooks/useCustomQuery";
import useEthnicities from "hooks/useEthnicities";
import useFetchLanguages from "hooks/useFetchLanguages";
import useMutate from "hooks/useMutate";
import Joi from "joi";
import { accentsKeys } from "queryKeys/accents-key-factory";
import { languagesKeys } from "queryKeys/languages-key-factory";
import { locationKeys } from "queryKeys/location-key-factory";
import { specialProfileKeys } from "queryKeys/specialProfile-key-factory";
import { useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import accentApi from "../../../../../../api/accent";
import specialProfileApi from "../../../../../../api/special-profile";
import useApi from "../../../../../../hooks/useApi";
import useFetchLocation from "../../../../../../hooks/useFetchLocation";
import PrimaryButton from "../../../../../Utils/Button/PrimaryButton";
import CustomSelect from "../../../../../Utils/SubComs/CustomSelect/CustomSelect";
import InfiniteSearchInput from "../../../../../Utils/SubComs/Inputs/InfiniteSearchInput/InfiniteSearchInput";
import {
	defaultFormatter,
	languageFormatter,
	locationFormatter,
	unduplicateValue,
} from "../../../../../Utils/SubComs/Inputs/SearchableInput/response-formatter";
import Tag from "../../../../../Utils/SubComs/Tags/Tag";
import TagContainer from "../../../../../Utils/SubComs/Tags/TagContainer";
import styles from "./GeneralDetailsEntry.module.css";

const itemsPerPage = 20;

const GeneralDetailsEntry = ({ profile }) => {
	const { uuid: specialProfileId } = profile;
	const { userUrl } = useParams();

	const { state } = useLocation();

	const queryClient = useQueryClient();

	const { bio = "", ethnicity, nationalities, accents, languages } = profile;

	const [shouldBlockSave, setShouldBlockSave] = useState(true);

	const { data: ethnicities } = useEthnicities();

	const getAccentsApi = useApi(accentApi.getAccents, true, true);

	const fetchAccents = async () => {
		const response = await getAccentsApi.request();
		return response.data;
	};

	const { data: accentsList } = useCustomQuery({
		queryKey: accentsKeys.all,
		queryFn: fetchAccents,
		enabled: true,
		options: { staleTime: 300000 }, // 5 minutes
	});

	const schema = Joi.object({
		ethnicity: Joi.object({
			value: Joi.string(),
			label: Joi.string(),
		})
			.label("Ethnicity")
			.allow(null),

		bio: Joi.string().allow("").label("Summary (Bio)"),
		nationalities: Joi.array().min(0).label("Nationalities"),
		languages: Joi.array().min(0).label("Languages Spoken"),
		accents: Joi.array().min(0).label("Dialects / Accents"),
	});

	const {
		register,
		setValue,
		formState: { errors, isDirty },
		watch,
		handleSubmit,
		control,
	} = useForm({
		resolver: joiResolver(schema),
		mode: "onSubmit",
		defaultValues: {
			ethnicity: ethnicity ? { value: ethnicity.uuid, label: ethnicity.name } : null,
			bio: bio ?? "",
			nationalities: nationalities.map((nationality) => ({
				label: nationality.countryDTO.name,
				value: nationality.id,
			})),
			languages: languages.map((language) => ({
				label: language.name,
				value: language.code,
			})),
			accents: accents.map((accent) => ({
				label: accent.name,
				value: accent.uuid,
			})),
		},
	});

	// !==== nationalities ==========

	const nationalityWatcher = watch("nationalities");

	const nationalitiesList = useMemo(
		() =>
			nationalityWatcher.map((nationality, index) => (
				<Tag
					key={nationality.value}
					itemName={nationality.label}
					onRemove={() => handleRemoveNationality(index)}
				/>
			)),
		[nationalityWatcher]
	);

	const handleSelectNationality = (nationality) => {
		setValue("nationalities", [...nationalityWatcher, nationality], {
			shouldDirty: true,
		});
	};

	const handleRemoveNationality = (nationalityIndex) => {
		const updatedNationalities = [...nationalityWatcher].filter(
			(_, index) => index !== nationalityIndex
		);
		setValue("nationalities", updatedNationalities, { shouldDirty: true });
	};

	// !============ languages =================
	const languageWatcher = watch("languages");

	const languagesList = useMemo(
		() =>
			languageWatcher.map((language, index) => (
				<Tag
					key={language.value}
					itemName={language.label}
					onRemove={() => handleRemoveLanguage(index)}
				/>
			)),
		[languageWatcher]
	);

	const handleSelectLanguages = (language) => {
		setValue("languages", [...languageWatcher, language], {
			shouldDirty: true,
		});
	};

	const handleRemoveLanguage = (languageIndex) => {
		const updatedLanguages = [...languageWatcher].filter((_, index) => index !== languageIndex);
		setValue("languages", updatedLanguages, { shouldDirty: true });
	};

	const fetchLanguages = useFetchLanguages();

	// !=================== ACCENTS ======================

	const accentsWatcher = watch("accents");

	const selectedAccentsList = useMemo(
		() =>
			accentsWatcher.map((accent, index) => (
				<Tag
					key={accent.value}
					itemName={accent.label}
					onRemove={() => handleRemoveAccent(index)}
				/>
			)),
		[accentsWatcher]
	);

	const handleSelectAccent = (accent) => {
		setValue("accents", [...accentsWatcher, accent], { shouldDirty: true });
	};

	const handleRemoveAccent = (accentIndex) => {
		const updatedAccents = [...accentsWatcher].filter((_, index) => index !== accentIndex);
		setValue("accents", updatedAccents, { shouldDirty: true });
	};

	//  !============================
	const navigate = useNavigate();
	const {
		action: { mutate: updateDetails, isLoading },
	} = useMutate(
		specialProfileApi.updateGeneralDetails,
		() => {
			queryClient.invalidateQueries(specialProfileKeys.detail(userUrl));
			navigate(
				routes.specialProfile(userUrl, state?.agencyId ? `?agency=${state?.agencyId}` : "")
			);
		},
		() => setShouldBlockSave(true)
	);

	const handleSave = (data) => {
		setShouldBlockSave(false);

		const { accents, bio, ethnicity, languages, nationalities } = data;

		const body = {
			accents: accents.map((accent) => accent.value),
			bio,
			...(ethnicity && { ethnicityId: ethnicity.value }),
			languages: languages.map((language) => language.value),
			nationalities: nationalities.map((nationality) => nationality.value),
		};

		// updateGeneralDetailsApi.request(specialProfileId, body);
		updateDetails({ uuid: specialProfileId, body });
	};

	const fetchLocation = useFetchLocation(false, itemsPerPage);

	return (
		<form onSubmit={handleSubmit((data) => handleSave(data))} noValidate>
			<div className={styles.section}>
				<h3>Summary (Bio)</h3>
				<textarea placeholder="Enter your bio here" {...register("bio")} />
			</div>

			<div className={styles.section}>
				<h3>General Details</h3>

				<div className={styles.form_input_wrapper}>
					<label>Ethnicity</label>
					<CustomSelect
						options={ethnicities?.map(defaultFormatter)}
						height="35px"
						error={!!errors.ethnicity}
						control={control}
						name="ethnicity"
						isClearable={true}
					/>
				</div>

				<InfiniteSearchInput
					queryName={locationKeys.countries}
					queryFn={fetchLocation}
					itemsPerPage={itemsPerPage}
					formatter={locationFormatter}
					label={"Nationalities"}
					onChange={handleSelectNationality}
					selected={nationalityWatcher}
					unDuplicateFn={(passedData) => unduplicateValue(nationalityWatcher, passedData)}
					openMenuOnClick={true}
				/>

				{nationalityWatcher.length > 0 && (
					<TagContainer label="Selected Nationalities" items={nationalitiesList} />
				)}

				<InfiniteSearchInput
					queryName={languagesKeys.search}
					queryFn={fetchLanguages}
					itemsPerPage={itemsPerPage}
					label={"Languages Spoken"}
					onChange={handleSelectLanguages}
					selected={languageWatcher}
					formatter={languageFormatter}
					unDuplicateFn={(passedData) => unduplicateValue(languageWatcher, passedData)}
					openMenuOnClick={true}
				/>

				{languageWatcher.length > 0 && (
					<TagContainer label="Selected Languages" items={languagesList} />
				)}

				<div className={styles.form_input_wrapper}>
					<label>Dialects Spoken / Accents</label>
					<CustomSelect
						value={""}
						options={accentsList
							?.map(defaultFormatter)
							.filter(
								(accent) =>
									!accentsWatcher.map((item) => item.value).includes(accent.value)
							)}
						height="35px"
						onChange={handleSelectAccent}
					/>
				</div>

				{accentsWatcher.length > 0 && (
					<TagContainer
						label="Selected Dialects Spoken / Accents"
						items={selectedAccentsList}
					/>
				)}
			</div>

			<div className={styles.button_container}>
				<PrimaryButton className={styles.save_btn} text={"save"} isLoading={isLoading} />
			</div>

			<PromptModal when={isDirty && shouldBlockSave} />
		</form>
	);
};

export default GeneralDetailsEntry;
