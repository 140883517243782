import { useQueryClient } from "@tanstack/react-query";
import profileApi from "api/profile";
import GroupMember from "components/Chat/GroupMember";
import routes from "components/Routing/routing-keys";
import DeleteItemModal from "components/Utils/DeleteItemModal/DeleteItemModal";
import Popover from "components/Utils/Popover/Popover";
import UserListModal from "components/Utils/UserListModal/UserListModal";
import useDirectMessage from "hooks/useDirectMessage";
import useMutate from "hooks/useMutate";
import moreIcon from "images/3dot-grey.svg";
import chatIcon from "images/comment-black.svg";
import contactIcon from "images/contact-icon.svg";
import deleteIcon from "images/delete-black.svg";
import { profileKeys } from "queryKeys/profile-key-factory";
import { useRef, useState } from "react";
import { useNavigate } from "react-router";

const itemsPerPage = 20;

const EmployeeModal = ({ show, onClose, userInfo, canEdit, onUpdateCount }) => {
	const popoverRef = useRef(null);
	const navigate = useNavigate();
	const { handleDirectMessage } = useDirectMessage();

	const queryClient = useQueryClient();

	//!=================== delete employee ===================
	const [deleteModal, setDeleteModal] = useState(false);
	const [selectedEmployee, setSelectedEmployee] = useState();

	const {
		action: { mutate: removeEmployee, isLoading },
	} = useMutate(profileApi.deleteEmployee, () => {
		queryClient.invalidateQueries(profileKeys.employeesPaginated(userInfo.uuid)); //* invalidating for this modal
		queryClient.invalidateQueries(profileKeys.employees(userInfo.uuid)); //* invalidating for the profileTab
		setSelectedEmployee(undefined);
		setDeleteModal(false);
		onUpdateCount();
	});

	const handleRemoveEmployee = () => {
		removeEmployee(selectedEmployee.uuid);
	};
	// !======================================================

	const fetchEmployees = async ({ pageParam = 0, signal, queryKey }) => {
		const [_, companyId, __, query] = queryKey;
		const response = await profileApi.getVerifiedEmployees(
			pageParam,
			itemsPerPage,
			companyId,
			query
		);
		return response.data;
	};

	const renderEmployee = ({ user, ref }) => (
		<GroupMember
			ref={ref}
			key={user.uuid}
			redirect={true}
			data={user}
			rightSideAction={
				<Popover
					ref={popoverRef}
					render={
						<div className="popover_container">
							<button
								className="popover_item"
								onClick={() => {
									onClose();
									navigate(routes.profile(user.url));
								}}
							>
								<img src={contactIcon} alt="" />
								<span>View Profile</span>
							</button>

							<button
								className="popover_item"
								onClick={(e) => {
									e.preventDefault();
									handleDirectMessage(user);
								}}
							>
								<img src={chatIcon} alt="" />
								<span>Message this person</span>
							</button>

							{canEdit && (
								<button
									className="popover_item"
									onClick={(e) => {
										e.preventDefault();
										setSelectedEmployee(user);
										setDeleteModal(true);
									}}
								>
									<img src={deleteIcon} alt="" />
									<span>Remove Employee</span>
								</button>
							)}
						</div>
					}
				>
					<button onClick={(e) => e.preventDefault()}>
						<img src={moreIcon} alt="options" />
					</button>
				</Popover>
			}
		/>
	);

	return (
		<>
			<UserListModal
				show={show && !deleteModal}
				onClose={onClose}
				title="Employees"
				fetchUsers={fetchEmployees}
				renderUser={renderEmployee}
				hasSearch={true}
				queryKey={(query) => profileKeys.employeesPaginated(userInfo.uuid, query)}
				pageSize={itemsPerPage}
			/>

			<DeleteItemModal
				show={deleteModal}
				onClose={() => setDeleteModal(false)}
				title="Remove Employee"
				text={`Are you sure you want to remove ${selectedEmployee?.name} from your list of employees? This action can NOT be undone.`}
				onConfirm={handleRemoveEmployee}
				isLoading={isLoading}
			/>
		</>
	);
};

export default EmployeeModal;
