import { useMemo } from "react";
import styles from "./TabMenu.module.css";
import ScrollContainer from "react-indiana-drag-scroll";
import { classNames } from "primereact/utils";

const TabMenu = ({
	menuItems,
	selectedTab,
	onSelectedTabChanged,
	customStyle,
	isLoading = false,
}) => {
	const menu = useMemo(
		() =>
			Object.entries(menuItems).map(([key, value]) => {
				return (
					<button
						key={key}
						className={classNames(styles.menuBtn, customStyle, {
							[styles.selected]: selectedTab.toLowerCase() === `${key.toLowerCase()}`,
						})}
						onClick={() => onSelectedTabChanged(key)}
						disabled={isLoading}
					>
						{value}
					</button>
				);
			}),
		[menuItems, selectedTab]
	);

	return (
		<ScrollContainer className={styles.scroll_container}>
			<div className={styles.tabs_wrapper}>{menu}</div>
		</ScrollContainer>
	);
};

export default TabMenu;
