import { useMemo } from "react";
import TagContainer from "../../Tags/TagContainer";
import InfiniteSearchInput from "../InfiniteSearchInput/InfiniteSearchInput";
import { unduplicateValue } from "../SearchableInput/response-formatter";
import styles from "./MultiSelectInput.module.scss";
import Tag from "../../Tags/Tag";
import { classNames } from "primereact/utils";

// old react-select version 4.3.1
const MultiSelectInput = ({
	queryName,
	queryFn,
	data,
	itemsPerPage,
	formatter,
	label,
	placeholder,
	limit = undefined,
	onChange,
	onRemoveItem,
	infoText,
	creatable,
	customStyle,
	tagContainerCustomStyle,
	required = false,
	infoTextItalic = false,
	customDisabled,
	borderTagContainer = true,
	error,
}) => {
	const itemsList = useMemo(
		() =>
			data?.map((item, index) => (
				<Tag key={item.value} itemName={item.label} onRemove={() => onRemoveItem(index)} />
			)),
		[data]
	);

	return (
		<div
			className={classNames(styles.container, {
				[styles.disabled]: customDisabled,
			})}
		>
			<InfiniteSearchInput
				queryName={queryName}
				queryFn={queryFn}
				itemsPerPage={itemsPerPage}
				formatter={formatter}
				label={label}
				required={required}
				placeholder={placeholder}
				onChange={onChange}
				disabled={data?.length === limit || customDisabled}
				selected={data}
				unDuplicateFn={(passedData) => unduplicateValue(data, passedData)}
				creatable={creatable}
				customStyle={customStyle}
				error={error}
				openMenuOnClick={true}
			/>

			{infoText && (
				<div className={`${infoTextItalic ? styles.info_text_italic : styles.info_text}`}>
					{infoText}
				</div>
			)}

			{data?.length > 0 && !customDisabled && (
				<div className={styles.tags_container}>
					<>
						<TagContainer
							label={`Selected ${label}`}
							items={itemsList}
							border={borderTagContainer}
							customStyle={tagContainerCustomStyle}
						/>

						{!!limit && (
							<div className={styles.info_text}>
								{data?.length < limit
									? `You can still add ${limit - data?.length} more.`
									: `You can't add more.`}
							</div>
						)}
					</>
				</div>
			)}
		</div>
	);
};

export default MultiSelectInput;
