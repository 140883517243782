import { joiResolver } from "@hookform/resolvers/joi";
import PrimaryButton from "components/Utils/Button/PrimaryButton";
import TextInput from "components/Utils/SubComs/Inputs/TextInput/TextInput";
import { useEffect, useMemo, useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import ProgressIndicator from "../../ServicesComponent/ProgressIndicator";
import styles from "../ServiceAdd.module.css";
import { useTag } from "../hooks/useTag";
import { secondStep } from "../schema";
// import { ModalLocationContent } from "components/Utils/LocationListModal/ContentModal";
import CustomCheckbox from "components/Utils/SubComs/Inputs/CustomCheckbox/CustomCheckbox";
import { locationFormatter } from "components/Utils/SubComs/Inputs/SearchableInput/response-formatter";
import { setLocationModal, setStep, setSuggModal, updateStep2 } from "store/slices/service";
import ServiceSuggestionModal from "../../ServiceSuggestionModal/ServiceSuggestionModal";
// import RectangleCheckbox from "components/Utils/SubComs/Inputs/Checkbox/RectangleCheckbox";
// import InputWrapper from "components/Utils/SubComs/Inputs/InputWrapper/InputWrapper";
// import InfiniteSearchInput from "components/Utils/SubComs/Inputs/InfiniteSearchInput/InfiniteSearchInput";
// import BasicCheckbox from "components/Utils/SubComs/Inputs/BasicCheckbox/BasicCheckbox";
import CustomSelect from "components/Utils/SubComs/CustomSelect/CustomSelect";
import MultiSelectInput from "components/Utils/SubComs/Inputs/MultiSelectInput/MultiSelectInput";
import useFetchLocation from "hooks/useFetchLocation";
import { useGetUserLocationsList } from "hooks/useGetUserLocationList";
import infoIcon from "images/icon-info-gray.png";
import { classNames } from "primereact/utils";
import UserLocationsModal from "../UserLocationsModal";
import useGaawkServices from "../hooks/useGaawkServices";
import { locationKeys } from "queryKeys/location-key-factory";
import { maxPostLength } from "components/Utils/General";
import TextArea from "components/Utils/SubComs/Inputs/TextArea/TextArea";

const itemsPerPage = 50;
const sizeLimit = 500;

const Stage2 = () => {
	const dispatch = useDispatch();

	// const { modalShow } = useSelector((state) => state.location);

	const { step2, service, suggModal, locationModal } = useSelector((state) => state.service);

	//*  when editing, will revert back to existing locations if unchecking box

	const originalLocationsRef = useRef([]);

	useEffect(() => {
		if (service) {
			setValue("locationIds", originalLocationsRef?.current);
		}
	}, [service]);

	//* commented below since removed user locations list for now (to be replaced with above useEffect once reimplemented)
	// const [myLocationCheckbox, setMyLocationCheckbox] = useState(false);

	// const { profileLocationLists } =
	// 	useGetUserLocationsList(myLocationCheckbox);

	// useEffect(() => {
	// 	if (myLocationCheckbox) {
	// 		if (profileLocationLists && profileLocationLists?.length > 0) {
	// 			setValue(
	// 				"locationIds",
	// 				profileLocationLists?.map((item) => locationFormatter(item))
	// 			);
	// 		} else {
	// 			setValue("locationIds", []);
	// 		}
	// 	} else if (!myLocationCheckbox && service) {
	// 		setValue("locationIds", originalLocationsRef?.current);
	// 	}
	// }, [profileLocationLists, myLocationCheckbox, service]);

	// *============================================

	// const {
	// 	title: name,
	// 	tag,
	// 	description,
	// 	gaawkLvl1,
	// 	gaawkLvl2,
	// 	gaawkLvl3,
	// 	tagAlbumMedia = true,
	// 	tagProducts = true,
	// 	locationIds,
	// 	worldWide,
	// } = step2 || {};

	// console.log("🚀 ~ service >>", service);
	// console.log("🚀 ~ step2 >>", step2);

	// const createDefaultValues = () => ({
	// 	name: step2 ? name : "",
	// 	tag: step2 ? tag : "",
	// 	description: step2 ? description : "",
	// 	gaawkLvl1: step2 ? gaawkLvl1 : null,
	// 	gaawkLvl2: step2 ? gaawkLvl2 : null,
	// 	gaawkLvl3: step2 ? gaawkLvl3 : null,
	// 	tagAlbumMedia: step2 ? tagAlbumMedia : true,
	// 	tagProducts: step2 ? tagProducts : true,
	// 	worldWide: step2 ? worldWide : false,
	// 	locationIds: step2 ? locationIds : [],
	// });

	const {
		register,
		formState: { errors },
		handleSubmit,
		watch,
		setValue,
		control,
		reset,
		clearErrors,
	} = useForm({
		resolver: joiResolver(secondStep),
		defaultValues: {
			name: "",
			tag: "",
			description: "",
			gaawkLvl1: null,
			gaawkLvl2: null,
			gaawkLvl3: null,
			tagAlbumMedia: true,
			tagProducts: true,
			worldWide: false,
			locationIds: [],
		},
	});

	// console.log(
	// 	"%c 🚫 ERRORS 🚫 >>",
	// 	"color: red; font-weight: bolder;",
	// 	errors
	// );

	const nameWatcher = watch("name");
	const locationsWatcher = watch("locationIds");
	const worldWideWatcher = watch("worldWide");
	const gaawkLvl1Watcher = watch("gaawkLvl1");
	const gaawkLvl2Watcher = watch("gaawkLvl2");

	// console.log("tagAlbumMedia", watch("tagAlbumMedia"));
	// console.log("tagProducts", watch("tagProducts"));
	// console.log("🚀 ~ locationsWatcher >>", locationsWatcher);
	// console.log("====");
	// console.log("🚀 ~ worldWideWatcher >>", worldWideWatcher);

	useTag(nameWatcher, setValue, step2, service);

	useEffect(() => {
		if (step2) {
			const {
				title: name,
				locationIds,
				...rest
				// tag,
				// description,
				// gaawkLvl1,
				// gaawkLvl2,
				// gaawkLvl3,
				// tagAlbumMedia = true,
				// tagProducts = true,
				// worldWide,
			} = step2 || {};

			reset({
				name,
				locationIds,
				...rest,
				// tag,
				// description,
				// gaawkLvl1,
				// gaawkLvl2,
				// gaawkLvl3,
				// tagAlbumMedia,
				// tagProducts,
				// worldWide,
				// locationIds,
			});

			originalLocationsRef.current = locationIds;
		} else if (service) {
			const {
				name,
				tag,
				description,
				gaawkService,
				tagMedia: tagAlbumMedia,
				tagProducts,
				locations,
				worldWide,
			} = service || {};

			originalLocationsRef.current = locations?.map((item) => locationFormatter(item)) || [];

			reset({
				name,
				tag,
				description,
				locationIds: locations?.map((item) => locationFormatter(item)),
				gaawkLvl1: {
					label: gaawkService.serviceL1.name,
					value: gaawkService.serviceL1.uuid,
				},
				...(gaawkService.serviceL2 && {
					gaawkLvl2: {
						label: gaawkService.serviceL2.name,
						value: gaawkService.serviceL2.uuid,
					},
				}),
				...(gaawkService.serviceL3 && {
					gaawkLvl3: {
						label: gaawkService.serviceL3.name,
						value: gaawkService.serviceL3.uuid,
					},
				}),
				tagAlbumMedia,
				tagProducts,
				worldWide,
			});
		}
	}, [service, step2]);

	const handleSave = (data) => {
		// console.log("THE DATA TO DISPATCH >>", data);

		const { name: title, ...rest } = data;

		dispatch(
			updateStep2({
				title,
				...rest,
			})
		);
		dispatch(setStep(3));

		//* not necessary because you're not supposed to go back to step 1 when editing a service (can be deleted after making sure all is working fine)
		// if (service) {
		// 	dispatch(
		// 		step1
		// 			? updateStep1({
		// 					level: step1.level,
		// 					parentId: step1.parentId,
		// 			  })
		// 			: updateStep1({
		// 					level: service.level,
		// 					parentId:
		// 						service.level === 3
		// 							? service.parents.p2.uuid
		// 							: service.level === 2
		// 							? service.parents.p3.uuid
		// 							: "",
		// 			  })
		// 	);
		// }
	};

	//! Location handlers =========

	const fetchLocation = useFetchLocation(true, itemsPerPage);

	const handleLocation = (location) => {
		if (errors?.locationIds) clearErrors("locationIds");
		setValue("locationIds", [...locationsWatcher, location]);
	};

	const handleRemoveLocation = (locationIndex) => {
		const updatedLocations = locationsWatcher.filter((_, index) => index !== locationIndex);

		setValue("locationIds", updatedLocations);
	};

	// !=========================

	const { handleKeyUp, hasNextPage, fetchNextPage, isFetching, data } = useGaawkServices();

	const gaawkServices = useMemo(
		() =>
			data?.pages
				.map((page) =>
					page.map((service) => ({
						label: service.serviceL1.name,
						value: service.serviceL1.uuid,
					}))
				)
				.flat(),
		[data]
	);

	const {
		handleKeyUp: handleKeyUpLvl2,
		hasNextPage: hasNextPageLvl2,
		fetchNextPage: fetchNextPageLvl2,
		isFetching: isFetchingLvl2,
		data: dataLvl2,
	} = useGaawkServices(gaawkLvl1Watcher?.value);

	const gaawkServicesLvl2 = useMemo(
		() =>
			dataLvl2?.pages
				.map((page) =>
					page.map((service) => ({
						label: service.serviceL2?.name,
						value: service.serviceL2?.uuid,
					}))
				)
				.flat(),
		[dataLvl2]
	);

	const {
		handleKeyUp: handleKeyUpLvl3,
		hasNextPage: hasNextPageLvl3,
		fetchNextPage: fetchNextPageLvl3,
		isFetching: isFetchingLvl3,
		data: dataLvl3,
	} = useGaawkServices(gaawkLvl1Watcher?.value, gaawkLvl2Watcher?.value);

	const gaawkServicesLvl3 = useMemo(
		() =>
			dataLvl3?.pages
				.map((page) =>
					page.map((service) => ({
						label: service.serviceL3?.name,
						value: service.serviceL3?.uuid,
					}))
				)
				.flat(),
		[dataLvl3]
	);

	// const didMountLvl2 = useRef(false);

	// useEffect(() => {
	// 	if (didMountLvl2.current) {
	// 		setValue("gaawkLvl2", null);
	// 	}
	// 	didMountLvl2.current = true;
	// }, [gaawkLvl1Watcher?.value]);

	// const didMountLvl3 = useRef(false);

	// useEffect(() => {
	// 	if (didMountLvl3.current) {
	// 		setValue("gaawkLvl3", null);
	// 	}
	// 	didMountLvl3.current = true;
	// }, [gaawkLvl2Watcher?.value]);

	return (
		<>
			{!suggModal && !locationModal ? (
				<form
					className={`${styles.form} ${styles.no_padding}`}
					onSubmit={handleSubmit((data) => handleSave(data))}
					noValidate
				>
					<div className={styles.section}>
						<div className={styles.progress_wrapper}>
							<ProgressIndicator step={2} />
						</div>

						<div className={styles.form_input_wrapper}>
							<label>
								Service Name<span className="required">*</span>
							</label>

							<div className={`${styles.input_wrapper} ${styles.left}`}>
								<span>+</span>
								<TextInput
									{...register("name")}
									error={!!errors.name}
									placeholder="Service Name"
									disabled={service}
								/>
							</div>

							{errors?.name?.message && (
								<p className={styles.error_message}>{errors?.name?.message}</p>
							)}
						</div>

						<span className={styles.info_text}>
							{service
								? "Service name can not be changed."
								: "Choose your service name carefully. Once saved, it can not be changed."}
						</span>

						<div className={styles.form_input_wrapper}>
							<label>Service Tag</label>

							<TextInput
								{...register("tag")}
								disabled={true}
								disabledText="Auto Generated Link"
							/>
						</div>

						{!service && (
							<span className={styles.info_text}>
								Service Tag is generated automatically
							</span>
						)}

						<Controller
							name={"description"}
							control={control}
							render={({ field }) => (
								<TextArea
									required={true}
									className={styles.textarea_wrapper}
									label="Description"
									error={errors.description}
									{...field}
								/>
							)}
						/>
					</div>

					<div className={styles.section}>
						<div className={styles.radio_button_wrapper}>
							<Controller
								name="worldWide"
								control={control}
								render={({ field: { onChange, value } }) => (
									<CustomCheckbox
										checked={value}
										onChange={(e) => {
											onChange(e.target.checked);
											// setMyLocationCheckbox(false); //*commented since removed user locations list for now
											clearErrors("locationIds");
										}}
										id="worldWide"
										customBackgroundColor={"gaawk"}
										marginRight="0px"
										isBorder={true}
									/>
								)}
							/>

							<label htmlFor="worldWide">Worldwide</label>
						</div>
						<div className={styles.info_text}>This service is offered worldwide</div>
						<MultiSelectInput
							queryName={locationKeys.cities}
							queryFn={fetchLocation}
							data={locationsWatcher}
							itemsPerPage={itemsPerPage}
							formatter={locationFormatter}
							label="Service Locations"
							required={!worldWideWatcher}
							limit={sizeLimit}
							onChange={handleLocation}
							onRemoveItem={handleRemoveLocation}
							infoText={`You can add up to ${sizeLimit} locations where you want to work.`}
							error={errors?.locationIds}
							customDisabled={worldWideWatcher}
						/>

						{/* //* below commented since removed user locations list for now */}
						{/* <div
							className={classNames(styles.radio_button_wrapper, {
								[styles.disabled]: worldWideWatcher,
							})}
						>
							<CustomCheckbox
								checked={
									myLocationCheckbox && !worldWideWatcher
								}
								onChange={(e) =>
									setMyLocationCheckbox(e.target.checked)
								}
								id="myLocationCheckbox"
								customBackgroundColor={"gaawk"}
								marginRight="0px"
								isBorder={true}
								disabled={worldWideWatcher}
							/>

							<label htmlFor="myLocationCheckbox">
								Use my location list
							</label>

							<button
								type="button"
								disabled={worldWideWatcher}
								onClick={() => dispatch(setLocationModal(true))}
							>
								Edit List
							</button>
						</div> */}
					</div>

					<div className={styles.section}>
						<div className={styles.section_title_wrapper}>
							<h3 className={styles.section_title}>
								Link your Service
								<span className="required">*</span>
							</h3>
							<button type={"button"} onClick={() => alert("show info modal")}>
								<img src={infoIcon} alt="" />
							</button>
						</div>
						<span className={styles.info_text}>
							You must link your Service to one of{" "}
							<span className={styles.gaawk}>gaawk</span> pre-defined services…
						</span>
						<div className={styles.form_input_wrapper}>
							<label>Level One Services</label>

							<div onKeyUp={handleKeyUp}>
								<CustomSelect
									placeholder="Select Service"
									options={gaawkServices}
									height="35px"
									error={!!errors.gaawkLvl1}
									control={control}
									name="gaawkLvl1"
									onChange={() => setValue("gaawkLvl2", null)}
									isLoading={isFetching}
									onMenuScrollToBottom={() => {
										if (hasNextPage && !isFetching) fetchNextPage();
									}}
								/>
							</div>
							{errors?.gaawkLvl1?.message && (
								<p className={styles.error_message}>{errors?.gaawkLvl1?.message}</p>
							)}
						</div>

						{gaawkLvl1Watcher && (
							<div className={styles.form_input_wrapper}>
								<label>Level Two Services</label>

								<div onKeyUp={handleKeyUpLvl2}>
									<CustomSelect
										placeholder="Select Service"
										options={gaawkServicesLvl2}
										height="35px"
										error={!!errors.gaawkLvl2}
										control={control}
										name="gaawkLvl2"
										isLoading={isFetchingLvl2}
										onChange={() => setValue("gaawkLvl3", null)}
										onMenuScrollToBottom={() => {
											if (hasNextPageLvl2 && !isFetchingLvl2)
												fetchNextPageLvl2();
										}}
										isClearable={true}
									/>
								</div>
								{errors?.gaawkLvl2?.message && (
									<p className={styles.error_message}>
										{errors?.gaawkLvl2?.message}
									</p>
								)}
							</div>
						)}

						{gaawkLvl2Watcher && (
							<div className={styles.form_input_wrapper}>
								<label>Level Three Services</label>

								<div onKeyUp={handleKeyUpLvl3}>
									<CustomSelect
										placeholder="Select Service"
										options={gaawkServicesLvl3}
										height="35px"
										error={!!errors.gaawkLvl3}
										control={control}
										name="gaawkLvl3"
										isLoading={isFetchingLvl3}
										onMenuScrollToBottom={() => {
											if (hasNextPageLvl3 && !isFetchingLvl3)
												fetchNextPageLvl3();
										}}
										isClearable={true}
									/>
								</div>
								{errors?.gaawkLvl3?.message && (
									<p className={styles.error_message}>
										{errors?.gaawkLvl3?.message}
									</p>
								)}
							</div>
						)}

						<button
							type="button"
							className={styles.suggestion_btn}
							onClick={() => {
								dispatch(setSuggModal(true));
							}}
						>
							Can’t find it? <span>Suggest a missing category</span>
						</button>
					</div>

					{/* //TODO >> tag media and tag product checkboxes */}
					<div className={styles.section}>
						<div className={styles.section_title_wrapper}>
							<h3 className={styles.section_title}>
								Display Options
								<span className="required">*</span>
							</h3>
							<button type={"button"} onClick={() => alert("show info modal")}>
								<img src={infoIcon} alt="" />
							</button>
						</div>
						<span className={styles.info_text}>
							Display tagged items with this service?
						</span>
						<div className={styles.row}>
							<div className={styles.tag_checkbox}>
								<div className={styles.radio_button_wrapper}>
									{/* <div className={styles.checkbox_wrapper}> */}
									<Controller
										control={control}
										name="tagAlbumMedia"
										render={({ field: { value, onChange } }) => (
											<CustomCheckbox
												checked={value}
												onChange={onChange}
												id="tagAlbumMedia"
												customBackgroundColor={"gaawk"}
												marginRight="0px"
												isBorder={true}
											/>
										)}
									/>

									<label htmlFor="tagAlbumMedia">
										<b>Media</b> (Images, videos, audios & PDF’s)
									</label>
								</div>
								<span className={styles.info_text}>
									Tagged items from your gallery.
								</span>
							</div>
							<div className={styles.tag_checkbox}>
								<div className={styles.radio_button_wrapper}>
									<Controller
										control={control}
										name={"tagProducts"}
										render={({ field: { value, onChange } }) => (
											<CustomCheckbox
												checked={value}
												id="tagProducts"
												onChange={onChange}
												customBackgroundColor={"gaawk"}
												marginRight="0px"
												isBorder={true}
											/>
										)}
									/>
									<label htmlFor="tagProducts">Products</label>
								</div>
								<span className={styles.info_text}>
									Tagged items from your products.
								</span>
							</div>
						</div>
					</div>

					<div className={`${styles.button_container} ${styles.padding}`}>
						<PrimaryButton className={styles.next_btn} text={"next"} />
					</div>
				</form>
			) : locationModal ? (
				<UserLocationsModal onSave={() => dispatch(setLocationModal(false))} />
			) : (
				<ServiceSuggestionModal />
			)}
		</>
	);
};

export default Stage2;
