import ContactLoader from "components/Utils/SubComs/CustomLoader/ContactLoader";
import SearchInput from "components/Utils/SubComs/Inputs/SearchInput/SearchInput";
import Tag from "components/Utils/SubComs/Tags/Tag";
import { useCustomQuery } from "hooks/useCustomQuery";
import useDebounce from "hooks/useDebounce";
import { locationKeys } from "queryKeys/location-key-factory";
import { useState } from "react";
import placesApi from "../../api/googlePlaces";
import useApi from "../../hooks/useApi";
import GaawkModal from "../Utils/GaawkModal/GaawkModal";
import styles from "./LocationModal.module.css";

const LocationModal = ({
	show,
	onClose,
	onLocationClicked,
	activeLocation,
	onRemove,
	closeAlign = "left",
}) => {
	const getPlacesApi = useApi(placesApi.getPlaces, true, true);

	const [searchQuery, setSearchQuery] = useState("");
	const debouncedQuery = useDebounce(searchQuery);

	const searchPlaces = async ({ queryKey }) => {
		const [_, query] = queryKey;
		const response = await getPlacesApi.request(query);
		return response.data;
	};

	const { data, isFetching } = useCustomQuery({
		queryKey: locationKeys.places(debouncedQuery),
		queryFn: searchPlaces,
		enabled: show,
	});

	const handleInputChange = (e) => {
		const query = e.target.value;
		setSearchQuery(query);
	};

	const handleClose = () => {
		setSearchQuery("");
		onClose();
	};

	const handleSelectedLocation = (place) => {
		setSearchQuery("");
		onLocationClicked(place);
	};

	const locationList = data?.map((place) => (
		<div
			className={styles.location_item}
			key={place.place_id}
			onClick={() => handleSelectedLocation(place)}
		>
			<img src={place.icon} alt={place.name} />
			<div>
				<label className={styles.name}>{place.name}</label>
				<label className={styles.location}>{place.formatted_address}</label>
			</div>
		</div>
	));

	return (
		<GaawkModal
			show={show}
			handleClose={handleClose}
			defaultModal={false}
			showHeader={true}
			title={"Search location"}
			closeAlign={closeAlign}
			children={
				<div className={styles.location_modal_container}>
					<SearchInput
						value={searchQuery}
						onChange={handleInputChange}
						border={false}
						showIcons={true}
						onClearClicked={() => setSearchQuery("")}
					/>

					{activeLocation && (
						<div className={styles.active_location}>
							<Tag
								itemName={activeLocation.title}
								onRemove={onRemove}
								customStyle={styles.tag}
							/>
						</div>
					)}

					<div className={styles.location_list_wrapper}>
						{isFetching ? <ContactLoader /> : locationList}
					</div>
				</div>
			}
		/>
	);
};

export default LocationModal;
