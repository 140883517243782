import HomeLeftSidebar from "components/Home/Components/HomeLeftSidebar/HomeLeftSidebar";
import { useNavigate } from "react-router-dom";
import UpdateWorkStatus from "../../components/Profile/WorkStatusTab/UpdateWorkStatus";
import BackButton from "../../components/Utils/Button/BackButton";
import DefaultPage from "../../components/Utils/PageArchs/DefaultPage/DefaultPage";
import styles from "../Header.module.css";

const UpdateWorkStatusView = () => {
	const navigate = useNavigate();

	return (
		<DefaultPage
			headerRightContent={
				<div className={styles.header}>
					<BackButton onBack={() => navigate(-1)} /> Update Work Status
				</div>
			}
			defaultHeader={false}
			leftSideChildren={<HomeLeftSidebar />}
			rightSideChildren={<UpdateWorkStatus />}
			rightSideBgColor={"#FFF"}
		/>
	);
};

export default UpdateWorkStatusView;
