import profileApi from "api/profile";
import routes from "components/Routing/routing-keys";
import CtaButton from "components/Utils/Button/CtaButton";
import useCurrentUser from "hooks/useCurrentUser";
import useMutate from "hooks/useMutate";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { otherUpdated as companyOtherUpdated } from "../../../../../store/slices/company";
import { otherUpdated } from "../../../../../store/slices/user";
import WarningModal from "../../../../Utils/GaawkModal/WarningModal";
import styles from "./Other.module.css";
import OtherItem from "./OtherItem";

const Other = () => {
	const navigate = useNavigate();
	const dispatch = useDispatch();

	const { others, uuid: companyId, type } = useCurrentUser();
	const editCompany = type === "COMPANY";

	const { handleRequestMutate, handleConfirmMutate, setWarningModal, warningModal, activeItem } =
		useMutate(
			editCompany ? profileApi.deleteCompanyOther : profileApi.deleteOther,
			(response, variables) => {
				const { companyId } = variables;
				dispatch(
					companyId ? companyOtherUpdated(response.data) : otherUpdated(response.data)
				);
			}
		);

	const otherList = others.map((other) => (
		<OtherItem
			key={other.uuid}
			item={other}
			onEditClicked={() =>
				navigate(routes.editOther, {
					state: other,
				})
			}
			onDelete={() => handleRequestMutate(other)}
		/>
	));

	return (
		<>
			<div className={styles.button_wrapper}>
				<CtaButton text="+ ADD NEW OTHER INFO" onClick={() => navigate(routes.addOther)} />
			</div>

			{others.length > 0 && (
				<div className={`${styles.container} ${styles.list}`}>
					<h4 className={styles.title}>Your other contact info</h4>
					<div className={styles.contact_section}>{otherList}</div>
				</div>
			)}

			<WarningModal
				show={warningModal}
				headerText="Are you sure you want to delete this information?"
				warningText="This action cannot be undone."
				cancelButtonText={"Cancel"}
				onCancelButtonClicked={() => setWarningModal(false)}
				submitButtonText={"DELETE"}
				onSubmitButtonClicked={() =>
					handleConfirmMutate({
						...(editCompany && { companyId }),
						otherUuid: activeItem.uuid,
					})
				}
			/>
		</>
	);
};

export default Other;
