import { useState } from "react";
import styles from "./ForwardModal.module.css";
import GaawkModal from "../Utils/GaawkModal/GaawkModal";
import MessageInfoUser from "./MessageInfoUser";
import PrimaryButton from "../Utils/Button/PrimaryButton";
import SearchInput from "../Utils/SubComs/Inputs/SearchInput/SearchInput";
import useApi from "../../hooks/useApi";
import chatApi from "../../api/chat";
import useInfiniteScroll from "hooks/useInfiniteScroll";
import { chatKeys } from "queryKeys/chat-key-factory";
import ContactLoader from "components/Utils/SubComs/CustomLoader/ContactLoader";
import { Message } from "primereact/message";
import { useCustomQuery } from "hooks/useCustomQuery";
import useDebounce from "hooks/useDebounce";
import NoResults from "components/Utils/SubComs/NoResults/NoResults";
import { classNames } from "primereact/utils";

const PAGE_SIZE = 20;

const ForwardModal = ({ isModalOpened, onClose, onSelect, selectedConvs, onForward }) => {
	const renderConvItem = ({ conv, ref, isLastItem, isFetchingNextPage }) => {
		return (
			<div key={conv.uuid} ref={ref}>
				<MessageInfoUser
					id={conv.uuid}
					thumbnail={
						conv.conversationType === "Group"
							? conv.groupDTO?.image?.file.customName
							: conv.minifiedProfile?.profileImage?.image
					}
					name={
						conv.conversationType === "Group"
							? conv.groupDTO.name
							: conv.minifiedProfile.name
					}
					type={
						conv.conversationType === "Default" ? conv.minifiedProfile.type : "COMPANY"
					}
					canSelect={true}
					onSelect={onSelect}
					isSelected={selectedConvs.includes(conv.uuid)}
				/>
				{isLastItem && isFetchingNextPage && <ContactLoader />}
			</div>
		);
	};

	//! ================ ALL CONVERSATIONS ================
	const getConversationsApi = useApi(chatApi.getConversations, true, true);

	const fetchAllConversations = async ({ pageParam = 0 }) => {
		const response = await getConversationsApi.request(pageParam, PAGE_SIZE);
		return response.data;
	};

	const {
		items: allConvs,
		viewRef,
		isFetchingNextPage,
		isLoading,
		errorComponent: convsErrorComponents,
	} = useInfiniteScroll({
		queryKey: chatKeys.conversations(),
		queryFn: fetchAllConversations,
		pageSize: PAGE_SIZE,
		queryOptions: {
			errorComponent: ({ error }) => {
				return (
					<Message
						severity="error"
						text="Unable to load conversations"
						style={{ width: "100%", marginBottom: "1rem" }}
					/>
				);
			},
		},
	});

	const conversationsList = allConvs.map((conv, index) => {
		const isLastItem = index === allConvs.length - 1;

		return renderConvItem({
			conv,
			ref: isLastItem ? viewRef : null,
			isLastItem,
			isFetchingNextPage,
		});
	});

	//! ================ FREQUENT CONVERSATIONS ================

	const getFreqConversationsApi = useApi(chatApi.getFreqConversations, true, true);

	const fetchFreqConvs = async ({ signal }) => {
		const response = await getFreqConversationsApi.request();
		return response.data;
	};

	const {
		data: freqConvs,
		isLoading: isLoadingFreqConv,
		errorComponent: freqConvsErrorComponent,
	} = useCustomQuery({
		queryKey: chatKeys.freqConversations(),
		queryFn: fetchFreqConvs,
		options: {
			errorComponent: ({ error }) => {
				return (
					<Message
						severity="error"
						text="Unable to load conversations"
						style={{ width: "100%", marginBottom: "1rem" }}
					/>
				);
			},
		},
	});

	const frequentConvsList = freqConvs?.map((conv) => {
		return renderConvItem({
			conv,
		});
	});

	//! ================ SEARCH CONVERSATIONS ==================

	const [searchQuery, setSearchQuery] = useState("");
	const debouncedQuery = useDebounce(searchQuery.trim());

	const searchConversationsApi = useApi(chatApi.searchConversations, true, true);

	const searchConvs = async ({ pageParam = 0, queryKey }) => {
		const [_, __, ___, query] = queryKey;
		const response = await searchConversationsApi.request(query, pageParam, PAGE_SIZE);
		return response.data;
	};

	const {
		items: searchedConvs,
		viewRef: searchViewRef,
		isFetchingNextPage: isFetchingNextPageSearched,
		isFetching: isFetchingSearch,
		errorComponent: searchErrorComponents,
		fetchStatus,
	} = useInfiniteScroll({
		queryKey: chatKeys.searchConv(debouncedQuery),
		queryFn: searchConvs,
		pageSize: PAGE_SIZE,
		enabled: !!debouncedQuery,
		queryOptions: {
			errorComponent: ({ error }) => {
				return (
					<Message
						severity="error"
						text="Unable to load conversations"
						style={{ width: "100%", marginBottom: "1rem" }}
					/>
				);
			},
		},
	});

	const searchedConvsList = searchedConvs.map((conv, index) => {
		const isLastItem = index === searchedConvs.length - 1;

		return renderConvItem({
			conv,
			ref: isLastItem ? searchViewRef : null,
			isLastItem,
			isFetchingNextPageSearched,
		});
	});

	const handleClearInput = () => {
		setSearchQuery("");
	};

	const handleInputChange = (e) => {
		const query = e.target.value;
		setSearchQuery(query);
	};

	//! ========================================================

	const LoadingView = () => (
		<>
			<ContactLoader />
			<ContactLoader />
			<ContactLoader />
		</>
	);

	return (
		<div>
			<GaawkModal
				show={isModalOpened}
				handleClose={onClose}
				defaultModal={false}
				showHeader={true}
				title={"Forward Message"}
				closeAlign={"right"}
				children={
					<div className={styles.modal_container}>
						<SearchInput
							border={false}
							showIcons={true}
							onChange={handleInputChange}
							value={searchQuery}
							onClearClicked={handleClearInput}
							// check component for more actions
						/>

						{searchQuery ? (
							<div className={styles.section_wrapper}>
								<span className={styles.title}>Search Conversations</span>
								<div className={styles.contacts_wrapper}>
									{debouncedQuery && (
										<>
											{searchErrorComponents || searchedConvsList}
											<NoResults
												text={"No results"}
												isSearch={true}
												visible={
													searchedConvs.length === 0 && !isFetchingSearch
												}
											/>
											{fetchStatus !== "idle" && isFetchingSearch && (
												<LoadingView />
											)}
										</>
									)}
								</div>
							</div>
						) : (
							<>
								<div className={styles.section_wrapper}>
									<span className={styles.title}>Frequently Contacted</span>
									<div
										className={classNames(styles.contacts_wrapper, {
											[styles.hasError]: freqConvsErrorComponent,
										})}
									>
										{isLoadingFreqConv && <LoadingView />}
										{freqConvsErrorComponent || frequentConvsList}
									</div>
								</div>

								<div className={styles.section_wrapper}>
									<span className={styles.title}>All Conversations</span>
									<div
										className={classNames(styles.contacts_wrapper, {
											[styles.hasError]: convsErrorComponents,
										})}
									>
										{isLoading && <LoadingView />}
										{convsErrorComponents || conversationsList}
									</div>
								</div>
							</>
						)}

						<div className={styles.btn_wrapper}>
							<PrimaryButton
								className={styles.forward_btn}
								text={"forward"}
								onClick={onForward}
							/>
						</div>
					</div>
				}
			/>
		</div>
	);
};

export default ForwardModal;
