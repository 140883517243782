import profileApi from "api/profile";
import routes from "components/Routing/routing-keys";
import CtaButton from "components/Utils/Button/CtaButton";
import useCurrentUser from "hooks/useCurrentUser";
import useMutate from "hooks/useMutate";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { siteUpdated as companySiteUpdated } from "../../../../../store/slices/company";
import { siteUpdated } from "../../../../../store/slices/user";
import WarningModal from "../../../../Utils/GaawkModal/WarningModal";
import styles from "./Email.module.css";
import EmailItem from "./EmailItem";

const Email = () => {
	const navigate = useNavigate();
	const dispatch = useDispatch();

	const { sites, uuid: companyId, type } = useCurrentUser();
	const editCompany = type === "COMPANY";

	const { handleRequestMutate, handleConfirmMutate, setWarningModal, warningModal, activeItem } =
		useMutate(
			editCompany ? profileApi.deleteCompanySite : profileApi.deleteSite,
			(response, variables) => {
				const { companyId } = variables;
				dispatch(
					companyId ? companySiteUpdated(response.data) : siteUpdated(response.data)
				);
			}
		);

	const sitesList = sites.map((site) => (
		<EmailItem
			key={site.uuid}
			item={site}
			onEditClicked={() =>
				navigate(routes.editEmail, {
					state: site,
				})
			}
			onDelete={() => handleRequestMutate(site)}
		/>
	));

	return (
		<>
			<div className={styles.button_wrapper}>
				<CtaButton
					text="+ ADD A NEW WEB ADDRESS"
					onClick={() => navigate(routes.addEmail)}
				/>
			</div>

			{sites.length > 0 && (
				<div className={styles.container}>
					<h4 className={styles.title}>Your Email / Website</h4>
					<div className={styles.contact_section}>{sitesList}</div>
				</div>
			)}

			<WarningModal
				show={warningModal}
				headerText="Are you sure you want to delete this information?"
				warningText="This action cannot be undone."
				cancelButtonText={"Cancel"}
				onCancelButtonClicked={() => setWarningModal(false)}
				submitButtonText={"DELETE"}
				onSubmitButtonClicked={() =>
					handleConfirmMutate({
						...(editCompany && { companyId }),
						siteId: activeItem.uuid,
					})
				}
			/>
		</>
	);
};

export default Email;
