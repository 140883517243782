import profileApi from "api/profile";
import routes from "components/Routing/routing-keys";
import CtaButton from "components/Utils/Button/CtaButton";
import useCurrentUser from "hooks/useCurrentUser";
import useMutate from "hooks/useMutate";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { softwareUpdated } from "../../../../store/slices/user";
import WarningModal from "../../../Utils/GaawkModal/WarningModal";
import styles from "./SoftwareEntry.module.css";
import SoftwareItem from "./SoftwareItem";

const Index = () => {
	const navigate = useNavigate();
	const dispatch = useDispatch();

	const { softwareList } = useCurrentUser();

	const { handleRequestMutate, handleConfirmMutate, setWarningModal, warningModal, activeItem } =
		useMutate(profileApi.deleteSoftware, (response) => {
			dispatch(softwareUpdated(response.data));
		});

	//! usememo ?

	const userSoftwareList = softwareList.map((software) => (
		<SoftwareItem
			key={software.uuid}
			item={software}
			onEditClicked={() =>
				navigate(routes.editSoftware, {
					state: software,
				})
			}
			onDelete={() => handleRequestMutate(software)}
		/>
	));

	return (
		<>
			<div className={styles.button_wrapper}>
				<CtaButton
					text="+ ADD A NEW SOFTWARE"
					onClick={() => navigate(routes.addSoftware)}
				/>
			</div>

			{softwareList.length > 0 && (
				<div className={styles.container}>
					<h4 className={styles.title}>Software you have added</h4>

					<div className={styles.software_section}>{userSoftwareList}</div>
				</div>
			)}

			<WarningModal
				show={warningModal}
				headerText="Are you sure you want to delete this information?"
				warningText="This action cannot be undone."
				cancelButtonText={"Cancel"}
				onCancelButtonClicked={() => setWarningModal(false)}
				submitButtonText={"DELETE"}
				onSubmitButtonClicked={() => handleConfirmMutate(activeItem.uuid)}
			/>
		</>
	);
};

export default Index;
