import profileApi from "api/profile";
import routes from "components/Routing/routing-keys";
import CtaButton from "components/Utils/Button/CtaButton";
import useCurrentUser from "hooks/useCurrentUser";
import useMutate from "hooks/useMutate";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { phoneUpdated as companyPhoneUpdated } from "../../../../../store/slices/company";
import { phoneUpdated } from "../../../../../store/slices/user";
import WarningModal from "../../../../Utils/GaawkModal/WarningModal";
import styles from "./Phone.module.css";
import PhoneItem from "./PhoneItem";

const Phone = () => {
	const navigate = useNavigate();
	const dispatch = useDispatch();

	const { contacts: contactList, uuid: companyId, type } = useCurrentUser();
	const editCompany = type === "COMPANY";

	const { handleRequestMutate, handleConfirmMutate, setWarningModal, warningModal, activeItem } =
		useMutate(
			editCompany ? profileApi.deleteCompanyContact : profileApi.deleteContact,
			(response, variables) => {
				const { companyId } = variables;
				dispatch(
					companyId ? companyPhoneUpdated(response.data) : phoneUpdated(response.data)
				);
			}
		);

	const contacts = contactList.map((contact) => (
		<PhoneItem
			key={contact.uuid}
			item={contact}
			onEditClicked={() =>
				navigate(routes.editPhone, {
					state: contact,
				})
			}
			onDelete={() => handleRequestMutate(contact)}
		/>
	));

	return (
		<>
			<div className={styles.button_wrapper}>
				<CtaButton
					text="+ ADD A NEW PHONE NUMBER"
					onClick={() => navigate(routes.addPhone)}
				/>
			</div>

			{contactList.length > 0 && (
				<div className={styles.container}>
					<h4 className={styles.title}>Your Mobile / Landline</h4>
					<div className={styles.contact_section}>{contacts}</div>
				</div>
			)}

			<WarningModal
				show={warningModal}
				headerText="Are you sure you want to delete this information?"
				warningText="This action cannot be undone."
				cancelButtonText={"Cancel"}
				onCancelButtonClicked={() => setWarningModal(false)}
				submitButtonText={"DELETE"}
				onSubmitButtonClicked={() =>
					handleConfirmMutate({
						...(editCompany && { companyId }),
						contactId: activeItem.uuid,
					})
				}
			/>
		</>
	);
};

export default Phone;
