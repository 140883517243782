import InfiniteSearchInput from "components/Utils/SubComs/Inputs/InfiniteSearchInput/InfiniteSearchInput";
import useSearchUsers from "hooks/useSearchUsers";
import closeIcon from "images/cancel-btn-gray.svg";
import { profileKeys } from "queryKeys/profile-key-factory";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import { components } from "react-select";
import ProfilePic from "../Home/ProfilePic";
import {
	peopleFormatter,
	unduplicateIds,
} from "../Utils/SubComs/Inputs/SearchableInput/response-formatter";
import styles from "./AdminRepEdit.module.css";

const PAGE_SIZE = 10;

const AdminRepEdit = ({ mode, people, onSelect, company, customStyle, onRemove }) => {
	const {
		owner: { uuid: companyOwnerId },
		companyUserType,
	} = company;

	const canEdit =
		companyUserType === "OWNER" || (companyUserType === "ADMIN" && mode === "representative");

	const { uuid: userId } = useSelector((state) => state.user.profileInfo);

	const peopleIds = useMemo(() => people.map((person) => person.uuid), [people]);

	const excludedPeople = [...peopleIds, companyOwnerId, userId];

	const peopleList = useMemo(
		() =>
			people.map((person) => (
				<div className={styles.member_item} key={person.uuid}>
					<ProfilePic
						thumbnail={person.profileImage ? person.profileImage.image : ""}
						className={styles.shrink}
						w={36}
						h={36}
						type={person.type}
						name={person.name}
						createMode={true}
						url={person.url}
						subdata={person.tagLine}
					/>
					{person.uuid !== userId && canEdit && (
						<img
							className={styles.close_icon}
							src={closeIcon}
							alt="close"
							onClick={() => onRemove(person.uuid)}
						/>
					)}
				</div>
			)),
		[people]
	);

	const fetchUsers = useSearchUsers(PAGE_SIZE);

	return (
		<div className={`${styles.container} ${customStyle}`}>
			<div className={styles.wrapper}>
				<h4>{`Company ${mode}s`}</h4>
				<p className={styles.other_info}>
					{`You can assign one or multiple ${mode}s to this company. You can also dismiss ${mode}s from the list below
                    by clicking the “x” sign next to their names.`}
				</p>

				{people.length > 0 && (
					<>
						<label className={styles.sub_title}>{`Current ${
							mode === "admin" ? "Admins" : "Representatives"
						}`}</label>
						<div>{peopleList}</div>
					</>
				)}

				{canEdit && (
					<InfiniteSearchInput
						label={`Assign new ${mode}`}
						queryName={profileKeys.search}
						onChange={onSelect}
						unDuplicateFn={(passedData) =>
							unduplicateIds(excludedPeople, passedData, "value")
						}
						queryFn={fetchUsers}
						itemsPerPage={PAGE_SIZE}
						formatter={peopleFormatter}
						option={{
							Option: (props) => {
								const user = props.data;
								return (
									<components.Option {...props}>
										<div className={styles.member_item}>
											<ProfilePic
												className={styles.shrink}
												thumbnail={user?.profileImage?.image}
												w={36}
												h={36}
												type={user.type}
												name={user.label}
												createMode={true}
												subdata={user.tagLine}
											/>
										</div>
									</components.Option>
								);
							},
						}}
					/>
				)}
			</div>
		</div>
	);
};

export default AdminRepEdit;
