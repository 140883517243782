import postApi from "api/post";
import useApi from "./useApi";
import { useCustomQuery } from "./useCustomQuery";
import { postsKeys } from "queryKeys/posts-key-factory";

const useGetPostById = (postId, enabled = true, initialData) => {
	const getPostApi = useApi(postApi.getPostById, true, true);

	const fetchPost = async ({ queryKey }) => {
		const [_, __, postId] = queryKey;
		const response = await getPostApi.request(postId);
		return response.data;
	};

	const query = useCustomQuery({
		queryKey: postsKeys.detail(postId),
		queryFn: fetchPost,
		enabled: enabled && !!postId,
		options: {
			initialData,
			select: (fetched) => {
				const { mediaList = [], pdfList = [] } = fetched;

				const subPostsContent = [...mediaList, ...pdfList].filter(Boolean);

				return {
					...fetched,
					subPosts: subPostsContent,
				};
			},
		},
	});

	return query;
};

export default useGetPostById;
