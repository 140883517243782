import profileApi from "api/profile";
import routes from "components/Routing/routing-keys";
import CtaButton from "components/Utils/Button/CtaButton";
import useCurrentUser from "hooks/useCurrentUser";
import useMutate from "hooks/useMutate";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { languageUpdated } from "../../../../store/slices/user";
import WarningModal from "../../../Utils/GaawkModal/WarningModal";
import styles from "./Language.module.css";
import LanguageItem from "./LanguageItem";

const Index = () => {
	const navigate = useNavigate();
	const dispatch = useDispatch();

	const { languages: userLanguageList } = useCurrentUser();

	const { handleRequestMutate, handleConfirmMutate, setWarningModal, warningModal, activeItem } =
		useMutate(profileApi.deleteLanguage, (response) => {
			dispatch(languageUpdated(response.data));
		});

	const userLanguage = userLanguageList.map((item) => (
		<LanguageItem
			key={item.uuid}
			item={item}
			onEditClicked={() =>
				navigate(routes.editLanguage, {
					state: item,
				})
			}
			onDelete={() => handleRequestMutate(item)}
		/>
	));

	return (
		<>
			<div className={styles.button_wrapper}>
				<CtaButton
					text="+ ADD A NEW LANGUAGE"
					onClick={() => navigate(routes.addLanguage)}
				/>
			</div>

			{userLanguageList.length > 0 && (
				<div className={styles.container}>
					<h4 className={styles.title}>Languages you have added</h4>

					<div className={styles.languages_section}>{userLanguage}</div>
				</div>
			)}

			<WarningModal
				show={warningModal}
				headerText="Are you sure you want to delete this information?"
				warningText="This action cannot be undone."
				cancelButtonText={"Cancel"}
				onCancelButtonClicked={() => setWarningModal(false)}
				submitButtonText={"DELETE"}
				onSubmitButtonClicked={() => handleConfirmMutate(activeItem.uuid)}
			/>
		</>
	);
};

export default Index;
