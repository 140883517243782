import { joiResolver } from "@hookform/resolvers/joi";
import routes from "components/Routing/routing-keys";
import PromptModal from "components/Utils/Prompt/PromptModal";
import SimpleDropZone from "components/Utils/SubComs/CustomDropZone/SimpleDropZone";
import ThumbnailContainer from "components/Utils/SubComs/ThumbnailContainer/ThumbnailContainer";
import useCurrentUser from "hooks/useCurrentUser";
import useMutate from "hooks/useMutate";
import { institutesKeys } from "queryKeys/institutes-key-factory";
import { locationKeys } from "queryKeys/location-key-factory";
import { useEffect, useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import Switch from "react-switch";
import educationTypeApi from "../../../../api/education-type";
import institutionApi from "../../../../api/institution";
import profileApi from "../../../../api/profile";
import useApi from "../../../../hooks/useApi";
import useFetchLocation from "../../../../hooks/useFetchLocation";
import { educationUpdated } from "../../../../store/slices/user";
import PrimaryButton from "../../../Utils/Button/PrimaryButton";
import CustomSelect from "../../../Utils/SubComs/CustomSelect/CustomSelect";
import DayPicker from "../../../Utils/SubComs/DayPicker/DayPicker";
import InfiniteSearchInput from "../../../Utils/SubComs/Inputs/InfiniteSearchInput/InfiniteSearchInput";
import {
	defaultFormatter,
	locationFormatter,
} from "../../../Utils/SubComs/Inputs/SearchableInput/response-formatter";
import TextInput from "../../../Utils/SubComs/Inputs/TextInput/TextInput";
import educationSchema from "./education-schema";
import styles from "./EducationEntry.module.css";
import { useMutation } from "@tanstack/react-query";
import { useCustomQuery } from "hooks/useCustomQuery";
import { educationKeys } from "queryKeys/educations-key-factory";
import InputWrapper from "components/Utils/SubComs/Inputs/InputWrapper/InputWrapper";

const itemsPerPage = 20;

const uploadSingleFile = async ({ educationId, multipartFile }) => {
	const formData = new FormData();
	formData.append("educationId", educationId);
	formData.append("multipartFile", multipartFile);

	const response = await profileApi.addEducationFiles(formData);

	if (!response.ok) {
		throw response.data;
	}

	return response.data;
};

const EducationEntry = () => {
	const dispatch = useDispatch();

	const { pathname, state } = useLocation();

	const editMode = pathname === routes.editEducation;

	const {
		uuid: educationId,
		locationDTO: {
			name: cityName,
			countryDTO: { name: countryName } = {},
			id: locationId,
		} = {},
		educationType,
		files: currentFiles,
		comments,
		instituteName,
		instituteDTO,
		score,
		scoreOutOf,
		title,
		currentlyWorking: currentStatus,
		startDate = null,
		completionDate = null,
		validity = null,
	} = state || {};

	const { educations: userEducations } = useCurrentUser();

	const prevUserEducations = useRef(userEducations).current;

	const [shouldBlockSave, setShouldBlockSave] = useState(true);

	const {
		register,
		formState: { errors, isDirty },
		handleSubmit,
		control,
		watch,
		setValue,
	} = useForm({
		resolver: joiResolver(educationSchema),
		mode: "onSubmit",
		defaultValues: editMode
			? {
					title,
					certificateType: {
						label: educationType?.name,
						value: educationType?.uuid,
					},
					institution: instituteDTO
						? { label: instituteDTO.name, value: instituteDTO.uuid }
						: {
								label: instituteName,
								value: instituteName,
								__isNew__: true,
						  },
					city: {
						value: locationId,
						label: `${cityName}, ${countryName}`,
					},
					startDate: startDate !== 0 ? new Date(startDate) : null,
					endDate:
						!currentStatus && completionDate !== 0 ? new Date(completionDate) : null,
					score: score ?? "",
					outOf: scoreOutOf ?? "",
					validity: validity !== 0 ? new Date(validity) : null,
					comments: comments ?? "",
					files: currentFiles,
					switch: currentStatus,
			  }
			: {
					title: "",
					certificateType: null,
					institution: null,
					city: null,
					startDate: null,
					endDate: null,
					comments: "",
					files: [],
					score: "",
					validity: null,
					outOf: "",
					switch: false,
			  },
	});

	const files = watch("files");
	const switchWatcher = watch("switch");

	// ! STATUS HANDLER -----------------------------------------------------

	const [currentlyWorking, setCurrentlyWorking] = useState(false);
	const handleWorkStatus = () => {
		setCurrentlyWorking((prevState) => !prevState);
		if (!currentlyWorking) setValue("endDate", null);
	};

	//! EDUCATION HANDLERS ----------------------------------------------

	//TODO >> This endpoint is not paginated, so can't use <InfiniteSearchInput /> component like the other endpoints

	const educationTypeSearchApi = useApi(educationTypeApi.search, true, true);

	const searchEducationType = async ({ queryKey }) => {
		const [_, query] = queryKey;
		const response = await educationTypeSearchApi.request(query);
		return response.data;
	};

	const { data: educations, isFetching: isFetchingSearch } = useCustomQuery({
		queryKey: educationKeys.search(),
		queryFn: searchEducationType,
		options: {
			staleTime: 30000,
		},
	});

	//! SAVE HANDLER ----------------------------------------------
	const [isLoading, setIsLoading] = useState(false);

	const {
		action: { mutate: addEducation },
	} = useMutate(
		profileApi.addEducation,
		async (response) => {
			dispatch(educationUpdated(response.data));

			const newEducation = response.data.educations.filter(
				(i) => prevUserEducations.map((item) => item.uuid).indexOf(i.uuid) === -1
			);

			if (files.length > 0) {
				try {
					for (const file of files) {
						await uploadMutation.mutateAsync({
							educationId: newEducation[0].uuid,
							multipartFile: file,
						});
					}

					setShouldBlockSave(false);
				} catch (error) {
					console.error("Upload failed:", error);
				}
			} else {
				setShouldBlockSave(false);
			}

			setIsLoading(false);
		},
		() => setIsLoading(false)
	);

	const {
		action: { mutate: updateEducation },
	} = useMutate(
		profileApi.updateEducation,
		async (response) => {
			dispatch(educationUpdated(response.data));

			const fileArray = files.filter((file) => file instanceof File);

			if (fileArray.length > 0) {
				try {
					for (const file of fileArray) {
						await uploadMutation.mutateAsync({
							educationId: educationId,
							multipartFile: file,
						});
					}

					setShouldBlockSave(false);
				} catch (error) {
					console.error("Upload failed:", error);
				}
			} else {
				setShouldBlockSave(false);
			}
			setIsLoading(false);
		},
		() => setIsLoading(false)
	);

	const handleSave = (data) => {
		setIsLoading(true);

		const {
			city: { value: locationId },
			certificateType: { value: educationTypeId },
			comments,
			score,
			outOf: outOfScore,
			startDate,
			endDate,
			title,
			validity,
			institution,
			switch: switchStatus,
		} = data;

		const body = {
			...(editMode && { educationId }),
			locationId,
			...(comments && { comments }),
			educationTypeId,
			...(score && { score }),
			...(outOfScore && { outOfScore }),
			currentlyWorking: switchStatus,
			...(startDate && { startDate: startDate.getTime() }),
			...(!switchStatus && endDate && { completionDate: endDate.getTime() }),
			title,
			...(validity !== null && { validUntil: validity.getTime() }),
			...(institution.__isNew__
				? { instituteName: institution.value }
				: { instituteId: institution.value }),
		};

		if (editMode) {
			updateEducation({ body });
		} else {
			addEducation({ body });
		}
	};

	const navigate = useNavigate();

	useEffect(() => {
		if (!shouldBlockSave) navigate(routes.education, { replace: true });
	}, [navigate, shouldBlockSave]);

	//* TO UPLOAD FILES (IF ANY AFTER CLICKING "SAVE") ==============

	const uploadMutation = useMutation({
		mutationFn: uploadSingleFile,
		onSuccess: async (data) => {
			dispatch(educationUpdated(data));
		},
	});

	//* =============================================================

	const handleDroppedFiles = (acceptedFiles) => {
		const updatedFiles = [...files];

		acceptedFiles.forEach((file) => {
			updatedFiles.push(file);
		});

		setValue("files", updatedFiles);

		// if (acceptedFiles.length > 0) {
		//     const formattedFiles = [];
		//     for (let i = 0; i < acceptedFiles.length; i++) {
		//         formattedFiles.push({
		//             caption: "",
		//             multipartFile: acceptedFiles[i],
		//             type: acceptedFiles[i].type.includes("video")
		//                 ? "VIDEO"
		//                 : acceptedFiles[i].type.includes("image")
		//                 ? "IMAGE"
		//                 : "PDF",
		//             taggedIds: [],
		//             newFile: true,
		//         });
		//     }
		//     setDroppedFiles([...droppedFiles, ...formattedFiles]);
		// }
	};

	const {
		action: { mutate: deleteFile },
	} = useMutate(profileApi.deleteEducationFile, (response) => {
		const updatedEducations = response.data;

		dispatch(educationUpdated(updatedEducations));

		const updatedFiles = [...files];
		const filteredList = updatedFiles.filter((file) => file.uuid !== fileIdToDelete);
		setValue("files", filteredList);
		setFileIdToDelete("");
	});

	const [fileIdToDelete, setFileIdToDelete] = useState("");

	const handleFileToRemove = (file, index) => {
		if (file instanceof File && files.includes(file)) {
			const updatedFiles = [...files];
			updatedFiles.splice(index, 1);
			setValue("files", updatedFiles, {
				shouldDirty: updatedFiles.length === 1 && !editMode ? false : true,
			});
		} else {
			setFileIdToDelete(file.uuid);
			deleteFile({ educationId, fileId: file.uuid });
		}
	};

	//* ========================= fetch institutes ====================================

	const searchInstituteApi = useApi(institutionApi.searchEducationInstitute, true, true);

	const fetchInstitutions = async ({ pageParam = 0, signal, queryKey }) => {
		const [_, searchInput] = queryKey;

		const response = await searchInstituteApi.request(pageParam, itemsPerPage, searchInput);
		return response.data;
	};

	//* ========================= fetch locations ====================================

	const fetchLocation = useFetchLocation(true, itemsPerPage);

	if (editMode && !state) {
		return <Navigate to={routes.education} />;
	}

	return (
		<>
			<div className={styles.container}>
				<form
					className={styles.form}
					onSubmit={handleSubmit((data) => handleSave(data))}
					noValidate
				>
					<InputWrapper
						label="Title"
						required={true}
						className={styles.form_input_wrapper}
						error={errors?.title}
						component={
							<TextInput
								{...register("title")}
								placeholder="Enter your certificate's title"
							/>
						}
					/>

					<InputWrapper
						label="Certificate Type"
						required={true}
						className={styles.m_15}
						error={errors?.certificateType}
						component={
							<CustomSelect
								options={educations?.map(defaultFormatter)}
								height="35px"
								placeholder="Search for a certificate type"
								control={control}
								isLoading={isFetchingSearch}
								name="certificateType"
							/>
						}
					/>

					<InfiniteSearchInput
						label={"Training Institution"}
						required={true}
						queryName={institutesKeys.search}
						queryFn={fetchInstitutions}
						itemsPerPage={itemsPerPage}
						formatter={defaultFormatter}
						error={errors?.institution}
						control={control}
						name="institution"
						creatable={true}
					/>

					<InfiniteSearchInput
						queryName={locationKeys.cities}
						queryFn={fetchLocation}
						itemsPerPage={itemsPerPage}
						formatter={locationFormatter}
						label={"Location"}
						placeholder={"Select a city"}
						required={true}
						error={errors?.city}
						control={control}
						name="city"
						openMenuOnClick={true}
					/>

					<div className={styles.react_switch}>
						<Controller
							name="switch"
							control={control}
							render={({ field: { onChange, value } }) => (
								<Switch
									checked={value}
									onColor="#6cc5d1"
									offColor="#a6b1bc"
									handleDiameter={18}
									width={39}
									height={24}
									checkedIcon={false}
									uncheckedIcon={false}
									activeBoxShadow="0px 0px 1px 8px rgb(108, 197, 209, 0.3)"
									onChange={(val) => {
										handleWorkStatus();
										onChange(val);
									}}
								/>
							)}
						/>

						<span>Currently pursuing</span>
					</div>

					<div className={styles.datePickerWrapper}>
						<div className={styles.datePickerItem}>
							<label>Start Date</label>

							<DayPicker
								className={`form-start-date ${
									!!errors.startDate ? "error" : undefined
								}`}
								control={control}
								name="startDate"
								isClearable={true}
							/>
							{errors?.startDate?.message && (
								<p className={styles.error_message}>{errors?.startDate?.message}</p>
							)}
						</div>
						<div className={styles.datePickerItem}>
							<label>End Date</label>
							<DayPicker
								disabled={switchWatcher}
								className={`form-end-date ${
									!!errors.endDate && !switchWatcher ? "error" : undefined
								}`}
								control={control}
								name="endDate"
								isClearable={true}
							/>
							{errors?.endDate?.message && !switchWatcher && (
								<p className={styles.error_message}>{errors?.endDate?.message}</p>
							)}
						</div>
					</div>

					<div className={styles.add_files_section}>
						<label>Files</label>
						<label className={styles.subLabel}>
							You can upload images and PDF files only.
						</label>

						{files.length > 0 && (
							<ThumbnailContainer items={files} onDelete={handleFileToRemove} />
						)}

						<div className={styles.file_uploader_wrapper}>
							<SimpleDropZone
								name="files"
								onDrop={handleDroppedFiles}
								acceptedFiles={[
									"image/png, image/jpg, image/jpeg, application/pdf",
								]}
							/>
						</div>
					</div>

					<div className={`${styles.form_input_wrapper} ${styles.grade_section}`}>
						<div className={styles.score_item}>
							<label>Score / Grade</label>
							<TextInput {...register("score")} placeholder="Your score" />
						</div>
						<div className={styles.score_item}>
							<label>Out of</label>
							<TextInput {...register("outOf")} placeholder="Max score" />
						</div>
					</div>

					<div className={styles.datePickerWrapper}>
						<div className={styles.datePickerItem}>
							<label>Valid until</label>
							<DayPicker
								className={`form-start-date ${
									!!errors.validity ? "error" : undefined
								}`}
								control={control}
								name="validity"
							/>
							{errors?.validity?.message && (
								<p className={styles.error_message}>{errors?.validity?.message}</p>
							)}
						</div>
					</div>

					<div className={styles.comments_section}>
						<label>Comments</label>

						<textarea {...register("comments")} placeholder="Enter your headline" />
					</div>
					<div className={styles.button_container}>
						<PrimaryButton
							className={styles.save_btn}
							text={"save"}
							isLoading={isLoading}
						/>
					</div>
				</form>
			</div>

			<PromptModal when={isDirty && shouldBlockSave} />
		</>
	);
};

export default EducationEntry;
