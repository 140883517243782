import { unduplicateValue } from "components/Utils/SubComs/Inputs/SearchableInput/response-formatter";
import Tag from "components/Utils/SubComs/Tags/Tag";
import { useGetLocationsList } from "hooks/useGetLocationsList";
import { useGetUserLocationsList } from "hooks/useGetUserLocationList";
import { useEffect, useMemo, useState } from "react";
import styles from "./UserLocationsModal.module.scss";
import useMutate from "hooks/useMutate";
import profileApi from "api/profile";
import { toast } from "react-toastify";
import { useQueryClient } from "@tanstack/react-query";
import { profileKeys } from "queryKeys/profile-key-factory";
import SearchInput from "components/Utils/SubComs/Inputs/SearchInput/SearchInput";
import TagContainer from "components/Utils/SubComs/Tags/TagContainer";
import LoadingSpinner from "components/Utils/SubComs/LoadingSpinner/LoadingSpinner";
import NoResults from "components/Utils/SubComs/NoResults/NoResults";
import PrimaryButton from "components/Utils/Button/PrimaryButton";

//* IMPROVEMENT >> add prompt when trying to leaving without saving newly added locations

const UserLocationsModal = ({ onSave }) => {
	const queryClient = useQueryClient();

	const {
		profileLocationLists,
		// isLoadingProfileLocationsList
	} = useGetUserLocationsList();

	const [userLocations, setUserLocations] = useState([]);

	useEffect(() => {
		if (profileLocationLists) {
			setUserLocations(profileLocationLists);
		}
	}, [profileLocationLists]);

	const handleRemoveLocation = (locationIndex) => {
		setUserLocations((prevState) => prevState.filter((_, index) => index !== locationIndex));
	};

	const {
		viewRef,
		handleSearchInputChange,
		handleClearSearch,
		isFetching,
		searchInput,
		locations,
	} = useGetLocationsList();

	const locationsList = useMemo(
		() =>
			userLocations?.map((location, index) => (
				<Tag
					key={location.id}
					itemName={`${location.name}, ${location.countryDTO.name}`}
					onRemove={() => handleRemoveLocation(index)}
				/>
			)),
		[userLocations]
	);

	const itemsList = useMemo(
		() =>
			locations?.pages?.map((page) => {
				const unDuplicatedPage = unduplicateValue(userLocations, page, "id");
				return unDuplicatedPage.map((item, i) => (
					<button
						key={item.id}
						ref={unDuplicatedPage.length === i + 1 ? viewRef : null}
						className={styles.location_item}
						onClick={() => setUserLocations((prevState) => [...prevState, item])}
					>
						{`${item.name}, ${item.countryDTO.name}`}
					</button>
				));
			}),
		[locations, viewRef, userLocations]
	);

	const {
		action: { mutate: saveMyLocations, isLoading: isLoadingSave },
	} = useMutate(
		profileApi.updateProfileLocations,
		() => {
			queryClient.invalidateQueries(profileKeys.myLocations());
			onSave();
		},
		undefined,
		{
			meta: {
				successMessage: "Locations saved successfully",
			},
		}
	);

	const handleSaveLocations = () => {
		saveMyLocations(userLocations.map((location) => location.id));
	};

	return (
		<>
			<div className={styles.search_wrapper}>
				<SearchInput
					onChange={handleSearchInputChange}
					value={searchInput}
					showIcons={true}
					onClearClicked={handleClearSearch}
					border={false}
					placeholder={"Search locations"}
				/>
			</div>
			<div className={styles.add_container}>
				{locationsList?.length > 0 && (
					<TagContainer
						label={"Selected Locations"}
						items={locationsList}
						containerClassName={styles.tags_container}
						border={false}
						scrollToBottomEnabled={true}
					/>
				)}

				<div className={styles.items_list_wrapper}>
					{itemsList}
					<LoadingSpinner visible={isFetching} />

					<NoResults
						isSearch={true}
						text={"No results related to your search"}
						visible={searchInput && itemsList?.[0].length === 0 && !isFetching}
					/>
				</div>

				<div className={styles.form_controls}>
					<PrimaryButton
						isLoading={isLoadingSave}
						onClick={handleSaveLocations}
						text={"Done"}
						type="button"
						className={styles.create_btn}
					/>
				</div>
			</div>
		</>
	);
};

export default UserLocationsModal;
