import { forwardRef, useImperativeHandle, useRef } from "react";
import CustomMentions from "../Utils/Mentions/CustomMentions";
import styles from "./CommentInput.module.css";
import ProfilePic from "./ProfilePic";

const CommentInput = forwardRef(
	(
		{
			profileImg,
			type,
			url,
			onTextChange,
			text,
			onConfirm,
			onEmojiClick,
			onCancel,
			isEditing,
			name,
		},
		ref
	) => {
		const inputRef = useRef(null);

		useImperativeHandle(ref, () => ({
			focus() {
				inputRef.current.focusInput();
			},
		}));

		const handleKeyDown = (event) => {
			if (
				(event.code === "Enter" ||
					event.key === "Enter" ||
					event.which === 13 ||
					event.keyCode === 13) &&
				!event.shiftKey
			) {
				event.preventDefault();
				onConfirm();
			}
		};

		return (
			<div className={styles.comment_add_wrapper}>
				<ProfilePic
					thumbnail={profileImg}
					type={type}
					url={url}
					enableName={false}
					name={name}
				/>
				<div className={styles.comment_text_wrapper}>
					<CustomMentions
						ref={inputRef}
						onChange={onTextChange}
						onEmojiSelected={onEmojiClick}
						value={text}
						placeholder={"Add a comment"}
						onKeyDown={handleKeyDown}
						isComment={true}
					/>
					{isEditing && (
						<span className={styles.cancel_edit} onClick={onCancel}>
							cancel
						</span>
					)}
				</div>
			</div>
		);
	}
);

export default CommentInput;
