import { ethnicitiesKeys } from "queryKeys/ethnicities-key-factory";
import { useCustomQuery } from "./useCustomQuery";
import useApi from "./useApi";
import ethnicityApi from "api/ethnicity";

const useEthnicities = () => {
	const getEthnicitiesApi = useApi(ethnicityApi.getEthnicities, true, true);

	const fetchEthnicity = async () => {
		const response = await getEthnicitiesApi.request();
		return response.data;
	};

	const query = useCustomQuery({
		queryKey: ethnicitiesKeys.all,
		queryFn: fetchEthnicity,
		enabled: true,
		options: { staleTime: 300000 }, // 5 minutes
	});

	return query;
};

export default useEthnicities;
