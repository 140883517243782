import { useCallback, useEffect, useMemo, useState } from "react";
import PrimaryButton from "../../../../../Utils/Button/PrimaryButton";
// import PromptMessage from "../../../../../Utils/Prompt/PromptMessage";
import Media from "../../SpecialProfileSections/Media";
import styles from "./MediaEntry.module.css";
import Joi from "joi";
import { Controller, useForm } from "react-hook-form";
import { joiResolver } from "@hookform/resolvers/joi";
import { useLocation, useNavigate, useParams } from "react-router-dom";
// import deleteIcon from "images/icon-exit.svg";
// import playIcon from "images/play-icon-blue.svg";
// import useApi from "../../../../../../hooks/useApi";
import specialProfileApi from "../../../../../../api/special-profile";
import LoadingBar from "../../../../../Utils/SubComs/LoadingBar/LoadingBar";
import FileThumbnail from "../../../../../Utils/SubComs/FileThumbnail/FileThumbnail";
import { getFileIcon, getVideoFrame } from "../../../../../Utils/General";
import PromptModal from "components/Utils/Prompt/PromptModal";
import { specialProfileKeys } from "queryKeys/specialProfile-key-factory";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import routes from "components/Routing/routing-keys";
// import { toast } from "react-toastify";
import useUrlPreview from "hooks/useUrlPreview";

const uploadSingleFile = async ({ specialProfileId, file, onUploadProgress }) => {
	const formData = new FormData();

	formData.append("specialProfileId", specialProfileId);
	formData.append("multipartFile", file);

	const response = await specialProfileApi.addMedia(formData, onUploadProgress);

	if (!response.ok) {
		// throw new Error("File upload failed");
		throw response.data;
	}

	return response.data;
};

const MediaEntry = ({ profile }) => {
	const navigate = useNavigate();
	const queryClient = useQueryClient();

	const { state } = useLocation();

	const { userUrl } = useParams();

	const [isUploading, setIsUploading] = useState(false);

	const { audiosCount, videosCount, imagesCount, uuid: specialProfileId } = profile;

	const [overallProgress, setOverallProgress] = useState(0);
	const uploadMutation = useMutation(uploadSingleFile);

	const schema = Joi.object({
		file: Joi.array().min(0),
	});

	const {
		formState: { isDirty, isSubmitSuccessful },
		handleSubmit,
		control,
		watch,
		setValue,
		// reset,
	} = useForm({
		resolver: joiResolver(schema),
		mode: "onSubmit",
		defaultValues: {
			file: [],
		},
	});

	const fileWatcher = watch("file");

	const handleLoadMedia = (e) => {
		const files = Array.from(e.target.files);
		setValue("file", [...fileWatcher, ...files], { shouldDirty: true });
	};

	const { objectUrls, setObjectUrls, cleanupObjectUrls } = useUrlPreview();

	useEffect(() => {
		// Create object URLs when fileWatcher changes
		const loadItems = async () => {
			const promises = fileWatcher.map(async (media) => {
				if (media.type.startsWith("image")) {
					return URL.createObjectURL(media);
				} else if (media.type.startsWith("video")) {
					const videoFirstFrame = await getVideoFrame(media);
					return videoFirstFrame;
				} else {
					return getFileIcon(media, media.name).icon;
				}
			});

			const resolvedUrls = await Promise.all(promises);
			setObjectUrls(resolvedUrls);
		};

		loadItems();

		// Cleanup function
		return () => {
			cleanupObjectUrls();
		};
	}, [fileWatcher]);

	const handleRemoveImg = useCallback(
		(index) => {
			const updatedArray = [...fileWatcher];
			updatedArray.splice(index, 1);
			setValue("file", updatedArray);
		},
		[fileWatcher, setValue]
	);

	const loadedMedias = useMemo(() => {
		return fileWatcher.map((item, index) => {
			const { type, name } = item;
			return (
				<FileThumbnail
					key={index}
					thumbnail={objectUrls[index]}
					isImage={type.startsWith("image")}
					isVideo={type.startsWith("video")}
					onDelete={(e) => handleRemoveImg(index, e)}
					label={name}
				/>
			);
		});
	}, [fileWatcher, handleRemoveImg, objectUrls]);

	const handleUploadFiles = async (data) => {
		setIsUploading(true);
		// Disable button during upload

		if (uploadMutation.isLoading) return;

		const { file: fileArray } = data;

		// Reset overall progress
		setOverallProgress(0);

		try {
			// Use Promise.all to wait for all files to upload
			await Promise.all(
				fileArray.map((file, index) =>
					uploadMutation.mutateAsync({
						specialProfileId,
						file,
						onUploadProgress: (progress) => {
							// Calculate overall progress across all files
							const baseProgress = (index / fileArray.length) * 100;
							const currentFileProgress = (progress / 100) * (100 / fileArray.length);
							setOverallProgress(Math.round(baseProgress + currentFileProgress));
						},
					})
				)
			);

			// Only navigate if ALL files are uploaded successfully
			queryClient.invalidateQueries(specialProfileKeys.detail(userUrl));
			navigate(
				routes.specialProfile(userUrl, state?.agencyId ? `?agency=${state?.agencyId}` : "")
			);
		} catch (error) {
			// Handle any upload errors
			setIsUploading(false);
			console.error("Upload failed:", error);

			// Optionally, show an error message to the user
		}
	};

	return (
		<form
			className={styles.form}
			onSubmit={handleSubmit((data) => handleUploadFiles(data))}
			noValidate
		>
			<div className={styles.subsection}>
				{/* {showProgress && <LoadingBar progress={progress} />} */}

				{uploadMutation.isLoading && fileWatcher.length > 0 && (
					<LoadingBar progress={overallProgress} />
				)}

				<h3>Media</h3>
				<div className={styles.info_text}>
					You can add video, image and audio files to your media in your special profile.
					You can choose from your Vault, media from your Gallery, or you can upload new
					files.
				</div>

				<div className={styles.custom_upload_button}>
					{fileWatcher.length > 0 && (
						<div className={styles.thumbs_container}>{loadedMedias}</div>
					)}

					{/* <span className={styles.info_text}>You can upload image files only.</span> */}
					<div className={styles.img_option}>
						<button type="button">
							<Controller
								name="file"
								control={control}
								render={() => (
									<input
										type="file"
										accept="image/png, image/jpg, image/jpeg, audio/*, video/*"
										onChange={handleLoadMedia}
										tabIndex="-1"
										multiple
									/>
								)}
							/>
							+ ADD MEDIA
						</button>
					</div>
				</div>

				{(audiosCount > 0 || videosCount > 0 || imagesCount > 0) && (
					<div className={styles.subsection}>
						<h3>Your Media</h3>
						<Media
							specialProfileId={specialProfileId}
							editMode={true}
							userUrl={userUrl}
						/>
					</div>
				)}
			</div>

			<div className={styles.button_container}>
				<PrimaryButton className={styles.save_btn} text={"save"} />
			</div>

			<PromptModal when={isDirty && !isUploading && !isSubmitSuccessful} />
		</form>
	);
};

export default MediaEntry;
