import { joiResolver } from "@hookform/resolvers/joi";
import profileApi from "api/profile";
import routes from "components/Routing/routing-keys";
import { maxPostLength } from "components/Utils/General";
import PromptModal from "components/Utils/Prompt/PromptModal";
import useCurrentUser from "hooks/useCurrentUser";
import useMutate from "hooks/useMutate";
import Joi from "joi";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { otherUpdated as companyOtherUpdated } from "../../../../../store/slices/company";
import { otherUpdated } from "../../../../../store/slices/user";
import PrimaryButton from "../../../../Utils/Button/PrimaryButton";
import TextInput from "../../../../Utils/SubComs/Inputs/TextInput/TextInput";
import VisibilitySelector from "../../../../Utils/Visibility/VisibilitySelector";
import styles from "./Other.module.css";

const OtherEntry = () => {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const [isFocused, setIsFocused] = useState(false);
	const { pathname, state } = useLocation();

	const editMode = pathname === routes.editOther;

	const { uuid: companyId, type } = useCurrentUser();
	const editCompany = type === "COMPANY";

	const { uuid, information: info, title, visibility: currentVisibility } = state || {};

	const schema = Joi.object({
		title: Joi.string()
			.required()
			.label("Title")
			.messages({ "string.empty": "Title can't be empty" }),
		info: Joi.string()
			.required()
			.label("Info")
			.messages({ "string.empty": "Info can't be empty" }),
	});

	const {
		register,
		formState: { errors, isDirty, isSubmitSuccessful },
		handleSubmit,
		watch,
	} = useForm({
		resolver: joiResolver(schema),
		mode: "onSubmit",
		defaultValues: editMode
			? {
					title,
					info,
			  }
			: {
					title: "",
					info: "",
			  },
	});

	const infoWatcher = watch("info");
	const [visibility, setVisibility] = useState(currentVisibility ?? "WORK_CIRCLE_FRIENDS");

	const validCount = infoWatcher?.length > 0 && infoWatcher?.length <= maxPostLength;

	const handleVisibilityChange = (value) => () => {
		setVisibility(value);
	};

	const handleSuccess = (response, variables) => {
		const { companyId } = variables;
		dispatch(companyId ? companyOtherUpdated(response.data) : otherUpdated(response.data));
		navigate(routes.other, { replace: true });
	};

	const {
		action: { mutate: addOther, isLoading: isAdding },
	} = useMutate(editCompany ? profileApi.addCompanyOther : profileApi.addOther, handleSuccess);

	const {
		action: { mutate: updateOther, isLoading: isUpdating },
	} = useMutate(
		editCompany ? profileApi.updateCompanyOther : profileApi.updateOther,
		handleSuccess
	);

	const handleSave = (data) => {
		const { info: information, title } = data;

		const body = {
			...(editMode && { uuid }),
			title,
			information,
			visibility,
		};

		if (editMode) {
			updateOther({ ...(editCompany && { companyId }), body });
		} else {
			addOther({ ...(editCompany && { companyId }), body });
		}
	};

	if (editMode && !state) {
		return <Navigate to={routes.other} />;
	}

	return (
		<>
			{/* <div className={styles.container}> */}
			{/* <div className={styles.contact_section}> */}
			<form
				className={styles.add_form}
				onSubmit={handleSubmit((data) => handleSave(data))}
				noValidate
			>
				<div className={styles.form_input_wrapper}>
					<label>
						Title
						<span className="required">*</span>
					</label>

					<TextInput {...register("title")} error={!!errors.title} placeholder="Title" />
					{errors?.title?.message && (
						<p className={styles.error_message}>{errors?.title?.message}</p>
					)}
				</div>

				<div className={styles.textarea_container}>
					<label>
						Info
						<span className="required">*</span>
					</label>
					<div
						className={`${styles.textarea_wrapper} ${
							!!errors.info ? styles.error : undefined
						} ${isFocused ? styles.focused : undefined}`}
					>
						<textarea
							placeholder="Other info"
							maxLength={maxPostLength}
							{...register("info")}
							onFocus={() => setIsFocused(true)}
							onBlur={() => setIsFocused(false)}
						/>

						<div className={styles.word_count_wrapper}>
							<span
								className={
									validCount
										? styles.text_count
										: styles.text_count + " " + styles.danger
								}
							>
								{`${infoWatcher.length}/${maxPostLength}`}
							</span>
						</div>
					</div>
					{errors?.info?.message && (
						<p className={styles.error_message}>{errors?.info?.message}</p>
					)}
				</div>

				<div className={styles.visibility_wrapper}>
					<label className={styles.visibility_label}>
						Visibility
						<span className="required">*</span>
					</label>

					<VisibilitySelector value={visibility} onValueChange={handleVisibilityChange} />
				</div>
				<div className={styles.button_container}>
					<PrimaryButton
						className={styles.save_btn}
						isLoading={isAdding || isUpdating}
						text={"save"}
					/>
				</div>
			</form>
			{/* </div> */}
			{/*  </div> */}

			<PromptModal when={isDirty && !isSubmitSuccessful} />
		</>
	);
};

export default OtherEntry;
