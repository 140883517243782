import { useNavigate, useSearchParams } from "react-router-dom";
import styles from "./ResetPasswordForm.module.scss";
import md5 from "md5";
import PasswordValidationItem from "../PasswordForm/PasswordValidationItem";
import InputWrapper from "components/Utils/SubComs/Inputs/InputWrapper/InputWrapper";
import PrimaryButton from "components/Utils/Button/PrimaryButton";
import TextInput from "components/Utils/SubComs/Inputs/TextInput/TextInput";
import { useForm } from "react-hook-form";
import { joiResolver } from "@hookform/resolvers/joi";
import passwordSchema from "components/Registeration/Schema/password-schema";
import useMutate from "hooks/useMutate";
import profileApi from "api/profile";
import { useEffect, useState } from "react";
import routes from "components/Routing/routing-keys";

const ResetPasswordForm = ({ onRedirect }) => {
	const navigate = useNavigate();

	const [searchParams] = useSearchParams();
	const key = searchParams.get("key");
	const email = searchParams.get("email");
	const time = searchParams.get("time");

	const encryptedParams = md5(email + process.env.REACT_APP_MD5_PRIVATE_KEY + time);

	useEffect(() => {
		if (encryptedParams !== key) {
			navigate(routes.login);
		}
	}, [encryptedParams, key, navigate]);

	const {
		register,
		formState: { errors, isDirty },
		handleSubmit,
	} = useForm({
		resolver: joiResolver(passwordSchema),
		mode: "onChange",
		criteriaMode: "all",
	});

	const [isSuccess, setIsSuccess] = useState(false);

	const {
		action: { mutate: resetPassword, isLoading },
	} = useMutate(profileApi.resetPassword, () => setIsSuccess(true));

	return (
		<form
			className={styles.form_wrapper}
			onSubmit={handleSubmit((data) =>
				resetPassword({ email, password: md5(data.password), key })
			)}
		>
			<h3>Reset Password</h3>

			<p className={styles.description}>Please enter the new password here.</p>

			<PasswordValidationItem
				validate="length"
				label="8 characters"
				errors={errors}
				isDirty={isDirty}
			/>

			<PasswordValidationItem
				validate="upper"
				label="1 uppercase letter"
				errors={errors}
				isDirty={isDirty}
			/>

			<PasswordValidationItem
				validate="lower"
				label="1 lowercase letter"
				errors={errors}
				isDirty={isDirty}
			/>

			<PasswordValidationItem
				validate="digit"
				label="1 number"
				errors={errors}
				isDirty={isDirty}
			/>

			<PasswordValidationItem
				validate="special"
				label="1 special character"
				errors={errors}
				isDirty={isDirty}
			/>

			<InputWrapper
				label="Password"
				required={true}
				error={errors?.password}
				customError={
					errors?.password?.type === "string.empty" ? "Password is required" : ""
				}
				component={
					<TextInput
						{...register("password")}
						placeholder="Enter password"
						cursor="text"
						isPassword={true}
					/>
				}
			/>
			<InputWrapper
				label="Confirm Password"
				required={true}
				error={errors?.confirmPassword}
				component={
					<TextInput
						{...register("confirmPassword")}
						placeholder="Enter password again"
						cursor="text"
						isPassword={true}
					/>
				}
			/>

			<PrimaryButton text="next" className={styles.submit_btn} isLoading={isLoading} />

			{isSuccess && (
				<>
					<p className={styles.success_info}>
						Your password has been changed successfully. You can now login using the new
						password.
					</p>

					<PrimaryButton
						text="Login"
						className={styles.submit_btn}
						type="button"
						onClick={() => {
							onRedirect();
							navigate(routes.login, { replace: true });
						}}
					/>
				</>
			)}
		</form>
	);
};

export default ResetPasswordForm;
