import useMutate from "hooks/useMutate";
import binIcon from "images/bin-icon.svg";
import editIcon from "images/rename-icon.svg";
import replyIcon from "images/reply-icon.svg";
import moreIcon from "images/three-dots-black-5-x-20.svg";
import { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import postApi from "../../api/post";
import { getDiffIn } from "../Utils/General";
import Popover from "../Utils/Popover/Popover";
import CommentReactionsCounter from "../Utils/Reactions/CommentReactionsCounter";
import FormatSpecialText from "../Utils/SubComs/FormatSpecialText/FormatSpecialText";
import ReadMore from "../Utils/SubComs/ReadMore/ReadMore";
import CommentInput from "./CommentInput";
import styles from "./CommentItem.module.css";
import ProfilePic from "./ProfilePic";

const ReplyItem = ({
	commentId,
	reply,
	onReplyUpdate,
	onTextChange,
	editedText,
	onDelete,
	profileImg,
	type,
	url,
	onReply,
	isFullscreenPost,
	name,
}) => {
	const navigate = useNavigate();

	const popoverRef = useRef(null);

	// !===== REACTION HANDLERS =======

	const {
		action: { mutate: addReaction },
	} = useMutate(postApi.addReplyReaction, (response) => {
		onReplyUpdate(response.data);
	});
	const {
		action: { mutate: removeReaction },
	} = useMutate(postApi.removeReplyReaction, (response) => {
		onReplyUpdate(response.data);
	});

	const handleAddReaction = (reactionType) => {
		if (reply.profileReactionType === reactionType) removeReaction(reply.uuid);
		else addReaction({ replyId: reply.uuid, reactionType });
	};

	const handleReactionBtnClicked = () => {
		if (reply.profileReactionType) removeReaction(reply.uuid);
		else addReaction({ replyId: reply.uuid, reactionType: "gaawk" });
	};

	// !===== REPLY EDIT =======

	const {
		action: { mutate: updateReply },
	} = useMutate(postApi.updateReply, (response) => {
		onReplyUpdate(response.data);
		setIsEditing(false);
	});

	const [isEditing, setIsEditing] = useState(false);

	const handleEdit = () => {
		setIsEditing((prevState) => !prevState);
		onTextChange(reply.reply);
	};

	const handleConfirmReply = () => {
		updateReply({
			object: {
				reply: editedText,
				replyId: reply.uuid,
				commentId,
			},
		});
	};

	const handleEmojiClick = (_, emojiObject) => {
		onTextChange(editedText + emojiObject.emoji);
	};

	const handleCancelEdit = () => {
		setIsEditing(false);
		onTextChange("");
	};

	return (
		<>
			{!isEditing ? (
				<div className={styles.comment_item} key={reply.uuid}>
					<ProfilePic
						thumbnail={
							reply.profile.profileImage
								? reply.profile.profileImage.image
								: undefined
						}
						type={reply.profile.type}
						url={reply.profile.url}
						name={name}
						enableName={false}
					/>

					<div className={styles.comment_flex}>
						<div className={styles.comment}>
							<div className={styles.comment_header}>
								<div>
									<label
										className={styles.owner}
										onClick={() => navigate(`/profile/${reply.profile.url}`)}
									>
										{reply.profile.name}
									</label>
									<span className={styles.time}>
										{getDiffIn(reply.replyTime)}
									</span>
								</div>

								<Popover
									ref={popoverRef}
									render={
										<div className="popover_container">
											<>
												<div
													className="popover_item"
													onClick={() => {
														onReply(reply.profile.url);
														popoverRef.current.closePopover();
													}}
												>
													<img src={replyIcon} alt="reply" />
													<span>Reply</span>
												</div>

												<div className="popover_item" onClick={handleEdit}>
													<img src={editIcon} alt="forward" />
													<span>Edit</span>
												</div>

												<div
													className="popover_item"
													onClick={() => onDelete(reply.uuid)}
												>
													<img src={binIcon} alt="delete" />
													<span>Delete</span>
												</div>
											</>
										</div>
									}
								>
									<button className={styles.option_icon}>
										<img src={moreIcon} alt="options" />
									</button>
								</Popover>
							</div>

							<ReadMore>
								<FormatSpecialText
									text={reply.reply}
									customStyle={styles.reply_text}
								/>
							</ReadMore>
						</div>

						<CommentReactionsCounter
							item={reply}
							onReply={() => onReply(reply.profile.url)}
							onClick={handleReactionBtnClicked}
							onReact={handleAddReaction}
							isFullscreenPost={isFullscreenPost}
						/>
					</div>
				</div>
			) : (
				<>
					<CommentInput
						profileImg={profileImg}
						name={name}
						type={type}
						url={url}
						onTextChange={onTextChange}
						text={editedText}
						// onQuery={handleQuery}
						// onKeyPress={handleKeyPress}
						onEmojiClick={handleEmojiClick}
						onCancel={handleCancelEdit}
						isEditing={isEditing}
						onConfirm={handleConfirmReply}
					/>
				</>
			)}
		</>
	);
};

export default ReplyItem;
