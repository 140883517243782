import client from "./client";

const endpoint = "/calendar";

const getEntry = (entryId) => client.get(endpoint + `/${entryId}`);

const getEntries = (month, year, requestedProfileId) =>
	client.get(endpoint + `?month=${month}&year=${year}&requestedProfileId=${requestedProfileId}`);

const updateCalendarVisibility = (bool) => client.put(endpoint + `/state?state=${bool}`);

const addEntry = (body) => client.post(endpoint, body);
const updateEntry = (body) => client.put(endpoint, body);
const deleteEntry = (entryId) => client.delete(endpoint + `?entryId=${entryId}`);

// eslint-disable-next-line import/no-anonymous-default-export
export default {
	getEntry,
	getEntries,
	updateCalendarVisibility,
	addEntry,
	updateEntry,
	deleteEntry,
};
