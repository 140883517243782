import client from "./client";

const endpoint = "/vault";

const getInfo = () => client.get(endpoint + `/info`);

// const getRecents = () => client.get(endpoint + `/items/recent`);

const getMeItems = (page, size, sort, parentFolderId) =>
	client.get(
		endpoint +
			`/items/me?page=${page}&size=${size}&sort=${sort}${
				parentFolderId ? `&parentFolderId=${parentFolderId}` : ""
			}`
	);

const getGaawkItems = (page, size, sort, parentFolderId) =>
	client.get(
		endpoint +
			`/items/gaawk?page=${page}&size=${size}&sort=${sort}${
				parentFolderId ? `&parentFolderId=${parentFolderId}` : ""
			}`
	);

const getSharedItems = (page, size, sort) =>
	client.get(endpoint + `/items/me/shared?page=${page}&size=${size}&sort=${sort}`);

const getFavoriteItems = (page, size, sort) =>
	client.get(endpoint + `/items/favorites?page=${page}&size=${size}&sort=${sort}`);

const createFolder = (name, onRoot, parentFolderId) => {
	return client.post(
		endpoint + `/folder?folderName=${name}&onRoot=${onRoot}&parentFolderId=${parentFolderId}`
	);
};

const updateFavorite = (itemId, state) =>
	client.patch(endpoint + `/items/favorites?itemId=${itemId}&status=${state}`);

const renameItem = (itemId, newName) =>
	client.patch(endpoint + `/items/rename?itemId=${itemId}&name=${newName}`);

const getItemInfo = (itemId) => client.get(endpoint + `/share/info?itemId=${itemId}`);

const shareItems = (items, profiles) => {
	const itemIdsString = items.map((item) => `items=${item.uuid}`).join("&");
	const profileIdsString = profiles
		.map((profile) => `sharedProfileIds=${profile.uuid}`)
		.join("&");

	return client.post(endpoint + `/share?${itemIdsString}&${profileIdsString}`, {}, {});
};

const withdrawShare = (itemId, profileId) =>
	client.delete(endpoint + `/share/withdraw?itemId=${itemId}&sharedWithProfileId=${profileId}`);

const removeItems = (items) => {
	const itemIdsString = items.map((item) => `items=${item.uuid}`).join("&");

	return client.delete(endpoint + `/share/remove?${itemIdsString}`);
};

const deleteItems = (items) => {
	const itemIdsString = items.map((item) => `items=${item.uuid}`).join("&");

	return client.delete(endpoint + `/items?${itemIdsString}`);
};

const getFolders = (page, size, parentId) =>
	client.get(
		endpoint +
			`/items/folders?page=${page}&size=${size}${
				parentId && `&parentFolderId=${parentId}`
			}&sort=ALPHA`
	);

const copyItems = (items, parentFolderId) => {
	const itemIdsString = items.map((item) => `items=${item.uuid}`).join("&");
	return client.post(endpoint + `/items/copy?${itemIdsString}&toParentFolder=${parentFolderId}`);
};

const moveItems = (items, parentFolderId) => {
	const itemIdsString = items.map((item) => `items=${item.uuid}`).join("&");
	return client.patch(endpoint + `/items/move?${itemIdsString}&toParentFolder=${parentFolderId}`);
};

const addFile = (file, parentId, onUploadProgress) => {
	const formData = new FormData();
	formData.append("file", file);
	formData.append("fileName", file.name);
	formData.append("onRoot", !parentId);
	formData.append("parentFolderId", parentId);

	return client.post(endpoint + "/file", formData, {
		onUploadProgress: (progressEvent) => {
			const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
			onUploadProgress(percentCompleted);
		},
	});
};

const getItemById = (itemId) => client.get(endpoint + `/item?itemId=${itemId}`);

const getItemsByParentId = (parentId, page, size, sort) =>
	client.get(
		endpoint + `/items/byParentId?parentId=${parentId}&page=${page}&size=${size}&sort=${sort}`
	);

const getCoverLetter = (page = 0, size = 10) =>
	client.get(endpoint + `/items/coverLetters?page=${page}&size=${size}`);

const getPortfolio = (page = 0, size = 10) =>
	client.get(endpoint + `/items/portfolio?page=${page}&size=${size}`);

// eslint-disable-next-line import/no-anonymous-default-export
export default {
	getInfo,
	// getRecents,
	getMeItems,
	getGaawkItems,
	getSharedItems,
	getFavoriteItems,
	createFolder,
	updateFavorite,
	renameItem,
	getItemInfo,
	shareItems,
	withdrawShare,
	removeItems,
	deleteItems,
	getFolders,
	copyItems,
	moveItems,
	addFile,
	getItemById,
	getItemsByParentId,
	getCoverLetter,
	getPortfolio,
};
