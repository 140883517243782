import useMutate from "hooks/useMutate";
import productApi from "api/product";

export const useDeleteMedia = (onDeleteSuccess, productId, otherImagesWatcher, setValue) => {
	const { warningModal, setWarningModal, handleRequestMutate, handleConfirmMutate, activeItem } =
		useMutate(productApi.deleteProductMedia, onDeleteSuccess);

	const handleDeleteMulti = (item, index) => {
		if (item instanceof Blob) {
			const updatedArray = otherImagesWatcher.filter((_, i) => i !== index);
			setValue("otherImages", updatedArray);
		} else {
			handleRequestMutate({ productId, vaultItemId: item.uuid });
		}
	};
	return {
		warningModal,
		setWarningModal,
		handleConfirmMutate,
		handleDeleteMulti,
		activeItem,
	};
};
