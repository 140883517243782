import profileApi from "api/profile";
import routes from "components/Routing/routing-keys";
import CtaButton from "components/Utils/Button/CtaButton";
import useCurrentUser from "hooks/useCurrentUser";
import useMutate from "hooks/useMutate";
import { useMemo } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { educationUpdated } from "../../../../store/slices/user";
import WarningModal from "../../../Utils/GaawkModal/WarningModal";
import styles from "./Education.module.css";
import EducationItem from "./EducationItem";

const Education = () => {
	const navigate = useNavigate();
	const dispatch = useDispatch();

	const { educations } = useCurrentUser();

	const { handleRequestMutate, handleConfirmMutate, activeItem, warningModal, setWarningModal } =
		useMutate(profileApi.deleteEducation, (response) => {
			dispatch(educationUpdated(response.data));
		});

	const formattedEducation = useMemo(() => {
		return educations.map((item) => (
			<EducationItem
				key={item.uuid}
				item={item}
				onEditClicked={() =>
					navigate(routes.editEducation, {
						state: item,
					})
				}
				onDelete={() => handleRequestMutate(item)}
			/>
		));
	}, [educations]);

	return (
		<>
			<div className={styles.button_wrapper}>
				<CtaButton
					text="+ ADD A NEW EDUCATION"
					onClick={() => navigate(routes.addEducation)}
				/>
			</div>

			{educations.length > 0 && (
				<div className={styles.container}>
					<h4 className={styles.title}>Educations you have added</h4>

					<div className={styles.certificates_section}>{formattedEducation}</div>
				</div>
			)}

			<WarningModal
				show={warningModal}
				headerText="Are you sure you want to delete this information?"
				warningText="This action cannot be undone."
				cancelButtonText={"Cancel"}
				onCancelButtonClicked={() => setWarningModal(false)}
				submitButtonText={"DELETE"}
				onSubmitButtonClicked={() => handleConfirmMutate(activeItem.uuid)}
			/>
		</>
	);
};

export default Education;
