import useMutate from "./useMutate";

const useEditSubPost = (endpoint, onSuccess, returnResponseData = false) => {
	const {
		action: { mutate },
	} = useMutate(endpoint, (response, variables) => {
		if (returnResponseData) {
			onSuccess(response.data);
		} else {
			const { body } = variables;
			const newItemId = body.postId;

			const subPosts = [];

			if (response.data.totalMediaCount > 0) {
				subPosts.push(...response.data.mediaList);
			}

			if (response.data.totalPDFCount > 0) {
				subPosts.push(...response.data.pdfList);
			}

			const updatedSubPost = subPosts.find((sub) => sub.uuid === newItemId);

			onSuccess(updatedSubPost);
		}
	});

	const handleEdit = async (newItem, ...args) => {
		const body = {
			postId: newItem.uuid,
			...(newItem.caption && { caption: newItem.caption }),
			...(newItem.date > 0 && { date: newItem.date }),
			...(newItem.location && {
				location: {
					latitude: newItem.location.latitude,
					longitude: newItem.location.longitude,
					title: newItem.location.type,
					type: newItem.location.type,
				},
			}),
			...(newItem.taggedIds.length > 0 && {
				taggedIds: newItem.taggedIds.map((tag) => tag.uuid),
			}),
			...(newItem.services?.length > 0 && {
				serviceIds: newItem.services.map((service) => service.uuid),
			}),
		};

		mutate({ ...(args.length > 0 && args[0]), body });
	};

	return { handleEdit };
};

export default useEditSubPost;
