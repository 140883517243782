import GaawkModal from "components/Utils/GaawkModal/GaawkModal";
import SearchInput from "components/Utils/SubComs/Inputs/SearchInput/SearchInput";
import useDebounce from "hooks/useDebounce";
import useInfiniteScroll from "hooks/useInfiniteScroll";
import { classNames } from "primereact/utils";
import { Fragment, useMemo, useState } from "react";
import PrimaryButton from "../Button/PrimaryButton";
import ContactLoader from "../SubComs/CustomLoader/ContactLoader";
import NoResults from "../SubComs/NoResults/NoResults";
import Tag from "../SubComs/Tags/Tag";
import TagContainer from "../SubComs/Tags/TagContainer";
import styles from "./UserListModal.module.scss";

const UserListModal = ({
	show,
	onClose,
	title,
	fetchUsers,
	renderUser,
	queryKey,
	hasSearch = false,
	closeAlign = "right",
	showSelectedContainer = false,
	selectedUsers = [],
	onConfirm,
	onRemove,
	pageSize,
}) => {
	const [searchInput, setSearchInput] = useState("");
	const debouncedQuery = useDebounce(searchInput);

	const handleSearchInputChange = ({ target }) => {
		setSearchInput(target.value);
	};

	const handleClearSearch = () => {
		setSearchInput("");
	};

	const { items, viewRef, isFetchingNextPage, isLoading } = useInfiniteScroll({
		queryKey: queryKey(debouncedQuery),
		queryFn: fetchUsers,
		pageSize,
		enabled: show,
	});

	//TODO >> also filter the below is you have a selectedUSers arrays passed in props

	const userList = useMemo(
		() =>
			items.map((user, index) => {
				const isLastItem = index === items.length - 1;
				return (
					<Fragment key={user.uuid}>
						{renderUser({
							user,
							ref: isLastItem ? viewRef : null,
						})}
						{isLastItem && isFetchingNextPage && <ContactLoader />}
					</Fragment>
				);
			}),
		[isFetchingNextPage, items, renderUser, viewRef]
	);

	const selectedTags = useMemo(
		() =>
			selectedUsers?.map((user, index) => (
				<Tag key={user.uuid} itemName={user.name} onRemove={() => onRemove(user, index)} />
			)),
		[selectedUsers]
	);

	return (
		<GaawkModal
			show={show}
			handleClose={onClose}
			defaultModal={false}
			showHeader={true}
			title={title}
			closeAlign={closeAlign}
		>
			<div className={styles.container}>
				{hasSearch && (
					<SearchInput
						onChange={handleSearchInputChange}
						value={searchInput}
						showIcons={true}
						onClearClicked={handleClearSearch}
						border={false}
					/>
				)}

				{showSelectedContainer && (
					<TagContainer
						customStyle={styles.tags_container}
						scrollToBottomEnabled={true}
						items={selectedTags}
					/>
				)}

				<div
					className={classNames(styles.users_container, {
						[styles.no_margin_top]: !hasSearch,
					})}
				>
					<div className={styles.users_list}>
						{isLoading && (
							<>
								<ContactLoader />
								<ContactLoader />
								<ContactLoader />
							</>
						)}

						<NoResults
							isSearch={true}
							visible={!isLoading && items?.length === 0}
							text={"No results"}
						/>

						{items?.length > 0 && userList}
					</div>
				</div>

				{onConfirm && (
					<PrimaryButton
						className={styles.confirm_btn}
						onClick={onConfirm}
						text={"Done"}
					/>
				)}
			</div>
		</GaawkModal>
	);
};

export default UserListModal;
