import routes from "components/Routing/routing-keys";
import { format } from "date-fns";
import useMutate from "hooks/useMutate";
import addIcon from "images/add-icon.svg";
import calendarIcon from "images/calendar-icon-blue.svg";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import Switch from "react-switch";
import calendarApi from "../../../../api/calendar";
import { updateCalendarState } from "../../../../store/slices/user";
import DeleteButton from "../../../Utils/Button/DeleteButton";
import EditButton from "../../../Utils/Button/EditButton";
import SecondaryButton from "../../../Utils/Button/SecondaryButton";
import GaawkModal from "../../../Utils/GaawkModal/GaawkModal";
import { entryTypes } from "../../../Utils/General";
import RangesCalendar from "../../../Utils/RangesCalendar/RangesCalendar";
import styles from "./MyCalendar.module.css";

const MyCalendar = ({ canEdit, calendarEntries, onMonthChange, onDeleteEntry, userInfo }) => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { pathname } = useLocation();

	const { calendarEnabled } = useSelector((state) => state.user.profileInfo);

	const {
		action: { mutate: updateCalendarVisibility },
	} = useMutate(calendarApi.updateCalendarVisibility, () => dispatch(updateCalendarState()));

	// !====== GET CALENDAR ENTRIES ======

	const [showEntryModal, setShowEntryModal] = useState(false);
	const [calendarEntry, setCalendarEntry] = useState("");

	const handleEntryClick = (entry) => {
		const { from, to, ...otherProps } = entry;
		setCalendarEntry({
			startTime: from.getTime(),
			endTime: to.getTime(),
			...otherProps,
		});
		setShowEntryModal(true);
	};

	// !===== DELETE ENTRY =====

	const handleDeleteEntry = (calendarEntry) => {
		onDeleteEntry(calendarEntry);
		setShowEntryModal(false);
	};

	return (
		<div className={styles.container}>
			<div className={styles.header}>
				<h3>{canEdit ? "My" : `${userInfo.firstName}'s`} Calendar</h3>
				{canEdit && (
					<div className={styles.btn_wrapper}>
						<SecondaryButton
							text={
								<>
									<img src={addIcon} alt="add" className={styles.add_icon} />
									New
								</>
							}
							className={styles.btn}
							type="submit"
							onClick={() => navigate(routes.calendarAdd)}
						/>

						{pathname !== routes.calendar && (
							<EditButton onClick={() => navigate(routes.calendar)} />
						)}
					</div>
				)}
			</div>

			<div className={styles.body}>
				<RangesCalendar
					entries={calendarEntries}
					onMonthChange={onMonthChange}
					onClick={handleEntryClick}
					// canEdit={canEdit}
				/>
				<div className={styles.entry_caption_container}>
					<div className={styles.entry_caption_item}>
						<div className={`${styles.color_pin} ${styles.booked}`} />
						<p>Booked</p>
					</div>
					<div className={styles.entry_caption_item}>
						<div className={`${styles.color_pin} ${styles.penciled}`} />
						<p>Penciled</p>
					</div>
					<div className={styles.entry_caption_item}>
						<div className={`${styles.color_pin} ${styles.blacked_out}`} />
						<p>Blacked out</p>
					</div>
				</div>

				{calendarEntry && (
					<GaawkModal
						show={showEntryModal}
						handleClose={() => setShowEntryModal(false)}
						defaultModal={false}
						showHeader={true}
						title={"Calendar Entry Details"}
						closeAlign={"right"}
						children={
							<div className={styles.entry_details_container}>
								<div className={styles.entry_details}>
									<img src={calendarIcon} alt="calendar icon" />
									<div className={styles.entry_status}>
										<div className={styles.entry_type}>
											<div
												className={styles.color_pin}
												style={{
													backgroundColor:
														calendarEntry.type === "Booked"
															? "#ffad5c"
															: calendarEntry.type === "Penciled"
															? "#83d1ad"
															: "#a6b1bc",
												}}
											></div>
											{
												entryTypes.find(
													(entry) => entry.value === calendarEntry.type
												).label
											}
										</div>
										<div className={styles.entry_dates}>
											{format(
												new Date(calendarEntry.startTime),
												"dd MMM. yyyy"
											)}{" "}
											-{" "}
											{format(
												new Date(calendarEntry.endTime),
												"dd MMM. yyyy"
											)}
										</div>
									</div>
								</div>
								<div className={styles.label}>{calendarEntry.label}</div>
								<div className={styles.notes}>{calendarEntry.notes}</div>
								{canEdit && (
									<>
										<div className={styles.btn_wrapper}>
											<EditButton
												onClick={() =>
													navigate(
														routes.calendarEdit(calendarEntry.uuid)
													)
												}
											/>
											<DeleteButton
												onClick={() => handleDeleteEntry(calendarEntry)}
											/>
										</div>
									</>
								)}
							</div>
						}
					/>
				)}
			</div>

			{canEdit && (
				<>
					<div className={styles.switch_container}>
						<Switch
							onChange={() => updateCalendarVisibility(!calendarEnabled)}
							checked={calendarEnabled}
							onColor="#6cc5d1"
							offColor="#a6b1bc"
							handleDiameter={18}
							width={39}
							height={24}
							checkedIcon={false}
							uncheckedIcon={false}
							activeBoxShadow="0px 0px 1px 8px rgb(108, 197, 209, 0.3)"
						/>
						Make my Calendar visibile to other people
					</div>
					<div className={styles.info_message}>
						If your work status is set to “Not looking for a job” your Calendar will not
						be visible to other people.
					</div>
				</>
			)}
		</div>
	);
};

export default MyCalendar;
