import { createSlice } from "@reduxjs/toolkit";
// import * as apiActions from "../actionCreators/api";

const initialState = {
	companyInfo: {},
};

const slice = createSlice({
	name: "Company",
	initialState,
	reducers: {
		companyInfoUpdated: (state, action) => {
			state.companyInfo = action.payload;
		},

		bioUpdate: (state, action) => {
			state.companyInfo.bio = action.payload.bio;
		},

		bioDelete: (state) => {
			state.companyInfo.bio = "";
		},
		certificateUpdated: (state, action) => {
			state.companyInfo.certificates = action.payload.certificates;
		},
		updateKeyPeople: (state, action) => {
			state.companyInfo.keyPeople = action.payload.keyPeople;
		},
		otherUpdated: (state, action) => {
			state.companyInfo.others = action.payload.others;
		},
		socialUpdated: (state, action) => {
			state.companyInfo.socials = action.payload.socials;
		},
		addressUpdated: (state, action) => {
			state.companyInfo.addresses = action.payload.addresses;
		},
		siteUpdated: (state, action) => {
			state.companyInfo.sites = action.payload.sites;
		},
		phoneUpdated: (state, action) => {
			state.companyInfo.contacts = action.payload.contacts;
		},
		companyProfileImageUpdated: (state, action) => {
			state.companyInfo.profileImage = action.payload.profileImage;
		},
		coverUpdated: (state, action) => {
			state.companyInfo.profileCover = action.payload.profileCover;
		},
		resetCompanyInfo: () => initialState,
	},
});

export const {
	companyInfoUpdated,
	bioUpdate,
	bioDelete,
	certificateUpdated,
	updateKeyPeople,
	otherUpdated,
	socialUpdated,
	addressUpdated,
	siteUpdated,
	phoneUpdated,
	companyProfileImageUpdated,
	coverUpdated,
	resetCompanyInfo,
} = slice.actions;

export default slice.reducer;

// export const getCompanyInfo = (companyId) =>
// 	apiActions.apiCallBegan({
// 		url: `/profile/company/get?companyId=${companyId}`,
// 		method: "GET",
// 		onSuccess: companyInfoUpdated.type,
// 	});

/**
 * Courses & Certifications
 */
// export const addCompanyCertificate = (body, companyId) =>
// 	apiActions.apiCallBegan({
// 		url: `/profile/company/certificate?companyId=${companyId}`,
// 		method: "POST",
// 		data: body,
// 		onSuccess: certificateUpdated.type,
// 		showSuccessAlert: true,
// 	});

// export const deleteCompanyCertificate = (certificationId, companyId) =>
// 	apiActions.apiCallBegan({
// 		url: `/profile/company/certificate?certificationId=${certificationId}&companyId=${companyId}`,
// 		method: "DELETE",
// 		passedData: certificationId,
// 		onSuccess: certificateDeleted.type,
// 		// showSuccessAlert: true,
// 	});

// export const updateCompanyCertificate = (body, companyId) =>
// 	apiActions.apiCallBegan({
// 		url: `/profile/company/certificate?companyId=${companyId}`,
// 		method: "PUT",
// 		data: body,
// 		onSuccess: certificateUpdated.type,
// 		showSuccessAlert: true,
// 	});

// export const addCompanyCertificateFiles = (formData) =>
// 	apiActions.apiCallBegan({
// 		url: "/profile/company/certificate/file",
// 		method: "POST",
// 		data: formData,
// 		onSuccess: certificateUpdated.type,
// 		contentType: "multipart/form-data",
// 		// showSuccessAlert: true,
// 	});

// export const deleteCompanyCertificateFiles = (certificationId, fileId, companyId) =>
//     apiActions.apiCallBegan({
//         url: `/profile/company/certificate/file?certificationId=${certificationId}&fileId=${fileId}&companyId=${companyId}`,
//         method: "DELETE",
//         onSuccess: certificateUpdated.type,
//         // showSuccessAlert: true,
//     });

/**
 * Key People
 */

// export const deleteKeyPeople = (companyId, profileId) =>
// 	apiActions.apiCallBegan({
// 		url: `/profile/company/users/key?companyId=${companyId}&profileId=${profileId}`,
// 		method: "DELETE",
// 		onSuccess: removeKeyPerson.type,
// 		passedData: profileId,
// 		// showSuccessAlert: true,
// 	});

//PROFILE & COVER IMAGE

// export const updateCompanyProfileImage = (formData, imgData, companyId) => {
// 	const array = [];
// 	for (const [key, value] of Object.entries(imgData)) {
// 		array.push(`${key}=${value}`);
// 	}
// 	return apiActions.apiCallBegan({
// 		url: `/profile/company/profileImage?${array.join(
// 			"&"
// 		)}&companyId=${companyId}`,
// 		method: "PUT",
// 		data: formData,
// 		onSuccess: companyProfileImageUpdated.type,
// 		showSuccessAlert: true,
// 		contentType: "multipart/form-data",
// 	});
// };

// export const deleteCompanyProfileImage = (companyId) =>
// 	apiActions.apiCallBegan({
// 		url: `/profile/company/profileImage?companyId=${companyId}`,
// 		method: "DELETE",
// 		showSuccessAlert: true,
// 		onSuccess: companyProfileImageUpdated.type,
// 	});

// export const makeTempPermanentCompany = (companyId) =>
// 	apiActions.apiCallBegan({
// 		url: `/profile/company/profileImage/permTemp?companyId=${companyId}`,
// 		method: "PATCH",
// 		showSuccessAlert: true,
// 		onSuccess: companyProfileImageUpdated.type,
// 	});

// export const removeTempCompany = (companyId) =>
// 	apiActions.apiCallBegan({
// 		url: `/profile/company/profileImage/removeTemp?companyId=${companyId}`,
// 		method: "DELETE",
// 		showSuccessAlert: true,
// 		onSuccess: companyProfileImageUpdated.type,
// 	});

// export const updateCompanyCoverImage = (formData, imgData, companyId) => {
// 	const array = [];
// 	for (const [key, value] of Object.entries(imgData)) {
// 		array.push(`${key}=${value}`);
// 	}
// 	return apiActions.apiCallBegan({
// 		url: `/profile/company/coverImage?${array.join(
// 			"&"
// 		)}&companyId=${companyId}`,
// 		method: "PUT",
// 		data: formData,
// 		onSuccess: coverUpdated.type,
// 		contentType: "multipart/form-data",
// 		showSuccessAlert: true,
// 	});
// };
// export const deleteCompanyCoverImage = (companyId) =>
// 	apiActions.apiCallBegan({
// 		url: `/profile/company/coverImage?companyId=${companyId}`,
// 		method: "DELETE",
// 		onSuccess: coverUpdated.type,
// 		showSuccessAlert: true,
// 	});
