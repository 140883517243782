import { useQueryClient } from "@tanstack/react-query";
import { router } from "AppRoutes";
import routes from "components/Routing/routing-keys";
import FileSaver from "file-saver";
import useCurrentUser from "hooks/useCurrentUser";
import useInfiniteScroll from "hooks/useInfiniteScroll";
import useMutate from "hooks/useMutate";
import useVaultThumbCount from "hooks/useVaultThumbCount";
import { vaultKeys } from "queryKeys/vault-key-factory";
import { useEffect, useMemo, useRef, useState } from "react";
import { NavigationType, useParams } from "react-router-dom";
import vaultApi from "../../api/vault";
import styles from "../../components/Vault/VaultFolder.module.css";
import useApi from "../../hooks/useApi";
import PrimaryButton from "../Utils/Button/PrimaryButton";
import SecondaryButton from "../Utils/Button/SecondaryButton";
import FileViewer from "../Utils/FileViewer/FileViewer";
import ViewerHeader from "../Utils/FileViewer/ViewerHeader";
import FullScreenModal from "../Utils/GaawkModal/FullScreenModal";
import GaawkModal from "../Utils/GaawkModal/GaawkModal";
import { viewerMapper } from "../Utils/General";
import RectangleCheckbox from "../Utils/SubComs/Inputs/Checkbox/RectangleCheckbox";
import TextInput from "../Utils/SubComs/Inputs/TextInput/TextInput";
import SelectCommands from "../Utils/SubComs/SelectCommands/SelectCommands";
import SortingButtons from "../Utils/SubComs/SortingButtons/SortingButtons";
import VaultActions from "./VaultActions";
import VaultDeleteModal from "./VaultDeleteModal";
import VaultDetailsModal from "./VaultDetailsModal";
import VaultFolderHeader from "./VaultFolderHeader";
import VaultItems from "./VaultItems";
import VaultListItemsModal from "./VaultListItemsModal";
import VaultRenameModal from "./VaultRenameModal";
import VaultShareModal from "./VaultShareModal";
import VaultUserButtons from "./VaultUserButtons";
// import VaultLoader from "../Utils/SubComs/CustomLoader/VaultLoader";

// const perPageCount = 16;

// const MyListLoader = () => <List />;

const VaultFolderRightSide = () => {
	/**
	 * Selectors
	 */

	const queryClient = useQueryClient();

	const { uuid: profileId } = useCurrentUser();

	// Refs

	const fileviewerRef = useRef();

	/**
	 * Hooks
	 */
	const { id: itemId } = useParams();

	/**
	 * States
	 */

	const [selectedAll, setSelectedAll] = useState(false);
	const [selectedItem, setSelectedItem] = useState("");
	const [selectedItems, setSelectedItems] = useState([]);

	const [order, setOrder] = useState(localStorage.getItem("folderSort") || "NEWEST");
	const [layout, setLayout] = useState(localStorage.getItem("folderLayout") || "grid");

	// const isList = layout === "list";

	// modals states
	const [renameModal, setRenameModal] = useState(false);
	const [deleteModal, setDeleteModal] = useState(false);
	const [detailsModal, setDetailsModal] = useState(false);
	const [shareModal, setShareModal] = useState(false);
	const [copyModal, setCopyModal] = useState(false);
	const [moveModal, setMoveModal] = useState(false);

	// ! ===== get items by parent Id
	const displayCount = useVaultThumbCount();

	const getItemsByParentApi = useApi(vaultApi.getItemsByParentId, true, true);

	const fetchItems = async ({ pageParam = 0, queryKey }) => {
		const [_, __, parentId, sortOrder] = queryKey;
		const response = await getItemsByParentApi.request(
			parentId,
			pageParam,
			displayCount,
			sortOrder
		);
		return response.data;
	};

	const {
		items: data,
		isLoading,
		isFetching,
		hasNextPage,
		loadMore,
	} = useInfiniteScroll({
		queryFn: fetchItems,
		queryKey: vaultKeys.itemsByParentId(itemId, order),
		pageSize: displayCount,
		queryOptions: {
			getNextPageParam: (lastPage, pages) => {
				const nextPage = lastPage.items?.length === displayCount ? pages.length : undefined;
				return nextPage;
			},
			meta: {
				enableError: false,
			},
			useErrorBoundary: (error) => error.code === 403,
		},
	});

	const items = data.flatMap((page) => page.items);
	const parentItem = data[0]?.parentItem;

	/**
	 * Functions
	 */

	const handleSelectAll = () => {
		if (!selectedAll) {
			setSelectedItems(items);
		} else {
			setSelectedItems([]);
		}
		setSelectedAll((prevState) => !prevState);
	};

	const handleMultiDelete = () => {
		handleDelete(selectedItems);
	};

	const [isMultiShare, setIsMultiShare] = useState(false);

	const handleMultiShare = () => {
		setIsMultiShare(true);
		handleShare(selectedItems);
	};

	const handleMultiMove = () => {
		handleMove(selectedItems);
	};

	const handleMultiCopy = () => {
		handleCopy(selectedItems);
	};

	const handleClearSelection = () => {
		setSelectedItems([]);
	};

	const onChangeOrder = (sort) => {
		localStorage.setItem("folderSort", sort);
		setOrder(sort);
	};

	const onChangeLayout = (layout) => {
		localStorage.setItem("folderLayout", layout);
		setLayout(layout);
	};

	const handleSelect = (item) => {
		if (selectedItems.includes(item)) {
			const filteredArray = [...selectedItems].filter((x) => x.uuid !== item.uuid);
			setSelectedItems(filteredArray);
		} else {
			setSelectedItems((prevState) => [...prevState, item]);
		}
	};

	// ! ----- FAVORITE HANDLER ---------

	const {
		action: { mutate: favorite },
	} = useMutate(vaultApi.updateFavorite, () => {
		queryClient.invalidateQueries(vaultKeys.itemsByParentId(itemId, order));
		setSelectedItems([]);
	});

	const handleFavoriteItem = (item) => {
		if (showSlider) {
			const activeFile = getActiveFile();
			favorite({ itemId: activeFile.uuid, state: !activeFile.favorite });
		} else {
			favorite({ itemId: item.uuid, state: !item.favorite });
		}
	};

	// ! --------------------- RENAMING HANDLER -------------------------

	const {
		action: { mutate: rename },
	} = useMutate(vaultApi.renameItem, () => {
		queryClient.invalidateQueries(vaultKeys.itemsByParentId(itemId, order));
	});

	const handleRename = (item) => {
		setSelectedItem(item);
		setRenameModal(true);
	};

	const doRenameItem = (item, newTitle) => {
		rename({ itemId: item.uuid, newName: newTitle });
	};

	// !-----------------------------

	const handleDetails = (item) => {
		setSelectedItem(item);
		setDetailsModal(true);

		// getItemInfoApi.request(item.uuid);
	};

	const handleShare = (item) => {
		if (Array.isArray(item)) setSelectedItems(item);
		else setSelectedItem(item);

		setShareModal(true);
	};

	// const doShareItem = (profiles) => {
	// 	shareItemsApi.request(
	// 		selectedItem ? [selectedItem] : selectedItems,
	// 		profiles
	// 	);
	// };

	// ! ============= DELETE HANDLERS ===============
	const deleteInvalidation = () => {
		queryClient.invalidateQueries(vaultKeys.itemsByParentId(itemId, order));
		// queryClient.invalidateQueries(vaultKeys.storage()); //! not needed because no storage indicator on this page ?
		setSelectedItem("");
		setSelectedItems([]);
	};

	const {
		action: { mutate: removeVaultItems },
	} = useMutate(vaultApi.removeItems, deleteInvalidation, undefined, {
		meta: {
			successMessage: "Item deleted successfully!",
		},
	});

	const {
		action: { mutate: deleteVaultItems },
	} = useMutate(vaultApi.deleteItems, deleteInvalidation, undefined, {
		meta: {
			successMessage: "Item deleted successfully!",
		},
	});

	const handleDelete = (item) => {
		if (Array.isArray(item)) setSelectedItems(item);
		else setSelectedItem(item);

		setDeleteModal(true);
	};

	const doDeleteItems = () => {
		let deleteItems = [];
		let removeItems = [];

		if (selectedItem) {
			if (selectedItem.owner.uuid === profileId) {
				deleteItems = [selectedItem];
			} else {
				removeItems = [selectedItem];
			}
		} else {
			selectedItems.forEach((item) => {
				const mine = item.owner.uuid === profileId;

				if (mine) deleteItems = [...deleteItems, item];
				else removeItems = [...removeItems, item];
			});
		}

		if (deleteItems.length > 0) deleteVaultItems(deleteItems);

		if (removeItems.length > 0) removeVaultItems(removeItems);
	};

	// ! ================== COPY ITEMS =======================

	const {
		action: { mutate: copy },
	} = useMutate(
		vaultApi.copyItems,
		() => {
			queryClient.invalidateQueries(vaultKeys.itemsByParentId(itemId, order));
			queryClient.invalidateQueries(vaultKeys.storage());
			setSelectedItem("");
			setSelectedItems([]);
		},
		undefined,
		{
			meta: {
				successMessage: "Item copied successfully!",
			},
		}
	);

	const handleCopy = (item) => {
		if (Array.isArray(item)) setSelectedItems(item);
		else setSelectedItem(item);

		setCopyModal(true);
	};

	const doCopyItems = (toFolder) => {
		copy({
			items: selectedItem ? [selectedItem] : selectedItems,
			parentFolderId: toFolder?.uuid ?? "",
		});
	};

	// ! =============MOVING ITEMS======================

	const {
		action: { mutate: move },
	} = useMutate(
		vaultApi.moveItems,
		() => {
			queryClient.invalidateQueries(vaultKeys.itemsByParentId(itemId, order));
			setSelectedItem("");
			setSelectedItems([]);
		},
		undefined,
		{
			meta: {
				successMessage: "Item moved successfully!",
			},
		}
	);

	const handleMove = (item) => {
		if (Array.isArray(item)) setSelectedItems(item);
		else setSelectedItem(item);
		setMoveModal(true);
	};

	const doMoveItems = (toFolder) => {
		move({
			items: selectedItem ? [selectedItem] : selectedItems,
			parentFolderId: toFolder?.uuid ?? "",
		});
	};

	// !======== CREATE FOLDER HANDLER ========

	const [newFolderModal, setNewFolderModal] = useState(false);
	const [folderName, setFolderName] = useState("");

	const {
		action: { mutate: createFolder },
	} = useMutate(
		vaultApi.createFolder,
		() => {
			queryClient.invalidateQueries(vaultKeys.itemsByParentId(itemId, order));
			setFolderName("");
		},
		undefined,
		{
			meta: {
				successMessage: "Folder created!",
			},
		}
	);

	const handleCreateFolder = () => {
		if (folderName) {
			setNewFolderModal(false);
			parentItem &&
				createFolder({
					folderName,
					onRoot: false,
					parentFolderId: parentItem.uuid,
				});
		}
	};

	// !======== SLIDER HANDLER ========

	const [showSlider, setShowSlider] = useState(false);
	const [initialSlideIndex, setInitialSlideIndex] = useState(0);
	const [activeSlideIndex, setActiveSlideIndex] = useState(0);

	const filesArray = useMemo(() => viewerMapper(items?.filter((item) => item.file)), [items]);

	const handleShowSlider = (item) => {
		const fileIndex = filesArray.findIndex((file) => file.uuid === item.file.uuid);
		setInitialSlideIndex(fileIndex);
		setShowSlider(true);
		window.history.pushState(null, "", routes.vaultItem(item.uuid));
	};

	const handleCloseSlider = () => {
		setShowSlider(false);
		window.history.replaceState(null, "", routes.vaultFolder(itemId));
		// window.history.replaceState(null, "", `/vault/${itemId}`);
	};

	useEffect(() => {
		let unlisten;
		if (showSlider) {
			unlisten = router.subscribe((state) => {
				if (state.historyAction === NavigationType.Pop) {
					handleCloseSlider();
				}
			});
		}

		return () => unlisten && unlisten();
	}, [showSlider]);

	useEffect(() => {
		if (showSlider && activeSlideIndex > -1) {
			window.history.replaceState(null, "", `/vault/item/${getActiveFile().uuid}`);
		} else {
			handleCloseSlider();
		}
	}, [activeSlideIndex]);

	const getActiveFile = () => {
		const fileArray = items?.filter((item) => item.file);
		return fileArray[activeSlideIndex];
	};

	const handleSliderDownload = () => {
		FileSaver.saveAs(
			filesArray[activeSlideIndex].url,
			filesArray[activeSlideIndex].originalName
		);
	};

	// const loadingItems = [...Array(displayCount / 2)].map((_, index) => (
	// 	// <VaultLoader key={index} />
	// 	<VaultLoader key={index} isList={isList} />
	// ));

	/**
	 * JSX
	 */
	return (
		<>
			<VaultFolderHeader
				folder={parentItem}
				// folder={content ? content.parentItem : null}
			/>

			<div className={styles.vault_root_container}>
				<div
					className={`${styles.folder_controls} ${
						items.length > 0 ? styles.select_all : undefined
					}`}
				>
					<div style={{ display: "flex", gap: "20px" }}>
						<VaultUserButtons onCreate={() => setNewFolderModal(true)} />
						{items?.length > 0 && (
							<div
								className={styles.checkbox_container}
								onClick={(e) => e.stopPropagation()}
							>
								<RectangleCheckbox
									checked={items.length === selectedItems.length}
									onChange={handleSelectAll}
								/>
								<label>Select all</label>
							</div>
						)}
					</div>
					{selectedItems.length > 0 ? (
						<SelectCommands
							items={selectedItems}
							onDelete={handleMultiDelete}
							onShare={handleMultiShare}
							onMove={handleMultiMove}
							onCopy={handleMultiCopy}
							onClear={handleClearSelection}
						/>
					) : (
						<SortingButtons
							order={order}
							stateLayout={layout}
							onChangeOrder={onChangeOrder}
							onChangeLayout={onChangeLayout}
						/>
					)}
				</div>

				<VaultItems
					items={items}
					perPageCount={displayCount}
					layout={layout}
					isActive={selectedItems.length > 0}
					onSelect={handleSelect}
					selectedItems={selectedItems}
					onDelete={handleDelete}
					onDetails={handleDetails}
					onShare={handleShare}
					onMove={handleMove}
					onRename={handleRename}
					onCopy={handleCopy}
					onFavoriteItem={handleFavoriteItem}
					onLoadMoreItems={loadMore}
					hasNextPage={hasNextPage}
					onClick={handleShowSlider}
					isLoading={isLoading}
				/>
			</div>

			{/* {getItemsByParentApi.loading &&
				(!content || content?.items.length === 0) && (
					<div style={{ padding: "20px" }}>
						<FileContainer isList={isList}>
							{loadingItems}
						</FileContainer>
					</div>
				)} */}

			<GaawkModal
				show={newFolderModal}
				handleClose={() => setNewFolderModal(false)}
				defaultModal={false}
				showHeader={true}
				title={"Create new folder"}
				closeAlign={"right"}
				children={
					<div className={styles.create_folder_container}>
						<label>
							Folder name
							<span className="required">*</span>
						</label>

						<div className={styles.text}>
							<TextInput
								value={folderName}
								onChange={(e) => setFolderName(e.target.value)}
							/>
						</div>

						<div className={styles.buttons}>
							<SecondaryButton
								text={"CANCEL"}
								className={styles.cancel_button}
								onClick={() => setNewFolderModal(false)}
							/>
							<PrimaryButton
								text={"CREATE"}
								disabled={folderName === ""}
								className={styles.create_button}
								onClick={handleCreateFolder}
							/>
						</div>
					</div>
				}
			/>

			<VaultDeleteModal
				visible={deleteModal}
				onConfirm={doDeleteItems}
				onClose={() => setDeleteModal(false)}
				// onReset={() => {
				//     setSelectedItem("");
				//     setSelectedItems([]);
				// }}
			/>

			<VaultDetailsModal
				visible={detailsModal}
				item={selectedItem}
				// itemInfo={itemInfo}
				// loading={getItemInfoApi.loading}
				onClose={() => {
					setSelectedItem("");
					setDetailsModal(false);
				}}
			/>

			<VaultShareModal
				show={shareModal}
				onClose={() => {
					setShareModal(false);
					setIsMultiShare(false);
				}}
				items={isMultiShare ? selectedItems : selectedItem}
				onReset={() => {
					setSelectedItem("");
					setSelectedItems([]);
					setIsMultiShare(false);
				}}
			/>

			{copyModal && (
				<VaultListItemsModal
					visible={copyModal}
					onConfirm={doCopyItems}
					onClose={() => setCopyModal(false)}
					// onReset={() => {
					//     setSelectedItem("");
					//     setSelectedItems([]);
					// }}
					title={selectedItems.length > 1 ? "Copy Items to" : "Copy item to"}
					buttonTitle={"COPY HERE"}
					selectedItems={selectedItem ? [selectedItem] : selectedItems}
				/>
			)}

			{moveModal && (
				<VaultListItemsModal
					visible={moveModal}
					onConfirm={doMoveItems}
					onClose={() => setMoveModal(false)}
					// onReset={() => {
					//     setSelectedItem("");
					//     setSelectedItems([]);
					// }}
					title={"Move Item/s to"}
					isMove={true}
					selectedItems={selectedItem ? [selectedItem] : selectedItems}
					buttonTitle={"MOVE HERE"}
				/>
			)}

			<VaultRenameModal
				visible={renameModal}
				item={selectedItem}
				onConfirm={doRenameItem}
				onClose={() => {
					setSelectedItem("");
					setRenameModal(false);
				}}
			/>

			<FullScreenModal
				show={showSlider}
				onClose={handleCloseSlider}
				header={
					<ViewerHeader
						onClose={handleCloseSlider}
						leftSide={<div className={styles.parentFolderName}>{parentItem?.name}</div>}
						rightSide={
							<VaultActions
								isFavorite={filesArray && filesArray[activeSlideIndex]?.favorite}
								onFavorite={handleFavoriteItem}
								onDownload={handleSliderDownload}
								onShare={() => handleShare(getActiveFile())}
								onFullscreen={() => fileviewerRef.current.enterFullscreen()}
							/>
						}
					/>
				}
				children={
					<div className={styles.fileviewer_container}>
						<FileViewer
							ref={fileviewerRef}
							files={filesArray}
							initialSlide={initialSlideIndex}
							onLoadMore={loadMore}
							isLoading={isLoading}
							onActiveSlide={(index) => setActiveSlideIndex(index)}
							text={items && getActiveFile()?.name}
							// fsActions={
							//     <VaultFullscreenActions
							//         favorite={filesArray && filesArray[activeSlideIndex]?.favorite}
							//         onFavorite={handleFavoriteItem}
							//         onDownload={handleSliderDownload}
							//         onShare={() => handleShare(getActiveFile())}
							//     />
							// }
						/>
					</div>
				}
			/>
		</>
	);
};

export default VaultFolderRightSide;
