export const vaultKeys = {
	all: ["vault"],
	storage: () => [...vaultKeys.all, "storage"],
	shareInfo: (itemId) => [...vaultKeys.all, "shared", itemId],
	portfolio: () => [...vaultKeys.all, "portfolio"],
	coverLetters: () => [...vaultKeys.all, "coverLetters"],
	itemsByParentId: (parentId, sort) => [...vaultKeys.all, "itemsByParent", parentId, sort],
	vaultItems: (tab, sort) => [...vaultKeys.all, tab, sort],
	vaultItem: (id) => [...vaultKeys.all, "detail", id],
	folders: (parentId = "") => [...vaultKeys.all, "folders", parentId],
};
