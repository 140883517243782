import React from "react";
import { useMemo } from "react";
import styles from "./EditCalendar.module.css";
import EventItem from "./EventItem";
import MyCalendar from "./MyCalendar/MyCalendar";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import useCalendarEntries from "hooks/useCalendarEntries";
import WarningModal from "components/Utils/GaawkModal/WarningModal";
import routes from "components/Routing/routing-keys";

const EditCalendar = () => {
	const { uuid } = useSelector((state) => state.user.profileInfo);

	const {
		data: calendarEntries,
		handleMonthChange,
		handleRequestMutate,
		handleConfirmMutate,
		activeItem,
		warningModal,
		setWarningModal,
	} = useCalendarEntries(uuid);

	const navigate = useNavigate();

	const eventList = useMemo(
		() =>
			calendarEntries.map((event) => (
				<EventItem
					key={event.uuid}
					onEdit={() => navigate(routes.calendarEdit(event.uuid))}
					onDelete={() => handleRequestMutate(event)}
					event={event}
				/>
			)),
		[calendarEntries]
	);

	return (
		<div className={styles.container}>
			<div className={styles.sub_container}>
				<MyCalendar
					canEdit={true}
					calendarEntries={calendarEntries}
					onMonthChange={handleMonthChange}
					onDeleteEntry={handleRequestMutate}
				/>
			</div>

			{eventList.length > 0 && (
				<>
					<span className={styles.separator}></span>
					<div className={styles.sub_container}>{eventList}</div>
				</>
			)}

			<WarningModal
				show={warningModal}
				headerText="Are you sure you want to delete this calendar entry?"
				warningText="This action cannot be undone."
				cancelButtonText={"Cancel"}
				onCancelButtonClicked={() => setWarningModal(false)}
				submitButtonText={"DELETE"}
				onSubmitButtonClicked={() => handleConfirmMutate(activeItem.uuid)}
			/>
		</div>
	);
};

export default EditCalendar;
