import UserListModal from "components/Utils/UserListModal/UserListModal";
import useMutate from "hooks/useMutate";
import { profileKeys } from "queryKeys/profile-key-factory";
import profileApi from "../../../../api/profile";
import useApi from "../../../../hooks/useApi";
import CompanyItem from "../../../Companies/CompanyItem";
import PrimaryButton from "../../../Utils/Button/PrimaryButton";
import GaawkModal from "../../../Utils/GaawkModal/GaawkModal";
import styles from "./LinkToAgencyModal.module.css";

const PAGE_SIZE = 20;

const LinkToAgencyModal = ({ show, onClose, specialProfileId }) => {
	const searchAgenciesApi = useApi(profileApi.searchCompany, true, true);

	const searchAgencies = async ({ pageParam = 0, queryKey }) => {
		const [_, __, ___, query] = queryKey;
		const agenciesOnly = true;
		const response = await searchAgenciesApi.request(query, pageParam, PAGE_SIZE, agenciesOnly);
		return response.data;
	};

	const { activeItem, handleRequestMutate, handleConfirmMutate, setWarningModal, warningModal } =
		useMutate(profileApi.sendAgencyRequest, onClose, undefined, {
			meta: {
				successMessage: "Request Sent Successfully",
			},
		});

	const renderUser = ({ user: agency, ref }) => (
		<div ref={ref} key={agency.uuid} className={styles.agency_item}>
			<CompanyItem
				item={agency}
				subdiv={
					<>
						<div className={styles.agency_tagLine}>{agency.tagLine}</div>
						<div className={styles.agency_location}>
							{`${agency.locationDTO.name}, ${agency.locationDTO.countryDTO.name}`}
						</div>
					</>
				}
				onClick={() => {
					handleRequestMutate(agency);
				}}
			/>
		</div>
	);

	return (
		<>
			<UserListModal
				show={show && !warningModal}
				onClose={onClose}
				title="Link to Agency"
				fetchUsers={searchAgencies}
				renderUser={renderUser}
				hasSearch={true}
				queryKey={(query) => profileKeys.companySearch(query)}
			/>

			<GaawkModal
				show={warningModal}
				handleClose={() => setWarningModal(false)}
				defaultModal={false}
				showHeader={true}
				title={"Send Request"}
				closeAlign={"left"}
				children={
					<div className={styles.container}>
						<div className={styles.info_text}>
							Are you sure you want to send request to <b>{activeItem?.name}</b> for
							listing you on their talent profile? You will not be listed until they
							confirm.
						</div>

						<div className={styles.btn_container}>
							<PrimaryButton
								text="send"
								type="button"
								className={styles.btn}
								onClick={() =>
									handleConfirmMutate({
										agencyId: activeItem?.uuid,
										specialProfileId,
									})
								}
							/>
						</div>
					</div>
				}
			/>
		</>
	);
};

export default LinkToAgencyModal;
