import { createSlice } from "@reduxjs/toolkit";
import * as apiActions from "../actionCreators/api";

const slice = createSlice({
	name: "User",
	initialState: {
		userLogin: {},
		profileInfo: {},
		reqUrl: {},
		reqUser: {},
		// currentPositions: [],
		// searchUserList: [],
	},
	reducers: {
		// userLoginUpdated: (state, action) => {
		//     state.userLogin = action.payload;
		// },
		userLoginUpdated: (state, action) => {
			state.userLogin = { ...state.userLogin, ...action.payload };
		},
		resetUserLogin: (state, action) => {
			state.userLogin = {};
		},

		profileInfoUpdated: (state, action) => {
			state.profileInfo = action.payload;
		},

		bioUpdated: (state, action) => {
			state.profileInfo.bio = action.payload.bio;
		},

		languageUpdated: (state, action) => {
			state.profileInfo.languages = action.payload.languages;
		},

		softwareUpdated: (state, action) => {
			state.profileInfo.softwareList = action.payload.softwareList;
		},

		profileImageUpdated: (state, action) => {
			state.profileInfo.profileImage = action.payload.profileImage;
		},

		coverUpdated: (state, action) => {
			state.profileInfo.profileCover = action.payload.profileCover;
		},

		certificateUpdated: (state, action) => {
			state.profileInfo.certificates = action.payload.certificates;
		},

		updateCalendarState: (state) => {
			state.profileInfo.calendarEnabled = !state.profileInfo.calendarEnabled;
		},
		phoneUpdated: (state, action) => {
			state.profileInfo.contacts = action.payload.contacts;
		},
		siteUpdated: (state, action) => {
			state.profileInfo.sites = action.payload.sites;
		},
		socialUpdated: (state, action) => {
			state.profileInfo.socials = action.payload.socials;
		},
		addressUpdated: (state, action) => {
			state.profileInfo.addresses = action.payload.addresses;
		},
		otherUpdated: (state, action) => {
			state.profileInfo.others = action.payload.others;
		},
		reqUrl: (state, action) => {
			state.reqUrl = action.payload;
		},
		reqUser: (state, action) => {
			state.reqUser = action.payload;
		},
		resetReqUrl: (state) => {
			state.reqUrl = {};
		},
		resetReqUser: (state) => {
			state.reqUser = {};
		},
		roleUpdated: (state, action) => {
			state.profileInfo.roles = action.payload.roles;
		},
		skillUpdated: (state, action) => {
			state.profileInfo.skills = action.payload.skills;
		},
		skillDeleted: (state, action) => {
			const skillId = action.payload.passedData;
			state.profileInfo.skills = state.profileInfo.skills.filter(
				(skill) => skill.uuid !== skillId
			);
		},
		experienceUpdated: (state, action) => {
			state.profileInfo.experiences = action.payload.experiences;
		},

		educationUpdated: (state, action) => {
			state.profileInfo.educations = action.payload.educations;
		},

		updateSpecialProfileCounts: (state, action) => {
			state.profileInfo.specialProfileCounts = action.payload;
		},
	},
});

export const {
	userLoginUpdated,
	profileInfoUpdated,
	resetReqUrl,
	resetReqUser,
	phoneUpdated,
	otherUpdated,
	socialUpdated,
	experienceUpdated,
	languageUpdated,
	softwareUpdated,
	updateCalendarState,
	updateSpecialProfileCounts,
	resetUserLogin,
	educationUpdated,
	certificateUpdated,
	roleUpdated,
	skillUpdated,
	bioUpdated,
	profileImageUpdated,
	coverUpdated,
	addressUpdated,
	siteUpdated,
} = slice.actions;

export default slice.reducer;

/**
 * get the token from the server
 */
// export const userLogin = (login, password) =>
// 	apiActions.apiCallBegan({
// 		url: `/profile/user/login?login=${login}&password=${password}`,
// 		method: "POST",
// 		onSuccess: userLoginUpdated.type,
// 		skipAuth: true,
// 	});

/**
 * register user
 */

// export const registerUser = (data) =>
// 	apiActions.apiCallBegan({
// 		url: "/profile/user/register",
// 		method: "POST",
// 		data: data,
// 		onSuccess: userLoginUpdated.type,
// 	});

/**
 * get OTP
 */

// export const getOtp = (data) =>
//   apiActions.apiCallBegan({
//     url: `profile/user/sendOTP?email=${data.email}&mobile=${data.mobile}`,
//     method: "GET",
//     onSuccess: otpUpdated.type,
//   });

// export const verifyEmail = () =>
// 	apiActions.apiCallBegan({
// 		url: "/profile/user/login/email/verify",
// 		method: "GET",
// 		onSuccess: userLoginUpdated.type,
// 	});

// export const updateEmail = (email) =>
// 	apiActions.apiCallBegan({
// 		url: `/profile/user/login/email?email=${email}`,
// 		method: "PUT",
// 		onSuccess: userLoginUpdated.type,
// 	});

// export const verifyPhone = (countryCode, mobile) =>
// 	apiActions.apiCallBegan({
// 		url: `/profile/user/login/mobile?countryCode=${countryCode}&mobile=${mobile}`,
// 		method: "PUT",
// 		onSuccess: userLoginUpdated.type,
// 	});

/**
 * get user info
 */

export const getProfileInfo = (uuid) =>
	apiActions.apiCallBegan({
		url: `/profile/user/get?requestedUserId=${uuid}`,
		method: "GET",
		onSuccess: profileInfoUpdated.type,
	});

/**
 * Profile Image
 */
// export const updateProfileImage = (formData, imgData) => {
// 	const array = [];
// 	for (const [key, value] of Object.entries(imgData)) {
// 		array.push(`${key}=${value}`);
// 	}
// 	return apiActions.apiCallBegan({
// 		// header: uuid,
// 		url: `/profile/user/profileImage?${array.join("&")}`,
// 		method: "PUT",
// 		data: formData,
// 		onSuccess: profileImageUpdated.type,
// 		// showSuccessAlert: true,
// 		contentType: "multipart/form-data",
// 	});
// };

// export const deleteProfileImage = () =>
// 	apiActions.apiCallBegan({
// 		// headerId: uuid,
// 		url: "/profile/user/profileImage",
// 		method: "DELETE",
// 		// showSuccessAlert: true,
// 		onSuccess: profileImageUpdated.type,
// 	});

// export const makeTempPermanent = () =>
// 	apiActions.apiCallBegan({
// 		url: "/profile/user/profileImage/permTemp",
// 		method: "PATCH",
// 		// showSuccessAlert: true,
// 		onSuccess: profileImageUpdated.type,
// 	});

// export const removeTemp = () =>
// 	apiActions.apiCallBegan({
// 		url: "/profile/user/profileImage/removeTemp",
// 		method: "DELETE",
// 		// showSuccessAlert: true,
// 		onSuccess: profileImageUpdated.type,
// 	});

/**
 * Cover image
 */

// export const updateCoverImage = (formData, imgData) => {
// 	const array = [];
// 	for (const [key, value] of Object.entries(imgData)) {
// 		array.push(`${key}=${value}`);
// 	}
// 	return apiActions.apiCallBegan({
// 		// headerId: uuid,
// 		url: `/profile/user/coverImage?${array.join("&")}`,
// 		method: "PUT",
// 		data: formData,
// 		onSuccess: coverUpdated.type,
// 		contentType: "multipart/form-data",
// 		// showSuccessAlert: true,
// 	});
// };
// export const deleteCoverImage = () =>
// 	apiActions.apiCallBegan({
// 		// headerId: uuid,
// 		url: "/profile/user/coverImage",
// 		method: "DELETE",
// 		onSuccess: coverUpdated.type,
// 		// showSuccessAlert: true,
// 	});

/**
 * Courses & Certifications
 */

// export const addCertificate = (body) =>
// 	apiActions.apiCallBegan({
// 		url: "/profile/user/certificate",
// 		method: "POST",
// 		data: body,
// 		onSuccess: certificateUpdated.type,
// 		// showSuccessAlert: true,
// 	});

// export const updateCertificate = (body) =>
// 	apiActions.apiCallBegan({
// 		url: "/profile/user/certificate",
// 		method: "PUT",
// 		data: body,
// 		onSuccess: certificateUpdated.type,
// 		// showSuccessAlert: true,
// 		// headerId: uuid,
// 		// data: formData,
// 		// contentType: "multipart/form-data",
// 	});

// export const deleteCertificate = (certificationId) =>
// 	apiActions.apiCallBegan({
// 		// headerId: uuid,
// 		url: `/profile/user/certificate?certificationId=${certificationId}`,
// 		method: "DELETE",
// 		passedData: certificationId,
// 		onSuccess: certificateDeleted.type,
// 		// showSuccessAlert: true,
// 	});

// export const addCertificateFiles = (formData) =>
// 	apiActions.apiCallBegan({
// 		url: "/profile/user/certificate/file",
// 		method: "POST",
// 		data: formData,
// 		onSuccess: certificateUpdated.type,
// 		contentType: "multipart/form-data",
// 		// showSuccessAlert: true,
// 	});

/**
 * Get other users profile
 */

// export const getUrlProfile = (url) =>
// 	apiActions.apiCallBegan({
// 		url: `/profile/url?url=${url}`,
// 		method: "GET",
// 		onSuccess: reqUrl.type,
// 		onError: reqUrl.type,
// 	});

// export const getUserProfile = (id) =>
// 	apiActions.apiCallBegan({
// 		url: `/profile/user/get?requestedUserId=${id}`,
// 		method: "GET",
// 		onSuccess: reqUser.type,
// 		onError: reqUser.type,
// 	});

/**
 * Get company profile
 */

// export const getCompanyProfile = (companyId) =>
// 	apiActions.apiCallBegan({
// 		url: `/profile/company/get?profileId=${companyId}`,
// 		method: "GET",
// 		onSuccess: reqUser.type,
// 		onError: reqUser.type,
// 	});

/**
 * Education
 */

// export const addEducation = (body) =>
// 	apiActions.apiCallBegan({
// 		url: "/profile/user/education",
// 		method: "POST",
// 		data: body,
// 		onSuccess: educationUpdated.type,
// 		// showSuccessAlert: true,
// 	});

// export const editEducation = (body) =>
// 	apiActions.apiCallBegan({
// 		url: "/profile/user/education",
// 		method: "PUT",
// 		data: body,
// 		onSuccess: educationUpdated.type,
// 		// showSuccessAlert: true,
// 	});

// export const addEducationFiles = (formData) =>
// 	apiActions.apiCallBegan({
// 		url: "/profile/user/education/file",
// 		method: "POST",
// 		data: formData,
// 		onSuccess: educationUpdated.type,
// 		contentType: "multipart/form-data",
// 		// showSuccessAlert: true,
// 	});

// export const deleteEducation = (educationId) =>
// 	apiActions.apiCallBegan({
// 		url: `/profile/user/education?educationId=${educationId}`,
// 		method: "DELETE",
// 		passedData: educationId,
// 		onSuccess: educationDeleted.type,
// 		// showSuccessAlert: true,
// 	});

// SEARCH USERS
// export const searchUser = (query) =>
// 	apiActions.apiCallBegan({
// 		url: `/profile/user/search?q=${query}`,
// 		method: "GET",
// 		onSuccess: searchUserUpdated.type,
// 	});
