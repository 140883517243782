import { forwardRef } from "react";
import styles from "./button.module.css";
import LoadingSpinner from "../SubComs/LoadingSpinner/LoadingSpinner";

const SecondaryButton = forwardRef(
	({ text, onClick, className, type = "button", disabled = false, isLoading = false }, ref) => {
		return (
			<button
				ref={ref}
				className={`${className} ${styles.secondary} ${
					disabled || isLoading ? styles.disabled : undefined
				}`}
				disabled={disabled || isLoading}
				onClick={onClick}
				type={type}
			>
				{isLoading ? (
					<LoadingSpinner
						visible={isLoading}
						color={"#FFFFFF"}
						size={20}
						customStyle={styles.custom_loader}
					/>
				) : (
					text
				)}
			</button>
		);
	}
);

export default SecondaryButton;
