import { useNavigate, useLocation, useParams } from "react-router-dom";
import BackButton from "../../components/Utils/Button/BackButton";
import DefaultPage from "../../components/Utils/PageArchs/DefaultPage/DefaultPage";
import Album from "../../components/Profile/GalleryTab/Album";
import AlbumEntry from "../../components/Profile/GalleryTab/AlbumEntry";
import styles from "../Header.module.css";
import HomeLeftSidebar from "components/Home/Components/HomeLeftSidebar/HomeLeftSidebar";

const AlbumView = () => {
	const navigate = useNavigate();
	const { pathname } = useLocation();
	const { albumId } = useParams();

	let component;
	let headerTitle;

	switch (pathname) {
		case `/profile/album/${albumId}`:
			component = <Album />;
			headerTitle = "Album";
			break;

		case "/profile/album/add":
			component = <AlbumEntry />;
			headerTitle = "Create Album";
			break;

		case `/profile/album/${albumId}/edit`:
			component = <AlbumEntry editMode />;
			headerTitle = "Edit Album";
			break;

		default:
			break;
	}
	return (
		<DefaultPage
			headerRightContent={
				<div className={styles.header}>
					<BackButton onBack={() => navigate(-1)} />
					{headerTitle}
				</div>
			}
			defaultHeader={false}
			leftSideChildren={<HomeLeftSidebar />}
			rightSideChildren={component}
			rightSideBgColor={"#FFF"}
		/>
	);
};

export default AlbumView;
