import PostLoader from "components/Utils/SubComs/CustomLoader/PostLoader";
import NoResults from "components/Utils/SubComs/NoResults/NoResults";
import SeeMore from "components/Utils/SubComs/SeeMore/SeeMore";
import useCurrentUser from "hooks/useCurrentUser";
import filterIcon from "images/filter-icon-white.svg";
import { useMemo, useRef, useState } from "react";
import postApi from "../../../api/post";
import useMutate from "../../../hooks/useMutate";
import usePostViewer from "../../../hooks/usePostViewer";
import CreatePost from "../../Home/CreatePost";
import EditPostModal from "../../Home/EditPostModal";
import PostFullScreen from "../../Home/PostFullScreen";
import PostItem from "../../Home/PostItem";
import FullScreenModal from "../../Utils/GaawkModal/FullScreenModal";
import { pluralize } from "../../Utils/General";
import LoadingSpinner from "../../Utils/SubComs/LoadingSpinner/LoadingSpinner";
import styles from "./Posts.module.scss";
import useGetQueryParam from "hooks/useGetQueryParam";
import useGetPostById from "hooks/useGetPostById";

const Posts = ({
	isFetchingNextPage,
	viewRef,
	count,
	data,
	isPaginated = false,
	isLoading,
	isMobile,
	onShowModal,
	onRefresh,
	onClick,
	tagsList,
}) => {
	const profile = useCurrentUser();

	const { type, url, profileImage, firstName, lastName, name } = profile || {};

	// !========== POST CLICK HANDLER ==============

	const { postModal, postId, subPostId, handleItemClicked, handleCloseModal, subPostIndex } =
		usePostViewer();

	// ! ============ DELETE MEDIA ============

	const {
		action: { mutate: deletePost },
	} = useMutate(postApi.deletePost, onRefresh);

	// ! ============ EDIT HANDLER ============

	const [showModal, setShowModal] = useState(false);
	const [postIdToEdit, setPostIdToEdit] = useState("");
	const { data: post } = useGetPostById(postIdToEdit);

	// ! ============ POST SHARE ============

	const postRef = useRef();

	const handleShare = (post) => {
		if (post.sharedPost) {
			postRef.current.sharePost(post.sharedPost);
		} else {
			postRef.current.sharePost(post);
		}
	};

	// !=======================================

	const itemsList = useMemo(
		() =>
			data?.map((post, index) => {
				const isLastItem = index === data.length - 1;
				return (
					<PostItem
						key={post.uuid}
						ref={isLastItem && isPaginated ? viewRef : null}
						post={post}
						canEditBoard={post.board?.moderator}
						isPinned={post.pinned}
						onItemClicked={handleItemClicked(post)}
						onDelete={() => deletePost(post.uuid)}
						onPostUpdate={onRefresh}
						// onEdit={(post) => setPostId(post.uuid)}
						onEdit={() => {
							setShowModal(true);
							setPostIdToEdit(post.uuid);
						}}
						onShare={handleShare}
					/>
				);
			}),
		[data]
	);

	const { paramValue: inputValue } = useGetQueryParam("q");

	const handleReplaceUrl = () => {
		window.history.replaceState(null, "", `/search?q=${inputValue}`);
	};

	if (isLoading) return <LoadingSpinner />;

	if (!isLoading && count === 0)
		return <NoResults visible={true} isSearch={true} text={"No results"} />;

	return (
		<>
			<div className={styles.title_wrapper}>
				<h4>{`${count} ${pluralize(count, "Post")} found`}</h4>
				{isMobile && isPaginated && (
					<button onClick={onShowModal}>
						<img src={filterIcon} alt="" />
					</button>
				)}
			</div>

			{tagsList?.length > 0 && <div className={styles.active_filters}>{tagsList}</div>}

			<div className={styles.container}>{itemsList}</div>

			{isFetchingNextPage && <PostLoader />}

			{!isPaginated && (
				<div className={styles.button_container}>
					<SeeMore onClick={onClick} text="See more" />
				</div>
			)}

			<CreatePost
				ref={postRef}
				name={type === "USER" ? `${firstName} ${lastName}` : name}
				profileImg={profileImage}
				type={type}
				onCreate={onRefresh}
			/>

			<EditPostModal
				existingPost={post}
				name={type === "USER" ? `${firstName} ${lastName}` : name}
				profileImg={profileImage}
				type={type}
				url={url}
				show={showModal}
				toggleShow={(bool) => setShowModal(bool)}
				onInvalidate={onRefresh}
				onClose={() => setPostIdToEdit("")}
			/>

			<FullScreenModal
				show={postModal}
				onClose={() => {
					handleCloseModal();
					handleReplaceUrl();
				}}
				fullWidth={true}
				children={
					<PostFullScreen
						postId={postId}
						subPostId={subPostId}
						initialSlideIndex={subPostIndex}
						isModal={true}
						onClose={handleCloseModal}
						onRefresh={onRefresh}
						onUpdateUrl={handleReplaceUrl}
					/>
				}
			/>
		</>
	);
};

export default Posts;
