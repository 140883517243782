import { useEffect, useState } from "react";
import CustomOTPInput from "../Utils/SubComs/CustomOTPInput/CustomOTPInput";
import styles from "./PhoneOTP.module.scss";
import PrimaryButton from "../Utils/Button/PrimaryButton";
import profileApi from "../../api/profile";
import useMutate from "../../hooks/useMutate";
import { toast } from "react-toastify";

const PhoneOTP = ({ countryCode, mobile, otpToken, onResendOtp, onSuccess }) => {
	const [otp, setOtp] = useState("");

	const [timer, setTimer] = useState(40);

	useEffect(() => {
		timer > 0 && setTimeout(() => setTimer((prevState) => prevState - 1), 1000);
	}, [timer]);

	const handleResendOtp = () => {
		setTimer(40);
		onResendOtp();
	};

	const {
		action: { mutate: updatePhone },
	} = useMutate(profileApi.updatePhone, onSuccess);

	const handleConfirm = () => {
		if (otp === otpToken) {
			updatePhone({
				countryCode: countryCode.slice(1).replace(" ", ""),
				mobile,
			});
		} else {
			toast.error("OTP don't match!");
		}
	};

	return (
		<div>
			{`We have sent an SMS message with a code to ${countryCode}${mobile}. Please enter the 6 digit code from the SMS below`}

			<h4>
				Code<span className="required">*</span>
			</h4>
			<div className={styles.otp_wrapper}>
				<CustomOTPInput value={otp} onChange={setOtp} />
			</div>

			<div className={styles.button_container}>
				<PrimaryButton text={"confirm"} onClick={handleConfirm} />
				<PrimaryButton
					disabled={timer > 0}
					text={timer > 0 ? `Resend Code in ${timer} seconds` : "Resend Code"}
					onClick={handleResendOtp}
				/>
			</div>
		</div>
	);
};

export default PhoneOTP;
