import { Link, useNavigate } from "react-router-dom";
import styles from "./BoardItem.module.scss";

import { formatCount } from "components/Utils/General";
import ProfilePic from "components/Home/ProfilePic";
import { classNames } from "primereact/utils";
import lockIcon from "images/lock.svg";
import publicIcon from "images/public-grey-100.svg";
import routes from "components/Routing/routing-keys";

const BoardItem = ({
	data,
	className,
	isSidebar,
	detailView = false,
	onShowFollowers,
	onShowModerators,
}) => {
	const {
		followersCount,
		moderatorsCount,
		uuid,
		owner: { name, type, url, profileImage: { image } = {} } = {},
		privateBoard,
		gaawkBoard,
	} = data || {};

	const navigate = useNavigate();

	return (
		<Link
			// className={`${styles.container} ${className}`}
			className={classNames(styles.container, className, {
				[styles.disabled]: detailView,
			})}
			to={`/boards/${uuid}`}
			onClick={(e) => detailView && e.preventDefault()}
		>
			<ProfilePic
				postType={"BOARD"}
				w={isSidebar ? 50 : 96}
				h={isSidebar ? 50 : 96}
				thumbnail={data?.feedImage?.file?.customName}
				isGaawkBoard={gaawkBoard}
				noMarginRight={true}
			/>

			<div
				className={classNames(styles.board_details, {
					[styles.sidebar]: isSidebar,
				})}
			>
				<h4>{data?.name}</h4>

				<div className={styles.counter_wrapper}>
					<img
						src={privateBoard ? lockIcon : publicIcon}
						className={styles.privacy_icon}
						alt="privacy"
					/>

					<button
						onClick={() => {
							detailView && onShowFollowers && onShowFollowers();
						}}
						className={classNames(styles.counter_item, {
							[styles.sideBar]: isSidebar,
						})}
					>
						<p>{formatCount(followersCount)} Followers</p>
					</button>
					<button
						onClick={() => {
							detailView && onShowModerators && onShowModerators();
						}}
						className={classNames(styles.counter_item, {
							[styles.sideBar]: isSidebar,
						})}
					>
						<p>{formatCount(moderatorsCount)} Moderators</p>
					</button>
				</div>

				<button
					onClick={() => detailView && navigate(routes.profile(url))}
					className={styles.owner_wrapper}
				>
					<ProfilePic
						thumbnail={image}
						type={type}
						w={17}
						h={17}
						noMarginRight={true}
						enableName={false}
						name={name}
						letterSize={10}
					/>
					<span className={styles.author_span}>Author:</span> <p>{name}</p>
				</button>
			</div>
		</Link>
	);
};

export default BoardItem;
