import { joiResolver } from "@hookform/resolvers/joi";
import { useQueryClient } from "@tanstack/react-query";
import routes from "components/Routing/routing-keys";
import PromptModal from "components/Utils/Prompt/PromptModal";
import MultiSelectInput from "components/Utils/SubComs/Inputs/MultiSelectInput/MultiSelectInput";
import TextArea from "components/Utils/SubComs/Inputs/TextArea/TextArea";
import useMutate from "hooks/useMutate";
import Joi from "joi";
import { skillsKeys } from "queryKeys/skills-key-factory";
import { specialProfileKeys } from "queryKeys/specialProfile-key-factory";
import { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import skillsApi from "../../../../../../api/skills";
import specialProfileApi from "../../../../../../api/special-profile";
import useApi from "../../../../../../hooks/useApi";
import PrimaryButton from "../../../../../Utils/Button/PrimaryButton";
import { defaultFormatter } from "../../../../../Utils/SubComs/Inputs/SearchableInput/response-formatter";
import styles from "./AdditionalDetailsEntry.module.css";

const itemsPerPage = 20;

const AdditionalDetailsEntry = ({ profile }) => {
	const navigate = useNavigate();
	const { state } = useLocation();

	const searchSkillsApi = useApi(skillsApi.searchSkills, true, true);

	const queryClient = useQueryClient();

	const fetchSkills = async ({ pageParam = 0, signal, queryKey }) => {
		const [_, searchInput] = queryKey;

		const response = await searchSkillsApi.request(pageParam, itemsPerPage, searchInput);
		return response.data;
	};

	const { userUrl } = useParams();
	const [shouldBlockSave, setShouldBlockSave] = useState(true);

	const schema = Joi.object({
		info: Joi.string().allow("").label("Additional Info"),
		skills: Joi.array().min(0).label("Skills"),
	});

	const {
		// register,
		setValue,
		formState: { isDirty },
		watch,
		handleSubmit,
		control,
	} = useForm({
		resolver: joiResolver(schema),
		mode: "onSubmit",
		defaultValues: {
			info: profile.info ?? "",
			skills: profile.skills.map((skill) => ({
				label: skill.name,
				value: skill.uuid,
			})),
		},
	});

	const skillsWatcher = watch("skills");

	const handleSelectSkill = (skill) => {
		setValue("skills", [...skillsWatcher, skill], { shouldDirty: true });
	};

	const handleRemoveSkill = (skillIndex) => {
		const updatedSkills = [...skillsWatcher].filter((_, index) => index !== skillIndex);
		setValue("skills", updatedSkills);
	};

	const {
		action: { mutate: updateDetails, isLoading },
	} = useMutate(
		specialProfileApi.updateAdditionalDetails,
		() => {
			queryClient.invalidateQueries(specialProfileKeys.detail(userUrl));
			navigate(
				routes.specialProfile(userUrl, state?.agencyId ? `?agency=${state?.agencyId}` : "")
			);
		},
		() => setShouldBlockSave(true)
	);

	const handleSave = (data) => {
		setShouldBlockSave(false);

		const { skills, info } = data;

		const body = {
			info,
			skillIds: skills.map((skill) => skill.value),
		};

		updateDetails({ uuid: profile.uuid, body });
	};

	return (
		<form
			className={styles.additional_form}
			onSubmit={handleSubmit((data) => handleSave(data))}
			noValidate
		>
			<h3>Additional Details</h3>
			<div className={styles.section}>
				<MultiSelectInput
					creatable={true}
					queryName={skillsKeys.search}
					queryFn={fetchSkills}
					data={skillsWatcher}
					itemsPerPage={itemsPerPage}
					formatter={defaultFormatter}
					label="Skills"
					// limit={skillsLimit}
					onChange={handleSelectSkill}
					onRemoveItem={handleRemoveSkill}
					// infoText={`You can add up to ${skillsLimit} skills which outline your core
					// skill sets.`}
				/>
			</div>

			<Controller
				name={"info"}
				control={control}
				render={({ field }) => <TextArea label="Additional Info" {...field} />}
			/>

			<div className={styles.button_container}>
				<PrimaryButton className={styles.save_btn} text={"save"} isLoading={isLoading} />
			</div>

			<PromptModal when={isDirty && shouldBlockSave} />
		</form>
	);
};

export default AdditionalDetailsEntry;
