import { joiResolver } from "@hookform/resolvers/joi";
import { useQueryClient } from "@tanstack/react-query";
import routes from "components/Routing/routing-keys";
import GaawkModal from "components/Utils/GaawkModal/GaawkModal";
import PromptModal from "components/Utils/Prompt/PromptModal";
import GaawkRadioButton from "components/Utils/SubComs/GaawkRadioButton/GaawkRadioButton";
import FileInput from "components/Utils/SubComs/Inputs/FileInput/FileInput";
import { differenceInYears } from "date-fns";
import useCurrentUser from "hooks/useCurrentUser";
import useMutate from "hooks/useMutate";
import useUrlPreview from "hooks/useUrlPreview";
import { specialProfileKeys } from "queryKeys/specialProfile-key-factory";
import { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { updateSpecialProfileCounts } from "store/slices/user";
import specialProfile from "../../../../../../api/special-profile";
import PrimaryButton from "../../../../../Utils/Button/PrimaryButton";
import CustomSelect from "../../../../../Utils/SubComs/CustomSelect/CustomSelect";
import TextInput from "../../../../../Utils/SubComs/Inputs/TextInput/TextInput";
import UrlCheckInput from "../../../../../Utils/SubComs/Inputs/UrlCheck/UrlCheckInput";
import { specialProfileTypes } from "../../special-types";
import specialProfileSchema from "./special-profile-schema";
import styles from "./SpecialBaseEntry.module.css";

const SpecialBaseEntry = ({ editMode = false, profile = "" }) => {
	const {
		name,
		url: currentUrl,
		type,
		profilePic: { file } = {},
		uuid: specialProfileId,
		privacy,
	} = profile;

	const [tempImage, setTempImage] = useState([]);

	const { dob, gender, customGender, specialProfileCounts } = useCurrentUser();

	const dispatch = useDispatch();
	const queryClient = useQueryClient();
	const { userUrl } = useParams();

	// const fileInputRef = useRef(null);

	const navigate = useNavigate();
	const { state } = useLocation();

	const [shouldBlockSave, setShouldBlockSave] = useState(true);

	// const [imageName, setImageName] = useState("");

	const {
		register,
		formState: { errors, isDirty, dirtyFields },
		handleSubmit,
		control,
		watch,
		setValue,
		setError,
		clearErrors,
	} = useForm({
		resolver: joiResolver(specialProfileSchema),
		mode: "all",
		defaultValues: editMode
			? {
					name: name,
					url: currentUrl,
					urlValidity: true,
					file: [file],
					type: specialProfileTypes.find((profileType) => profileType.value === type),
					privacy,
			  }
			: {
					name: "",
					url: "",
					urlValidity: undefined,
					file: [],
					type: null,
					privacy: "public",
			  },
	});

	const urlWatcher = watch("url");
	const fileWatcher = watch("file");
	const privacyWatcher = watch("privacy");
	const urlValidityWatcher = watch("urlValidity");

	const [warningModal, setWarningModal] = useState(false);
	const [warningTitle, setWarningTitle] = useState("");
	const [warningText, setWarningText] = useState("");

	const didMountRef = useRef(false);

	useEffect(() => {
		if (didMountRef.current) {
			if (privacyWatcher === "AGENCY" && profile.agencies?.length === 0) {
				setWarningModal(true);
				setWarningTitle("Not linked with any agency");
				setWarningText(
					"You are not linked with any agencies. If you choose this visibility, your profile will not be visible anywhere. Either link with an agency or choose some other visibility. You can link with agency in the last section of your special profile page."
				);
			}
			if (privacyWatcher === "PRIVATE") {
				setWarningModal(true);
				setWarningTitle("Dismissing pending links");
				setWarningText(
					"All pending requests with agencies will be dismissed if you change your privacy to private."
				);
			}
		}
		didMountRef.current = true;
	}, [privacyWatcher, profile.agencies?.length]);

	const { url: isUrlDirty } = dirtyFields;

	useEffect(() => {
		if (fileWatcher.length > 0) {
			clearErrors("file");
		}
	}, [fileWatcher]);

	const {
		action: { mutate: addUpdateDetails, isLoading },
	} = useMutate(
		editMode ? specialProfile.updateSpecialProfile : specialProfile.addSpecialProfile,
		() => {
			editMode && isUrlDirty && queryClient.removeQueries(specialProfileKeys.detail(userUrl));

			editMode &&
				!isUrlDirty &&
				queryClient.invalidateQueries(specialProfileKeys.detail(urlWatcher));

			dispatch(updateSpecialProfileCounts(specialProfileCounts + 1));
			navigate(
				routes.specialProfile(
					urlWatcher,
					state?.agencyId ? `?agency=${state?.agencyId}` : ""
				),
				{ replace: true }
			);
		},
		() => setShouldBlockSave(true)
	);

	const handleSave = (data) => {
		setShouldBlockSave(false);
		const {
			name,
			type: { value: type },
			url,
			file: profilePicFile,
			privacy,
		} = data;

		const formData = new FormData();
		formData.append("url", url);
		formData.append("name", name);
		formData.append("type", type);
		formData.append("privacy", privacy);

		if (profilePicFile[0] instanceof Blob)
			formData.append("profilePicFile", profilePicFile[0], profilePicFile[0].fileName);

		if (editMode) {
			formData.append("specialProfileId", specialProfileId);
		}

		addUpdateDetails(formData);
	};

	// !----- revoking image preview -----

	const { objectUrls, setObjectUrls, cleanupObjectUrls } = useUrlPreview();

	useEffect(() => {
		// Create object URLs when postMedia changes

		if (
			fileWatcher?.[0] &&
			fileWatcher[0] instanceof Blob &&
			!(fileWatcher[0] instanceof File)
		) {
			setObjectUrls([URL.createObjectURL(fileWatcher[0])]);
		}

		// // Cleanup function
		return () => {
			cleanupObjectUrls();
		};
	}, [fileWatcher]);

	// !----------------------------------

	return (
		<>
			<div className={styles.wrapper}>
				<form onSubmit={handleSubmit((data) => handleSave(data))} noValidate>
					<div className={styles.container}>
						<div className={styles.form_input_wrapper}>
							<label>
								Special Profile Name
								<span className="required">*</span>
							</label>
							<TextInput
								error={!!errors.name}
								{...register("name")}
								placeholder="Enter your Special Profile Name"
							/>

							{errors?.name?.message && (
								<p className={styles.error_message}>{errors?.name?.message}</p>
							)}
						</div>

						<UrlCheckInput
							label={"Special Profile URL"}
							placeholder={"Enter your Special Profile URL"}
							control={control}
							name={"url"}
							errors={errors}
							urlValidityWatcher={urlValidityWatcher}
							urlWatcher={urlWatcher}
							url={currentUrl}
							onSetValue={setValue}
							onSetError={setError}
							onClearErrors={clearErrors}
							urlPrefix={"sp/"}
						/>

						<div className={styles.react_select_wrapper}>
							<label>
								Special Profile Type
								<span className="required">*</span>
							</label>
							<CustomSelect
								options={specialProfileTypes}
								placeholder="Select"
								height="35px"
								disabled={editMode}
								control={control}
								error={!!errors.type}
								name="type"
							/>
							{errors?.type?.message && (
								<p className={styles.error_message}>{errors?.type?.message}</p>
							)}
						</div>

						<div className={styles.custom_upload_wrapper}>
							<label>
								Special Profile Picture
								<span className="required">*</span>
							</label>

							{fileWatcher.length > 0 &&
								((fileWatcher[0] instanceof Blob &&
									!(fileWatcher[0] instanceof File)) ||
									fileWatcher?.[0].url) && (
									<div className={styles.thumbs_container}>
										<div className={styles.thumb}>
											<div className={styles.thumb_inner}>
												<img
													src={
														fileWatcher[0] instanceof Blob &&
														!(fileWatcher[0] instanceof File)
															? objectUrls[0]
															: fileWatcher[0].url
													}
													alt=""
												/>
											</div>
										</div>
									</div>
								)}

							<span className={styles.info_text}>
								You can upload image files only.
							</span>

							<FileInput
								control={control}
								error={errors.file}
								name="file"
								required={true}
								// loadedFile={fileWatcher}
								loadedFile={tempImage}
								onCrop={(cropped) => {
									setValue("file", [cropped]);
								}}
								onChange={(value) => {
									// setValue("file", [value.target.files[0]], {
									// 	shouldDirty: true,
									// });
									setTempImage([value.target.files[0]]);
								}}
								showThumbails={false}
							/>
							{/* <div className={styles.img_option}>
								<button
									type="button"
									className={
										errors?.file ? styles.error : undefined
									}
									onClick={() => {
										fileInputRef.current.click();
									}}
								>
									<Controller
										name="file"
										control={control}
										render={() => (
											<input
												ref={fileInputRef}
												type="file"
												accept="image/png, image/jpg, image/jpeg"
												onClick={(e) =>
													e.stopPropagation()
												}
												onChange={(val) => {
													val.target.files[0] &&
														setValue(
															"file",
															[
																val.target
																	.files[0],
															],
															{
																shouldDirty: true,
															}
														);
													setImageName(
														val.target.files[0].name
													);
													setCropModal(true);
													val.target.value = "";
												}}
												tabIndex="-1"
											/>
										)}
									/>
									{editMode ? "REPLACE IMAGE" : "+ ADD IMAGE"}
								</button>
							</div> */}

							{errors?.file?.message && (
								<p className={styles.error_message}>{errors?.file?.message}</p>
							)}
						</div>
					</div>

					<div className={styles.separator} />

					<div className={styles.container}>
						<div className={styles.form_input_wrapper}>
							<label>Gender</label>
							<TextInput
								value={
									customGender
										? customGender[0].toUpperCase() +
										  customGender.slice(1).toLowerCase()
										: gender[0].toUpperCase() + gender.slice(1).toLowerCase()
								}
								disabled
							/>
						</div>

						<div className={styles.form_input_wrapper}>
							<label>Age</label>
							<TextInput
								value={differenceInYears(new Date(), new Date(dob))}
								disabled
							/>
						</div>

						<span className={styles.info_text}>
							Gender and age will be extracted from your profile. If you wish to
							change them,{" "}
							<span
								className={styles.link}
								onClick={() => navigate(routes.editProfile)}
							>
								edit
							</span>{" "}
							your main profile.
						</span>
					</div>

					<div className={styles.separator} />

					<div className={styles.container}>
						<h4 className={styles.label_title}>Privacy of Special Profile</h4>

						<GaawkRadioButton
							{...register("privacy", { required: true })}
							label={
								<>
									<h4 className={styles.label_title}>Public Profile</h4>
									<p className={styles.label_text}>
										Everyone can access your special profile from everywhere
									</p>
								</>
							}
							id="public"
							value="PUBLIC"
							name="privacy"
							customStyle={styles.radio_item}
						/>

						<GaawkRadioButton
							{...register("privacy", { required: true })}
							label={
								<>
									<h4 className={styles.label_title}>Only via Agencies</h4>
									<p className={styles.label_text}>
										People only find you through agencies you are linked with.
									</p>
								</>
							}
							id="agency"
							value="AGENCY"
							name="privacy"
							customStyle={styles.radio_item}
						/>

						<GaawkRadioButton
							{...register("privacy", { required: true })}
							label={
								<>
									<h4 className={styles.label_title}>Private Profile</h4>
									<p className={styles.label_text}>
										No one can access your special profile.
									</p>
								</>
							}
							id="private"
							value="PRIVATE"
							name="privacy"
							customStyle={styles.radio_item}
						/>

						<div className={styles.button_container}>
							<PrimaryButton
								className={styles.save_btn}
								text={"save"}
								isLoading={isLoading}
							/>
						</div>
					</div>
				</form>
			</div>

			<GaawkModal
				show={warningModal}
				handleClose={() => setWarningModal(false)}
				defaultModal={false}
				showHeader={true}
				title={warningTitle}
				closeAlign={"right"}
				children={
					<div className={styles.modal_container}>
						<p>{warningText}</p>
						<div className={styles.button_container}>
							<PrimaryButton
								onClick={() => setWarningModal(false)}
								className={styles.save_btn}
								text={"ok"}
							/>
						</div>
					</div>
				}
			/>

			<PromptModal when={isDirty && shouldBlockSave} />
		</>
	);
};

export default SpecialBaseEntry;
