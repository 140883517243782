import useInfiniteScroll from "hooks/useInfiniteScroll";
import backArrow from "images/back-arrow-black.svg";
import folderIcon from "images/icon-awesome-folder-open.svg";
import vaultIcon from "images/vault-icon.svg";
import { classNames } from "primereact/utils";
import { vaultKeys } from "queryKeys/vault-key-factory";
import { Fragment, useEffect, useState } from "react";
import { List } from "react-content-loader";
import vaultApi from "../../api/vault";
import useApi from "../../hooks/useApi";
import PrimaryButton from "../Utils/Button/PrimaryButton";
import SecondaryButton from "../Utils/Button/SecondaryButton";
import GaawkModal from "../Utils/GaawkModal/GaawkModal";
import styles from "./VaultListItemsModal.module.css";

const PAGE_SIZE = 10;

const LoadingView = () => {
	return (
		<div className={styles.loadingContainer}>
			<List />
		</div>
	);
};

function VaultListItemsModal({
	visible,
	onClose,
	// onReset,
	onConfirm,
	title,
	buttonTitle,
	isMove = false,
	selectedItems,
}) {
	/**
	 * Hooks
	 */

	/**
	 * States
	 */

	const [selectedFolder, setSelectedFolder] = useState(undefined);
	const [previousFolders, setPreviousFolders] = useState([]);
	const [disableButton, setDisableButton] = useState(false);

	const getFoldersApi = useApi(vaultApi.getFolders, true, true);

	const fetchFolders = async ({ pageParam = 0, queryKey }) => {
		const [_, __, parentId] = queryKey;
		const response = await getFoldersApi.request(pageParam, PAGE_SIZE, parentId);
		return response.data;
	};

	const { items, viewRef, isFetchingNextPage, isLoading } = useInfiniteScroll({
		queryKey: vaultKeys.folders(selectedFolder?.uuid ?? ""),
		queryFn: fetchFolders,
		pageSize: PAGE_SIZE,
	});

	const foldersList = items.map((folder, index) => {
		const isLastItem = index === items.length - 1;
		const isDisabled = selectedItems.map(({ uuid }) => uuid).includes(folder.uuid);

		return (
			<Fragment key={folder.uuid}>
				<div
					ref={isLastItem ? viewRef : null}
					className={classNames(styles.folder_item, {
						[styles.disabled]: isDisabled,
					})}
					onClick={() => !isDisabled && handleSelectedFolder(folder)}
				>
					<img src={folderIcon} alt="Folder" />
					<span>{folder.name}</span>
				</div>

				{isLastItem && isFetchingNextPage && <LoadingView />}
			</Fragment>
		);
	});

	/**
	 * effects
	 */

	useEffect(() => {
		if (isMove) {
			if (selectedItems[0].parentId) {
				// HAVE PARENT ID (NESTED)
				if (selectedFolder) {
					selectedItems[0].parentId === selectedFolder.uuid
						? setDisableButton(true)
						: setDisableButton(false);
				} else {
					setDisableButton(false);
				}
			} else {
				// DON'T HAVE PARENT ID (ROOT)
				if (selectedFolder) {
					setDisableButton(false);
				} else {
					setDisableButton(true);
				}
			}
		}
	}, [isMove, selectedFolder, selectedItems]);

	const handleSelectedFolder = (folder) => {
		setSelectedFolder(folder);
		setPreviousFolders((prevState) => [...prevState, folder]);
	};

	const handleGoBackFolder = () => {
		const targetParentId = previousFolders[previousFolders.length - 1].parentId;

		if (targetParentId) {
			setSelectedFolder(previousFolders.find((folder) => folder.uuid === targetParentId));
		} else {
			setSelectedFolder(undefined);
		}

		setPreviousFolders((prevState) => prevState?.slice(0, prevState.length - 1)); //* to remove last item
	};

	const handleConfirm = () => {
		onConfirm(selectedFolder);
		onClose();
		// onReset();
	};

	/**
	 * JSX
	 */

	const EmptyView = () => <span className={styles.info_text}>You don't have any folders</span>;

	return (
		<GaawkModal
			show={visible}
			handleClose={onClose}
			defaultModal={false}
			showHeader={true}
			title={title}
			closeAlign={"right"}
			children={
				<div className={styles.modal_container}>
					<div className={styles.movecopy_body}>
						<h3>Select destination folder</h3>
						<div className={styles.movecopy_folder_container}>
							<div className={styles.current_folder}>
								{selectedFolder ? (
									<>
										<img
											src={backArrow}
											onClick={handleGoBackFolder}
											alt="vault"
										/>
										<span>{selectedFolder.name}</span>
									</>
								) : (
									<>
										<img src={vaultIcon} alt="vault" />
										<span>Your Vault</span>
									</>
								)}
							</div>
							<div className={styles.separator} />
							<div className={styles.folders_list}>
								{isLoading && <LoadingView />}
								{items.length > 0 && !isLoading && foldersList}
								{items.length === 0 && !isLoading && <EmptyView />}
							</div>
						</div>
					</div>

					<div className={styles.movecopy_modal_buttons}>
						<SecondaryButton
							className={styles.cancel_button}
							text={"CANCEL"}
							onClick={onClose}
						/>

						<PrimaryButton
							className={styles.movecopy_button}
							text={buttonTitle}
							disabled={disableButton}
							onClick={handleConfirm}
						/>
					</div>
				</div>
			}
		/>
	);
}

export default VaultListItemsModal;
