import HomeLeftSidebar from "components/Home/Components/HomeLeftSidebar/HomeLeftSidebar";
import { useNavigate } from "react-router-dom";
import EditCalendar from "../../components/Profile/WorkStatusTab/EditCalendar";
import BackButton from "../../components/Utils/Button/BackButton";
import DefaultPage from "../../components/Utils/PageArchs/DefaultPage/DefaultPage";
import styles from "../Header.module.css";

const CalendarView = () => {
	const navigate = useNavigate();

	return (
		<DefaultPage
			headerRightContent={
				<div className={styles.header}>
					<BackButton onBack={() => navigate(-1)} /> Calendar
				</div>
			}
			defaultHeader={false}
			leftSideChildren={<HomeLeftSidebar />}
			rightSideBgColor={"#FFFFFF"}
			rightSideChildren={<EditCalendar />}
		/>
	);
};

export default CalendarView;
