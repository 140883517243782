import { joiResolver } from "@hookform/resolvers/joi";
import { useQueryClient } from "@tanstack/react-query";
import boardsApi from "api/boards";
import PrimaryButton from "components/Utils/Button/PrimaryButton";
import GaawkModal from "components/Utils/GaawkModal/GaawkModal";
import WarningModal from "components/Utils/GaawkModal/WarningModal";
import { maxPostLength } from "components/Utils/General";
import FileInput from "components/Utils/SubComs/Inputs/FileInput/FileInput";
import InfiniteSearchInput from "components/Utils/SubComs/Inputs/InfiniteSearchInput/InfiniteSearchInput";
import InputWrapper from "components/Utils/SubComs/Inputs/InputWrapper/InputWrapper";
import { locationFormatter } from "components/Utils/SubComs/Inputs/SearchableInput/response-formatter";
import TextInput from "components/Utils/SubComs/Inputs/TextInput/TextInput";
import useFetchLocation from "hooks/useFetchLocation";
import useMutate from "hooks/useMutate";
import { classNames } from "primereact/utils";
import { boardsKeys } from "queryKeys/boards-key-factory";
import { locationKeys } from "queryKeys/location-key-factory";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import Switch from "react-switch";
import { boardSchema } from "./board-schema";
import styles from "./BoardEntry.module.scss";

const itemsPerPage = 20;

const BoardEntry = ({ data, show, onClose }) => {
	const [tempImage, setTempImage] = useState([]);
	const [tempFeedImage, setTempFeedImage] = useState([]);

	const {
		register,
		formState: { errors, isDirty },
		handleSubmit,
		watch,
		control,
		reset,
		setValue,
		clearErrors,
	} = useForm({
		resolver: joiResolver(boardSchema),
		mode: "onChange",
		defaultValues: {
			boardName: "",
			description: "",
			visibility: false,
			city: null,
			image: [],
			feedImage: [],
		},
	});

	const imageWatcher = watch("image");
	const feedImageWatcher = watch("feedImage");
	const descriptionWatcher = watch("description");

	const fetchLocation = useFetchLocation(true, itemsPerPage);

	useEffect(() => {
		if (data) {
			const {
				name: boardName,
				description,
				privateBoard,
				locationDTO,
				image,
				feedImage,
			} = data;

			reset({
				boardName,
				description,
				visibility: privateBoard,
				city: locationFormatter(locationDTO),
				image: [image],
				feedImage: [feedImage],
			});
		}
	}, []);

	// const handleCrop = (blob, isFeedImage) => {
	// 	if (isFeedImage) {
	// 		setValue("feedImage", [blob]);
	// 		clearErrors("feedImage");
	// 	} else {
	// 		setValue("image", [blob]);
	// 		clearErrors("image");
	// 	}
	// };

	const queryClient = useQueryClient();

	const {
		action: { mutate: saveBoard, isLoading },
	} = useMutate(
		data ? boardsApi.editBoard : boardsApi.addBoard,
		(response) => {
			if (data) {
				queryClient.invalidateQueries(boardsKeys.detail(response.data.uuid));
			} else {
				queryClient.invalidateQueries(boardsKeys.list("myBoards"));
				queryClient.invalidateQueries(
					boardsKeys.list("myBoards-sidebar") //TODO >> move to boards-key-factory
				);
			}

			close();
		},
		undefined,
		{
			meta: {
				errorMessage: "Something wrong happened",
			},
		}
	);

	const handleSave = (dataToSave) => {
		const { boardName, description, city, image, feedImage, visibility } = dataToSave;

		const formData = new FormData();

		if (data) formData.append("uuid", data.uuid); //* added when editing
		formData.append("name", boardName);
		formData.append("description", description);
		formData.append("locationId", city.value);
		formData.append("privateBoard", visibility);
		formData.append("gaawkBoard", data?.gaawkBoard ?? false);

		if (image[0] instanceof Blob) formData.append("image", image[0], image[0].fileName);

		if (feedImage[0] instanceof Blob)
			formData.append("feedImage", feedImage[0], feedImage[0].fileName);

		saveBoard(formData);
	};

	const [unsavedModal, setUnsavedModal] = useState(false);

	const close = () => {
		onClose();
		reset();
	};

	const closeForm = () => {
		if (isDirty) {
			setUnsavedModal(true);
			return;
		}

		close();
	};

	return (
		<GaawkModal
			showHeader={true}
			show={show}
			fullWidth={true}
			handleClose={closeForm}
			defaultModal={false}
			title={`${data ? "Edit" : "Create"} board`}
			closeAlign={"right"}
		>
			<form onSubmit={handleSubmit((data) => handleSave(data))} noValidate>
				<div className={styles.section}>
					<InputWrapper
						label="Board Name"
						className={styles.no_margin}
						required={true}
						error={errors?.boardName}
						component={
							<TextInput {...register("boardName")} placeholder="Enter Board Name" />
						}
					/>
					<span className={styles.character_max}>50 characters max</span>

					<InputWrapper
						label="Description"
						required={true}
						error={errors?.description}
						component={
							<div
								className={classNames(styles.textarea_wrapper, {
									[styles.error]: !!errors.description,
								})}
							>
								<textarea
									{...register("description")}
									placeholder="Enter board description"
									maxLength={maxPostLength}
								/>

								<div className={styles.description_counter}>
									<span className={styles.description_watcher}>
										{descriptionWatcher?.length}/{maxPostLength}
									</span>
								</div>
							</div>
						}
					/>

					<InputWrapper
						label="Board visibility"
						// error={errors?.visibility}
						infoText={
							"if your group is private, accounts will send you requests to join."
						}
						component={
							<div className={styles.react_switch}>
								<Controller
									name="visibility"
									control={control}
									render={({ field: { onChange, value } }) => (
										<Switch
											checked={value}
											onColor="#6cc5d1"
											offColor="#a6b1bc"
											handleDiameter={18}
											width={39}
											height={24}
											checkedIcon={false}
											uncheckedIcon={false}
											activeBoxShadow="0px 0px 1px 8px rgb(108, 197, 209, 0.3)"
											onChange={onChange}
											id={"switch"}
										/>
									)}
								/>
								<label htmlFor="switch">Make this board private</label>
							</div>
						}
					/>

					<InfiniteSearchInput
						queryName={locationKeys.cities}
						queryFn={fetchLocation}
						itemsPerPage={itemsPerPage}
						formatter={locationFormatter}
						label={"Location"}
						required={true}
						error={errors?.city}
						control={control}
						name="city"
						openMenuOnClick={true}
					/>

					<FileInput
						control={control}
						error={errors.image}
						name="image"
						title="Board Cover Image"
						required={true}
						fullWidthModal={true}
						// loadedFile={imageWatcher}
						loadedFile={tempImage}
						loadedThumbs={imageWatcher}
						// onCrop={handleCrop}
						onCrop={(blob) => {
							setValue("image", [blob]);
							clearErrors("image");
						}}
						cropAspect={2 / 1}
						onDelete={() => setValue("image", [])}
						onChange={(value) => {
							setTempImage([value.target.files[0]]);
							// setValue("image", [value.target.files[0]]);
						}}
					/>

					<FileInput
						control={control}
						error={errors.feedImage}
						name="feedImage"
						title="In feed board image"
						required={true}
						fullWidthModal={true}
						// loadedFile={feedImageWatcher}
						loadedFile={tempFeedImage}
						loadedThumbs={feedImageWatcher}
						onCrop={(blob) => {
							setValue("feedImage", [blob]);
							clearErrors("feedImage");
						}}
						onDelete={() => setValue("feedImage", [])}
						onChange={(value) => {
							// setValue("feedImage", [value.target.files[0]]);
							setTempFeedImage([value.target.files[0]]);
						}}
					/>
				</div>

				<div className={styles.button_container}>
					<PrimaryButton text={data ? "Save" : "Create board"} isLoading={isLoading} />
				</div>
			</form>

			<WarningModal
				show={unsavedModal}
				headerText="Are you sure you want to discard unsaved changes?"
				warningText="This action cannot be undone."
				cancelButtonText={"Cancel"}
				onCancelButtonClicked={() => setUnsavedModal(false)}
				submitButtonText={"DISCARD"}
				onSubmitButtonClicked={() => {
					setUnsavedModal(false);
					close();
				}}
			/>

			{/* <PromptModal when={isDirty && shouldBlockSave} /> */}
		</GaawkModal>
	);
};

export default BoardEntry;
