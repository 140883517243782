import { useState, useRef } from "react";

import CustomCheckbox from "../SubComs/Inputs/CustomCheckbox/CustomCheckbox";

import styles from "./VisibilitySelector.module.css";

// import connectionIcon from "images/connections-black-20-x-20.svg";
import dropDownArrow from "images/dropdown-arrow-12-x-8-black.svg";

import allIcon from "images/privacy-icons/all.svg";
import workIcon from "images/privacy-icons/work.svg";
import publicIcon from "images/privacy-icons/public.svg";
import meIcon from "images/privacy-icons/me.svg";
import friendIcon from "images/privacy-icons/friend.svg";

// import publicIcon from "images/public-grey-100.svg";
// import FriendIcon from "images/friends-grey-16-x-16.svg";
// import WorkIcon from "images/work-grey-16-x-16.svg";
// import OnlyMeIcon from "images/me-grey-100.svg";

import { getReadableValue } from "../General";
import useClickOutside from "../../../hooks/useClickOutside";
import GaawkRadioButton from "../SubComs/GaawkRadioButton/GaawkRadioButton";

const VisibilitySelector = ({ value, onValueChange }) => {
	const [showOptions, setShowOptions] = useState(false);

	const handleContainerClick = () => {
		setShowOptions((prevState) => !prevState);
	};

	const wrapperRef = useRef(null);
	useClickOutside(wrapperRef, () => setShowOptions(false));

	const getImageSrc = () => {
		switch (value) {
			case "ME":
				return meIcon;

			case "WORK_CIRCLE_FRIENDS":
				return allIcon;

			case "FRIENDS":
				return friendIcon;

			case "WORK_CIRCLE":
				return workIcon;

			default:
				return publicIcon;
		}
	};

	return (
		<div className={styles.select_container} ref={wrapperRef}>
			<div className={styles.selected_value} onClick={handleContainerClick}>
				<div>
					<img className={styles.connection} src={getImageSrc()} alt={"connection"} />
					<label>{getReadableValue(value)}</label>
				</div>

				<img className={styles.arrow} src={dropDownArrow} alt={"arrow"} />
			</div>

			{showOptions && (
				<div className={styles.option_list_container}>
					<ul id="option-list" className={styles.option_list}>
						<li onClick={onValueChange("PUBLIC")}>
							<label>Public</label>
							{/* <CustomCheckbox
								checked={value === "PUBLIC"}
								marginRight={"0px"}
								customBackgroundColor={"gaawk"}
								readOnly={true}
							/> */}

							<GaawkRadioButton
								checked={value === "PUBLIC"}
								value="PUBLIC"
								name="visibility"
								readOnly={true}
								customStyle={styles.no_padding}
								standaloneMode={true}
							/>
						</li>

						<li onClick={onValueChange("WORK_CIRCLE_FRIENDS")}>
							<label>All Connections</label>

							<GaawkRadioButton
								checked={value === "WORK_CIRCLE_FRIENDS"}
								value="WORK_CIRCLE_FRIENDS"
								name="visibility"
								readOnly={true}
								customStyle={styles.no_padding}
								standaloneMode={true}
							/>
						</li>
						<li onClick={onValueChange("FRIENDS")}>
							<label>Friends</label>
							<GaawkRadioButton
								checked={value === "FRIENDS"}
								value="FRIENDS"
								name="visibility"
								readOnly={true}
								customStyle={styles.no_padding}
								standaloneMode={true}
							/>
						</li>
						<li onClick={onValueChange("WORK_CIRCLE")}>
							<label>Work Circle</label>
							<GaawkRadioButton
								checked={value === "WORK_CIRCLE"}
								value="WORK_CIRCLE"
								name="visibility"
								readOnly={true}
								customStyle={styles.no_padding}
								standaloneMode={true}
							/>
						</li>
						<li onClick={onValueChange("ME")}>
							<label>Only Me</label>
							<GaawkRadioButton
								checked={value === "ME"}
								value="ME"
								name="visibility"
								readOnly={true}
								customStyle={styles.no_padding}
								standaloneMode={true}
							/>
						</li>
					</ul>
				</div>
			)}
		</div>
	);
};
export default VisibilitySelector;

// export const getVisibiltyValue = (value, stateValue) => {
// 	if (value === stateValue) {
// 		return;
// 	} else if (stateValue === "WORK_CIRCLE_FRIENDS") {
// 		if (value === "FRIENDS") {
// 			return "WORK_CIRCLE";
// 		} else if (value === "WORK_CIRCLE") {
// 			return "FRIENDS";
// 		} else {
// 			return value;
// 		}
// 	} else if (stateValue === "FRIENDS" && value === "WORK_CIRCLE") {
// 		return "WORK_CIRCLE_FRIENDS";
// 	} else if (stateValue === "WORK_CIRCLE" && value === "FRIENDS") {
// 		return "WORK_CIRCLE_FRIENDS";
// 	}
// 	return value;
// };
