import { useEffect, useState } from "react";
import locationApi from "../api/location";
import { dubaiDefaultLocation } from "../components/Utils/General";
import useBrowserLocation from "./useBrowserLocation";
import useMutate from "./useMutate";

const UAE_FLAG_URL = "https://flagcdn.com/w40/ae.png";

const useUserLocation = (onFlagChange) => {
	const [userCoordinates, setUserCoordinates] = useState(() => {
		const selectedCity = localStorage.getItem("selectedCity");

		if (selectedCity) {
			const parsedCity = JSON.parse(selectedCity);

			return {
				lat: parsedCity.latitude,
				lng: parsedCity.longitude,
				id: parsedCity.id,
				name: parsedCity.name,
				countryDTO: parsedCity.countryDTO,
			};
		} else {
			return {
				lat: dubaiDefaultLocation.lat,
				lng: dubaiDefaultLocation.lng,
				id: dubaiDefaultLocation.id,
				countryDTO: {
					flagUrl: UAE_FLAG_URL,
					code: "AE",
					dialCode: "+971",
					name: "United Arab Emirates",
				},
				name: "Dubai",
			};
		}
	});
	const [countryFlag, setCountryFlag] = useState();

	const setDubaiAsDefault = () => {
		setCountryFlag(UAE_FLAG_URL);
		localStorage.setItem(
			"selectedCity",
			JSON.stringify({
				latitude: dubaiDefaultLocation.lat,
				longitude: dubaiDefaultLocation.lng,
				id: dubaiDefaultLocation.id,
				countryDTO: {
					flagUrl: UAE_FLAG_URL,
					code: "AE",
					dialCode: "+971",
					name: "United Arab Emirates",
				},
				name: "Dubai",
			})
		);
		setUserCoordinates({
			lat: dubaiDefaultLocation.lat,
			lng: dubaiDefaultLocation.lng,
			id: dubaiDefaultLocation.id,
			countryDTO: {
				flagUrl: UAE_FLAG_URL,
				code: "AE",
				dialCode: "+971",
				name: "United Arab Emirates",
			},
			name: "Dubai",
		});
	};

	const {
		action: { mutate: getLocation },
	} = useMutate(
		locationApi.getLocation,
		(response) => {
			setCountryFlag(response.data.countryDTO.flagUrl);
			localStorage.setItem("selectedCity", JSON.stringify(response.data));

			setUserCoordinates({
				lat: response.data.latitude,
				lng: response.data.longitude,
				id: response.data.id,
				countryDTO: response.data.countryDTO,
				name: response.data.name,
			});
		},
		() => setDubaiAsDefault()
	);

	const handleGetLocation = ({ coords: { latitude, longitude } }) => {
		getLocation({ latitude, longitude });
	};

	const getBrowserLocation = useBrowserLocation(
		handleGetLocation, //* if granted
		setDubaiAsDefault //! if declined
	);

	useEffect(() => {
		const selectedCity = localStorage.getItem("selectedCity");

		if (selectedCity) {
			const parsedCity = JSON.parse(selectedCity);
			setCountryFlag(parsedCity.countryDTO.flagUrl);
		} else {
			getBrowserLocation();
		}
	}, []);

	const handleSelectedCity = (city) => {
		localStorage.setItem("selectedCity", JSON.stringify(city));
		setCountryFlag(city.countryDTO.flagUrl);
		setUserCoordinates({
			lat: city.latitude,
			lng: city.longitude,
			id: city.id,
			countryDTO: city.countryDTO,
			name: city.name,
		});
	};

	useEffect(() => {
		onFlagChange && onFlagChange(countryFlag);
	}, [countryFlag]);

	return { userCoordinates, handleSelectedCity };
};

export default useUserLocation;
