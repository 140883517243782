import { useQuery, useQueryClient } from "@tanstack/react-query";
import GroupMember from "components/Chat/GroupMember";
import TalentItem from "components/Profile/TalentTab/TalentItem";
import routes from "components/Routing/routing-keys";
import SecondaryButton from "components/Utils/Button/SecondaryButton";
import { format } from "date-fns";
import useCurrentUser from "hooks/useCurrentUser";
import useMutate from "hooks/useMutate";
import fullscreenIcon from "images/fullscreen-icon-white.svg";
import { classNames } from "primereact/utils";
import { jobsKeys } from "queryKeys/jobs-key-factory";
import { useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import jobApi from "../../../api/job";
import useApi from "../../../hooks/useApi";
import PrimaryButton from "../../Utils/Button/PrimaryButton";
import FileViewer from "../../Utils/FileViewer/FileViewer";
import ViewerHeader from "../../Utils/FileViewer/ViewerHeader";
import FullScreenModal from "../../Utils/GaawkModal/FullScreenModal";
import { viewerMapper } from "../../Utils/General";
import LoadingPage from "../../Utils/SubComs/CustomLoader/LoadingPage";
import JobInfoItem from "../JobsComponents/JobInfoItem/JobInfoItem";
import styles from "./ApplicantDetails.module.css";

const ApplicantDetails = () => {
	const navigate = useNavigate();

	const { type } = useCurrentUser();
	const { applicationId } = useParams();

	if (type === "USER") navigate(routes.jobs);

	// const { data: job, isFetching: isFetchingJob } = useJob(jobId);

	//! ========== GET APPLICANT =============

	const getApplicantApi = useApi(jobApi.getApplicant, true, true);

	const fetchApplicant = async ({ queryKey }) => {
		const [_, __, applicantId] = queryKey;
		const response = await getApplicantApi.request(applicantId);
		return response.data;
	};

	const {
		data: applicant,
		// isLoading,
		isFetching,
		// isError,
	} = useQuery({
		queryKey: jobsKeys.applicant(applicationId),
		queryFn: fetchApplicant,
	});

	const {
		applyTime,
		coverText,
		coverUploaded,
		cover,
		portfolioUploaded,
		portfolio,
		specialProfileDTO,
	} = applicant || {};

	// ! =========================================

	const [showSlider, setShowSlider] = useState(false);
	const [media, setMedia] = useState();
	const fileviewerRef = useRef();

	const handleViewer = (file) => {
		setMedia(viewerMapper([file]));
		setShowSlider(true);
	};

	const queryClient = useQueryClient();

	const {
		action: { mutate: updateApplicationStatus },
	} = useMutate(jobApi.updateApplicantStatus, () => {
		queryClient.invalidateQueries(jobsKeys.applicant(applicationId));
	});

	if (isFetching) return <LoadingPage />;

	return (
		<>
			<div className={styles.application_details}>
				<GroupMember
					redirect={true}
					data={applicant.minifiedProfile}
					showConnection={false}
					customStyle={styles.applicant}
				/>

				<p>
					Applied on:
					{format(new Date(applyTime), "dd/MM/yyyy")}
				</p>

				{/* <div className={styles.sub_section}>
					<h4>Portfolio</h4>
					<p
						className={styles.file}
						onClick={() => handleViewer(applicant.portfolio)}
					>
						{applicant.portfolio.file.originalName}
					</p>
				</div> */}

				<JobInfoItem
					title="Portfolio"
					content={
						portfolioUploaded ? (
							<div className={styles.application_item_container}>
								{portfolio.name}
								<SecondaryButton
									text={"View Portfolio"}
									className={styles.item_btn}
									// onClick={() => alert("VIEW PORTFOLIO")}
									onClick={() => handleViewer(portfolio)}
								/>
							</div>
						) : (
							<i>No portfolio attached</i>
						)
					}
				/>

				{/* {(applicant.cover || applicant.coverText) && (
					<div className={styles.sub_section}>
						<h4>Cover Letter</h4>
						{applicant.cover && (
							<p
								className={styles.file}
								onClick={() => handleViewer(applicant.cover)}
							>
								{applicant.cover.file.originalName}
							</p>
						)}
						{applicant.coverText && <p>{applicant.coverText}</p>}
					</div>
				)} */}
				<JobInfoItem
					title="Cover"
					content={
						coverText ? (
							<p>{coverText}</p>
						) : coverUploaded ? (
							<div className={styles.application_item_container}>
								{cover.name}
								<SecondaryButton
									text={"View Cover Letter"}
									className={styles.item_btn}
									// onClick={() => alert("VIEW COVER LETTER")}
									onClick={() => handleViewer(cover)}
								/>
							</div>
						) : (
							<i>No cover letter attached</i>
						)
					}
				/>

				{/* {applicant.specialProfileDTO && (
					<div className={styles.sub_section}>
						<h4>Special Profile</h4>
						<div
							className={styles.special_profile_wrapper}
							onClick={() =>
								navigate(
									routes.specialProfile(
										applicant.specialProfileDTO?.url
									)
								)
							}
						>
							<ProfilePic
								thumbnail={
									applicant.specialProfileDTO?.profilePic
										?.file?.customName
								}
								w={36}
								h={36}
								name={applicant.specialProfileDTO?.name}
								type="USER"
							/>
						</div>
					</div>
				)} */}

				<JobInfoItem
					title="Special Profile"
					content={
						specialProfileDTO ? (
							<div className={styles.application_item_container}>
								<TalentItem data={specialProfileDTO} />
								<SecondaryButton
									text={"View Profile"}
									className={styles.item_btn}
									onClick={() =>
										navigate(routes.specialProfile(specialProfileDTO.url))
									}
								/>
							</div>
						) : (
							<i>No cover letter attached</i>
						)
					}
				/>

				<div className={styles.button_container}>
					{(applicant.status === "NEW" || applicant.status === "SHORTLISTED") && (
						<PrimaryButton
							text={"Turn Down"}
							className={classNames(styles.btn, styles.turn_down)}
							type="button"
							onClick={() =>
								updateApplicationStatus({
									applicationId: applicant.uuid,
									status: "TURNED_DOWN",
								})
							}
						/>
					)}
					{(applicant.status === "NEW" || applicant.status === "TURNED_DOWN") && (
						<PrimaryButton
							text={"Shortlist"}
							className={styles.btn}
							type="button"
							onClick={() =>
								updateApplicationStatus({
									applicationId: applicant.uuid,
									status: "SHORTLISTED",
								})
							}
						/>
					)}
				</div>
			</div>

			<FullScreenModal
				show={showSlider}
				fullWidth={true}
				onClose={() => setShowSlider(false)}
				header={
					<ViewerHeader
						onClose={() => setShowSlider(false)}
						leftSide={""}
						rightSide={
							<button>
								<img
									src={fullscreenIcon}
									className={styles.fullscreen_icon}
									alt="Fullscreen"
									onClick={() => fileviewerRef.current.enterFullscreen()}
								/>
							</button>
						}
					/>
				}
				children={
					<div className={styles.fileviewer_container}>
						<FileViewer ref={fileviewerRef} files={media} />
					</div>
				}
			/>
		</>
	);
};

export default ApplicantDetails;
