import { useEffect, useState } from "react";
import { useParams } from "react-router";

const usePostViewer = () => {
	const [postModal, setPostModal] = useState(false);

	const { id, subPostId: subId } = useParams();

	const [postId, setPostId] = useState(() => id);
	const [subPostId, setSubPostId] = useState(() => subId);

	const [subPostIndex, setSubPostIndex] = useState(0);

	const handleItemClicked = (post) => (mediaId) => {
		let newUrl;

		if (post.hasSharedPost) {
			const sharedSubPosts = [];

			if (post.sharedPost.totalMediaCount > 0) {
				sharedSubPosts.push(...post.sharedPost.mediaList);
			}

			if (post.sharedPost.totalPDFCount > 0) {
				sharedSubPosts.push(...post.sharedPost.pdfList);
			}

			setSubPostIndex(sharedSubPosts.findIndex((item) => item.uuid === mediaId));

			newUrl = `${post.sharedPost.uuid}${sharedSubPosts?.length > 0 ? `/${mediaId}` : ""}`;

			setPostId(post.sharedPost.uuid);
			// if (sharedSubPosts?.length > 0) setSubPostId(mediaId);
		} else {
			const subPosts = [];

			if (post.totalMediaCount > 0) {
				subPosts.push(...post.mediaList);
			}

			if (post.totalPDFCount > 0) {
				subPosts.push(...post.pdfList);
			}

			setSubPostIndex(subPosts.findIndex((item) => item.uuid === mediaId));

			newUrl = `${post.uuid}${subPosts?.length > 0 ? `/${mediaId}` : ""}`;

			setPostId(post.uuid);
			// if (subPosts?.length > 0) setSubPostId(mediaId);
		}

		if (mediaId) setSubPostId(mediaId);

		// window.history.replaceState(null, "", `/post/${newUrl}`);
		window.history.pushState(null, "", `/post/${newUrl}`);

		setPostModal(true);
	};

	const handleCloseModal = () => {
		setPostModal(false);
		// setPostId(undefined);
		// setSubPostId(undefined);
	};

	//* used to open the modal when sharing / navigating to the link directly
	useEffect(() => {
		if (subPostId) {
			setPostModal(true);
		}
	}, [subPostId]);

	useEffect(() => {
		if (id) {
			setPostId(id);
		}
	}, [id]);

	return {
		postModal,
		postId,
		subPostId,
		handleItemClicked,
		handleCloseModal,
		subPostIndex,
	};
};

export default usePostViewer;
