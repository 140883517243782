import { useEffect, useRef, useState } from "react";
import styles from "./OtpForm.module.scss";
import { useDispatch, useSelector } from "react-redux";
import {
	otpUpdated,
	resetTimer,
	startTimer,
	// updateNewEmail,
	// updateNewMobile,
} from "store/slices/otp";
import { toast } from "react-toastify";
import {
	// verifyEmail,
	resetUserLogin,
	profileInfoUpdated,
	userLoginUpdated,
	// updateEmail,
	// verifyPhone,
} from "store/slices/user";
import CustomOTPInput from "components/Utils/SubComs/CustomOTPInput/CustomOTPInput";
import PrimaryButton from "components/Utils/Button/PrimaryButton";
import { useNavigate } from "react-router-dom";
import useMutate from "hooks/useMutate";
import profileApi from "api/profile";

// import { resetTokenState } from "store/slices/fcmToken";

const OtpForm = ({
	// onRegisterEmail,
	// onRegisterMobile,
	onCancelRegistration,
}) => {
	const intervalId = useRef();
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const didMountRef = useRef(false);

	const {
		expireAt,
		sent: isSent,
		code,
		// newMobile,
		// newEmail,
	} = useSelector((state) => state.otp);

	const timeRemaining = Math.ceil((expireAt - new Date().getTime()) / 1000);

	const profile = useSelector((state) => state.user.profileInfo);
	const user = useSelector((state) => state.user.userLogin);

	// const [email, setEmail] = useState(newEmail !== "" ? newEmail : user.email);
	// const [mobile, setMobile] = useState(
	// 	newMobile.countryCode !== "" && newMobile.mobile !== "" ? newMobile : ""
	// );

	const [otherOptions, setOtherOptions] = useState(false);
	const [hasError, setHasError] = useState(false);
	const [otp, setOtp] = useState("");

	const [resendButtonState, setResendButtonState] = useState({
		disabled: false,
		remainingTime: 40,
	});

	const {
		action: { mutate: getOtp },
	} = useMutate(profileApi.sendOtp, (response) => {
		dispatch(otpUpdated(response.data));
	});

	const sendOtp = (channel = "") => {
		// if (mobile !== "" && channel !== "byEmail") {
		// 	dispatch(
		// 		getOtp({ mobile: `${mobile.countryCode}${mobile.mobile}` })
		// 	);
		// } else {
		// 	dispatch(getOtp({ email: email }));
		// }
		// dispatch(getOtp({ email: user.email })); //PREVIOUS CODE
		getOtp({ email: user.email });
	};

	const handleOTPChange = (e) => {
		setOtp(e);
	};

	const {
		action: { mutate: verifyUserEmail },
	} = useMutate(profileApi.verifyUserEmail, (response) =>
		dispatch(userLoginUpdated(response.data))
	);

	const checkOtp = () => {
		if (otp !== code) {
			setHasError(true);

			toast.error("otp doesn't match !");
		} else {
			setHasError(false);

			// if (newEmail !== "") {
			// 	dispatch(updateEmail(email));
			// } else if (mobile !== "") {
			// 	dispatch(verifyPhone(mobile.countryCode, mobile.mobile));
			// } else {
			// 	dispatch(verifyEmail());
			// }

			verifyUserEmail();
		}
	};

	const {
		action: { mutate: getUserProfile },
	} = useMutate(profileApi.getUserProfile, (response) =>
		dispatch(profileInfoUpdated(response.data))
	);

	useEffect(() => {
		if (didMountRef.current) {
			if (profile && Object.keys(profile).length > 0) {
				navigate("/", { replace: true });
			} else if (user.mobileVerified || user.emailVerified) {
				getUserProfile(user.uuid);
			}
		}
		didMountRef.current = true;
	}, [navigate, profile, user]);

	const showOtherOptions = () => {
		setOtherOptions(true);
	};

	const handleResendButtonTimeOut = () => {
		clearInterval(intervalId.current);

		setResendButtonState({
			disabled: false,
			remainingTime: 40,
		});
	};

	if (resendButtonState.remainingTime <= 0) {
		handleResendButtonTimeOut();
	}

	const timer = () => {
		intervalId.current = setInterval(() => {
			return setResendButtonState((prevState) => ({
				...prevState,
				remainingTime: prevState.remainingTime - 1,
			}));
		}, 1000);
	};

	const resendOTP = () => {
		sendOtp();

		setResendButtonState((prevState) => ({
			...prevState,
			disabled: true,
		}));

		// dispatch the time at which the button has been clicked and the expiry time (+40sec)
		dispatch(startTimer(new Date().getTime()));

		timer();
	};

	const renderResendButton = () => {
		const text = resendButtonState.disabled
			? `Send code again (wait 0.${("0" + resendButtonState.remainingTime).slice(-2)})`
			: "Resend code";

		return (
			<button
				disabled={resendButtonState.disabled}
				className={styles.gray_btn}
				onClick={resendOTP}
			>
				{text}
			</button>
		);
	};

	// const handleConfirmByEmail = () => {
	// 	dispatch(updateNewEmail(""));
	// 	dispatch(updateNewMobile(""));
	// 	setEmail(user.email);
	// 	setMobile("");
	// 	sendOtp("byEmail");
	// };

	useEffect(() => {
		if (!isSent) {
			sendOtp();
		}
	}, [isSent]);

	useEffect(() => {
		if (!isNaN(timeRemaining)) {
			if (timeRemaining <= 0) {
				dispatch(resetTimer());
			} else {
				setOtherOptions(true);
				setResendButtonState({
					disabled: true,
					remainingTime: timeRemaining,
				});
				timer();
			}
		}
	}, []);

	const handleCancelRegistration = () => {
		dispatch(resetUserLogin());
		// dispatch(resetTokenState());
		onCancelRegistration();
	};

	return (
		<div className={styles.container}>
			<h3>Confirm your account</h3>
			{/* <div className={styles.info}>
				{mobile !== ""
					? `We have sent an SMS message with a code to ${mobile.countryCode}${mobile.mobile}. Please enter the 6 digit code from the SMS below`
					: `We have sent an email with a code to ${email}. Please enter the 6 digit code from the email below`}
			</div> */}
			<div className={styles.info}>
				{`We have sent an email with a code to ${user.email}. Please enter the 6 digit code from the email below`}
			</div>

			<CustomOTPInput value={otp} onChange={handleOTPChange} />

			{hasError && (
				<p className={styles.otp_error}>
					Please check your emails for a code and try entering it again.
				</p>
			)}

			<PrimaryButton text="confirm" onClick={checkOtp} className={styles.confirm_btn} />

			{!otherOptions && (
				<button className={styles.other_btn} onClick={showOtherOptions}>
					I didn't receive the code
				</button>
			)}

			{/* {otherOptions && mobile === "" && (
				<>
					<button
						className={styles.gray_btn}
						onClick={onRegisterEmail}
					>
						Add another email
					</button>

					<button
						className={styles.gray_btn}
						onClick={onRegisterMobile}
					>
						Confirm by mobile number
					</button>
				</>
			)}

			{otherOptions && mobile !== "" && (
				<>
					<button
						className={styles.gray_btn}
						onClick={handleConfirmByEmail}
					>
						Confirm by email
					</button>

					<button
						className={styles.gray_btn}
						onClick={onRegisterMobile}
					>
						Add another mobile number
					</button>
				</>
			)} */}

			{otherOptions && <div>{renderResendButton()}</div>}

			<button className={styles.other_btn} onClick={handleCancelRegistration}>
				Logout
			</button>
		</div>
	);
};

export default OtpForm;
