import profileApi from "api/profile";
import routes from "components/Routing/routing-keys";
import CtaButton from "components/Utils/Button/CtaButton";
import useCurrentUser from "hooks/useCurrentUser";
import useMutate from "hooks/useMutate";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { socialUpdated as companySocialUpdated } from "../../../../../store/slices/company";
import { socialUpdated } from "../../../../../store/slices/user";
import WarningModal from "../../../../Utils/GaawkModal/WarningModal";
import styles from "./Social.module.css";
import SocialItem from "./SocialItem";

const Social = () => {
	const navigate = useNavigate();
	const dispatch = useDispatch();

	const { socials, uuid: companyId, type } = useCurrentUser();
	const editCompany = type === "COMPANY";

	const { handleRequestMutate, handleConfirmMutate, setWarningModal, warningModal, activeItem } =
		useMutate(
			editCompany ? profileApi.deleteCompanySocial : profileApi.deleteSocial,
			(response, variables) => {
				const { companyId } = variables;
				dispatch(
					companyId ? companySocialUpdated(response.data) : socialUpdated(response.data)
				);
			}
		);

	const socialList = socials.map((social) => (
		<SocialItem
			key={social.uuid}
			item={social}
			onEditClicked={() =>
				navigate(routes.editSocial, {
					state: social,
				})
			}
			onDelete={() => handleRequestMutate(social)}
		/>
	));

	return (
		<>
			<div className={styles.button_wrapper}>
				<CtaButton
					text="+ ADD NEW SOCIAL MEDIA"
					onClick={() => navigate(routes.addSocial)}
				/>
			</div>

			{socials.length > 0 && (
				<div className={styles.container}>
					<h4 className={styles.title}>Your Social Medias</h4>
					<div className={styles.contact_section}>{socialList}</div>
				</div>
			)}

			<WarningModal
				show={warningModal}
				headerText="Are you sure you want to delete this information?"
				warningText="This action cannot be undone."
				cancelButtonText={"Cancel"}
				onCancelButtonClicked={() => setWarningModal(false)}
				submitButtonText={"DELETE"}
				onSubmitButtonClicked={() =>
					handleConfirmMutate({
						...(editCompany && { companyId }),
						userSocialId: activeItem.uuid,
					})
				}
			/>
		</>
	);
};

export default Social;
