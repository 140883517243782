import UserListModal from "components/Utils/UserListModal/UserListModal";
import { postsKeys } from "queryKeys/posts-key-factory";
import postApi from "api/post";
import GroupMember from "components/Chat/GroupMember";

const itemsPerPage = 20;

const RepostModal = ({ show, onClose, postId }) => {
	const fetchUsers = async ({ pageParam = 0, signal, queryKey }) => {
		const [_, __, postId] = queryKey;
		const response = await postApi.getShares(postId, pageParam, itemsPerPage);
		return response.data;
	};

	const renderUser = ({ user, ref }) => (
		<GroupMember ref={ref} key={user.uuid} redirect={true} data={user} />
	);

	return (
		<UserListModal
			show={show}
			onClose={onClose}
			title="Shared by"
			fetchUsers={fetchUsers}
			renderUser={renderUser}
			queryKey={() => postsKeys.sharedBy(postId)}
			pageSize={itemsPerPage}
		/>
	);
};

export default RepostModal;
